import * as React from "react";

function SvgAdultMouthpieceBg(props) {
  return (
    <svg
      viewBox="0 0 394 395"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M186.74 338.841c.029 1.033-.286 2.065-.83 2.926-.373.573-2.006.917-2.693 1.003-1.06.144-1.576-.114-2.321-.803-.544-.516-1.002-1.348-1.088-2.208-.287-2.61 1.862-4.273 3.352-4.331 2.119-.029 3.58 1.463 3.58 3.413.029 1.09 0-1.032 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M104.897 236.68c-.43.631-4.498 3.585-6.331-.229-1.26-2.61 1.461-4.618 2.435-4.819 2.75-.602 3.695 1.033 4.154 2.238.2.573.229 2.065-.258 2.81zM117.473 318.765c.086 1.749-1.261 3.757-3.18 3.728-1.662-.028-2.893-.803-3.352-2.437-.601-2.065.688-4.245 2.636-4.618 2.607-.488 3.867 2.266 3.896 3.327z"
        fill="#fff"
      />
      <path
        d="M98.881 293.21c-.143.631-.687 1.893-1.26 2.266-.63.43-1.69 1.061-2.464.975-1.003-.115-1.948-.66-2.578-1.434-1.404-1.692-.258-3.815 1.432-4.704 2.75-1.462 5.185 1.492 4.87 2.897zM55.052 231.231c-.63-.545-.946-1.405-.946-2.237 0-.832.058-3.241 3.467-3.442.83-.057 2.234.402 2.893.947.602.488.573 1.807.544 2.524-.029.889-.773 1.663-1.346 2.323-.258.315-2.894 1.377-4.612-.115zM145.747 316.671c.573.602.745 1.52.774 2.323a3.51 3.51 0 01-.917 2.495c-.716.746-1.347.889-2.321 1.061-.83.115-1.661.086-2.263-.573-.515-.574-1.117-1.234-1.145-2.065-.172-5.507 5.185-3.958 5.872-3.241z"
        fill="#007FC8"
      />
      <path
        d="M54.164 282.799c-1.49.459-4.125-1.09-4.01-2.811.028-.459 0-1.577.687-2.524.515-.717 1.69-1.262 2.55-1.376.83-.115 1.69.172 2.32.745.744.66.83 1.234 1.002 2.151 0 1.721-.974 3.327-2.55 3.815zM63.33 201.317c.86-.086 1.605.344 2.178.947.716.717.63 1.405.458 2.351-.286 1.52-1.776 3.127-3.352 2.897-1.776-.258-3.265-1.118-3.15-3.155.085-1.721 2.291-3.011 3.867-3.04 1.002-.115-.974 0 0 0zM121.111 279.702c.601.659.745.803.745 1.663 0 .889 0 3.184-2.836 3.786-.831.172-1.805-.143-2.55-.545-.602-.315-1.174-1.864-1.117-2.553.43-4.761 5.242-2.896 5.758-2.351zM78.914 217.034c.888.488.831 1.377.716 2.266-.257 1.864-1.776 3.442-3.752 2.811-.802-.259-1.576-.689-2.091-1.377-.545-.746-.602-1.348-.373-2.18.2-.717.544-1.405 1.203-1.807.287-.229 1.977-.975 4.297.287z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M94.011 247.809c1.662.401 2.578 2.265 2.149 3.843-.459 1.663-1.948 2.782-3.753 2.409-1.547-.316-2.263-1.578-2.263-3.098 0-3.154 2.95-3.384 3.867-3.154zM110.025 254.979c-.115 2.122-.917 3.699-3.237 3.785-2.092.086-2.922-2.179-2.464-3.929.43-1.692 2.32-2.638 3.151-2.524 1.547.258 2.492 1.893 2.55 2.668-.058.889-.058-.889 0 0z"
        fill="#fff"
      />
      <path
        d="M122.715 272.417c-1.747.258-3.38-.459-3.495-2.295-.2-2.753 2.349-3.069 2.894-3.097 1.69-.115 2.549 1.319 2.606 2.351.058 1.033-.601 2.84-2.005 3.041z"
        fill="#007FC8"
      />
      <path
        d="M148.554 279.759c.745.373 1.347 1.061 1.519 1.864.343 1.491-.946 3.929-2.693 3.815-.688-.029-3.065 0-3.266-1.893-.487-4.704 3.724-4.13 4.44-3.786z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M128.015 283.545c.286-1.52 1.89-3.098 3.523-2.954.659.057 1.49.286 1.977.745.2.201.831 1.549.802 2.18-.143 1.52-2.234 3.471-3.81 3.126-.602-.114-1.518-.803-1.948-1.262-.516-.459-.573-1.147-.544-1.835.114-.574-.029 1.348 0 0zM150.989 297.799c-.028 1.319-1.747 3.126-3.065 3.155-1.547.029-3.065-1.262-3.122-2.84-.058-1.749 1.575-3.04 3.208-3.154 1.49-.115 2.951 1.319 2.979 2.839-.028.774 0-.602 0 0z"
        fill="#fff"
      />
      <path
        d="M119.908 263.583c-.774-.459-.86-1.721-1.003-2.524-.286-1.692 1.69-2.897 3.18-2.84 1.919.087 2.951 1.521 2.951 3.327.028 1.148-2.55 3.528-5.128 2.037z"
        fill="#3BB2F4"
      />
      <path
        d="M99.082 266.824c-.774-.517-.917-1.377-1.146-2.237-.373-1.406 1.948-2.868 3.151-2.954 1.805-.115 2.664 1.089 2.865 2.782.085.774-.43 1.606-1.003 2.122-.43.344-2.091 1.463-3.867.287zM192.556 318.076c1.489.029 2.549 1.119 2.864 2.581.144.775-.315 3.155-2.578 3.442-1.604.201-3.495-1.061-3.323-3.011.229-2.725 2.32-2.811 3.037-3.012 1.346.029-.631.201 0 0zM69.977 251.308c-.545-.373-.889-1.291-1.003-1.922-.2-1.549 1.719-3.155 3.151-3.012a3.544 3.544 0 012.005.947c.287.258.516.574.688.918.286.573.057.917.086 1.52.028 1.606-2.693 2.983-4.927 1.549z"
        fill="#91E7F2"
      />
      <path
        d="M120.08 297.57c-.058-2.324 1.919-2.783 2.692-2.983 1.519-.431 2.98 1.061 2.98 2.524.028 1.749-.974 2.982-2.693 3.212-1.633.229-3.237-1.033-2.979-2.753-.086-.631-.115.717 0 0z"
        fill="#007FC8"
      />
      <path
        d="M142.395 278.067c-.601 1.262-1.088 2.581-2.664 2.782-1.346.143-2.75-.889-3.008-2.237-.229-1.291.946-3.356 2.349-3.557.716-.115 1.891 0 2.464.43.773.631.745 1.664.859 2.582-.229.487-.057-.459 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M176.055 307.808c.029 1.492-1.26 2.754-2.693 2.983-.773.144-1.461-.172-2.091-.545-.601-.344-.802-1.118-.945-1.778-.143-.631.114-1.319.458-1.864 1.977-3.212 5.3-.201 5.271 1.204.029 1.463 0-.889 0 0z"
        fill="#007FC8"
      />
      <path
        d="M106.53 300.81c.028-.831-.058-1.204.487-1.806.544-.574 1.145-1.119 1.919-1.377 1.318-.402 2.779 1.147 2.893 2.438a2.746 2.746 0 01-.487 1.807c-.286.43-1.088 1.204-1.633 1.176-.716-.029-1.632.057-2.234-.431-.516-.373-.974-1.09-.945-1.807 0-.372-.058.832 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M147.666 336.317c.029-1.835 1.118-2.782 2.779-3.269 1.318-.373 2.521.975 2.55 2.179.028 1.406-.201 2.496-1.719 2.926-1.347.43-3.638-.086-3.61-1.836.029-.918 0 .775 0 0z"
        fill="#fff"
      />
      <path
        d="M58.604 239.061c-.23-1.664 1.031-3.126 2.55-3.126 1.919-.029 2.893 1.491 2.807 2.323-.143 1.549-1.318 2.839-2.95 2.925-1.576.086-2.235-.917-2.407-2.122z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M156.117 342.942c-.172-1.319 1.346-3.384 2.922-2.954 1.289.373 2.435 1.176 2.435 2.696 0 .66-.63 1.492-1.031 1.979-.545.631-3.954 1.406-4.326-1.721z"
        fill="#fff"
      />
      <path
        d="M139.015 302.044c-1.404.229-2.721-.889-2.893-2.266-.172-1.434.773-3.012 2.377-2.926.573.029 2.464.144 2.808 2.209.315 2.065-1.032 2.811-2.292 2.983z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M115.324 272.99c.172 3.212-5.156 3.499-5.271.144-.057-1.406 1.203-2.639 2.578-2.639.602 0 1.576.143 2.092.487.572.402.515 1.377.601 2.008.057 1.119-.057-.315 0 0z"
        fill="#fff"
      />
      <path
        d="M161.961 293.44c.716-.115 1.432-.259 1.919.315.602.688 1.776 3.27-1.174 4.904-1.232.689-3.38-.258-3.151-1.921 0-1.405.945-3.04 2.406-3.298z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M144.773 311.365c-1.318.057-3.123-.803-2.979-2.323.143-1.434 1.289-3.184 2.893-3.126.974.028 2.55 1.491 2.32 2.524-.315 1.262-.687 2.839-2.234 2.925-1.576.086.602-.029 0 0z"
        fill="#fff"
      />
      <path
        d="M143.197 353.44c-.057 1.176-.945 2.581-2.148 2.839-1.318.287-3.037-.717-2.893-2.208.143-1.578.974-3.356 2.807-3.155 1.461.143 2.12 1.147 2.234 2.524-.028.917-.171-1.979 0 0zM159.641 357.111c-.287-2.151 1.031-3.04 2.033-3.327 1.461-.43 3.066 1.004 2.837 2.495-.172 1.262-1.06 2.381-2.35 2.639-1.317.229-2.52-.459-2.52-1.807-.23-1.061 0 .86 0 0zM157.005 288.363c-.172-1.663 1.461-2.753 2.979-2.581 1.605.172 2.693 1.663 2.006 3.184-.258.602-1.003 1.921-2.922 1.606-.086-.029-1.862-.287-2.063-2.209zM168.578 294.3c-.143-1.348.745-2.725 2.006-3.184 1.231-.459 2.635.488 2.922 1.721.2.803-.602 3.155-1.977 3.413s-2.779-.344-2.951-1.95z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M191.41 362.388c-.258-1.147 1.432-2.897 2.521-2.782 4.039.43 2.778 5.306.028 5.134a3.265 3.265 0 01-1.69-.602c-.716-.488-.773-.947-.859-1.75-.172-.774.028.316 0 0zM79.774 269.892c-1.375.431-2.894-.602-2.808-1.979.058-.688-.143-1.147.373-1.749.515-.631 1.26-1.119 2.062-1.176.573-.057 1.175.23 1.69.516.831.488 1.576 3.471-1.317 4.388z"
        fill="#fff"
      />
      <path
        d="M49.81 240.839c-1.347.23-2.866-1.405-2.665-2.696.23-1.319 1.404-2.466 2.807-2.266.688.115 2.235.976 2.235 2.266 0 1.033-.86 2.438-2.378 2.696z"
        fill="#3BB2F4"
      />
      <path
        d="M51.815 269.864c-.487 1.262-1.69 2.122-3.066 2.065-.573 0-1.375-.775-1.575-1.262-1.203-3.184 1.719-3.929 2.779-3.815 1.89.23 2.033 2.524 1.862 3.012z"
        fill="#007FC8"
      />
      <path
        d="M57.4 257.847c-.028 1.376-1.203 2.122-2.348 2.466-.63.172-1.232.086-1.776-.287-.402-.286-1.175-.946-1.204-1.491-.028-1.147.831-2.094 1.834-2.438.63-.201 1.432-.545 2.062-.201.66.345 1.203 1.291 1.433 1.951-.029 1.004-.029-.115 0 0zM71.123 261.833c-.115-1.319.916-2.581 2.205-2.609 1.72-.029 2.235 1.491 2.35 1.72.716 1.492-.545 2.754-2.063 2.84-1.347.143-2.407-.66-2.492-1.951z"
        fill="#91E7F2"
      />
      <path
        d="M74.589 227.531c-.2-2.524 3.838-3.786 4.87-1.348.515 1.234-.974 4.274-3.896 3.04-.716-.315-.831-1.003-.974-1.692-.086-1.204.057.258 0 0z"
        fill="#007FC8"
      />
      <path
        d="M38.064 206.48c2.492-.259 3.867 3.785 1.432 4.847-1.231.545-2.893-.746-3.18-2.151-.372-1.693 1.032-2.582 1.748-2.696 1.175-.115-.286.057 0 0zM91.72 206.967c.458 1.32-.602 2.696-1.834 3.069-1.29.43-2.55-.889-2.721-2.065-.087-.574-.23-1.377.257-1.836.487-.458 1.032-.831 1.69-1.003 1.06-.258 2.264.86 2.607 1.835z"
        fill="#91E7F2"
      />
      <path
        d="M105.47 312.569c-3.466.087-2.893-4.961.2-4.732 1.204.086 1.805 1.262 2.12 2.295.315 1.176-1.203 2.524-2.32 2.437-.716.029.63.058 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M128.731 316.011c-.201.201-2.063 1.033-3.18-.573-.773-1.119-.114-2.295.63-3.184.974-1.204 3.008-.057 3.438.516.43.517.057 2.381-.888 3.241z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M76.336 237.684c1.175-.172 2.521.689 2.521 2.209 0 1.176-.544 2.093-1.92 2.323-1.517.258-2.606-.602-2.606-1.922-.029-1.548.487-2.409 2.005-2.61zM178.891 361.671c.086-1.032.487-2.065 1.662-2.18 1.317-.114 2.893.574 2.922 2.037.028 1.52-1.204 2.437-2.579 2.638-1.145.144-2.033-1.549-2.005-2.495 0-.143-.028.66 0 0z"
        fill="#fff"
      />
      <path
        d="M97.85 206.938c-1.146-.028-2.292-.917-2.35-2.122-.028-.717.545-1.434.889-2.008.344-.602 1.432-.487 2.005-.344 1.117.287 2.149 1.119 1.862 2.352-.286 1.061-1.232 2.208-2.406 2.122-1.232-.028 1.46.086 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M179.378 315.954c0 1.061-.687 2.495-2.406 2.38-1.318-.057-2.378-1.204-2.12-2.552.229-1.233 1.232-2.237 2.464-2.237 1.289.028 2.062 1.376 2.062 2.409z"
        fill="#fff"
      />
      <path
        d="M147.895 291.317c0 1.09-1.145 2.495-2.291 2.295-1.089-.201-2.435-1.062-2.435-2.266 0-1.262 1.375-2.323 2.578-2.381 1.117-.028 2.12 1.32 2.148 2.352z"
        fill="#3BB2F4"
      />
      <path
        d="M106.959 280.275c.086-2.954 4.956-2.438 4.383.402-.229 1.176-.974 2.208-2.263 2.265-1.403.029-2.12-1.462-2.12-2.667.029-1.233 0 1.52 0 0z"
        fill="#007FC8"
      />
      <path
        d="M113.72 248.841c1.289-.057 2.005 1.176 1.805 2.38-.115.574-.516 1.176-.831 1.635-.2.258-3.008 1.32-3.438-1.721-.143-1.176 1.175-2.294 2.464-2.294.802-.057-.687 0 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M86.162 243.851c-.172-1.09.802-2.352 1.862-2.496.974-.143 2.664.545 2.721 1.721.058 1.147-.86 2.61-2.034 2.782a2.526 2.526 0 01-1.747-.401c-.63-.43-.659-.918-.802-1.606-.143-.918.086.372 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M89.743 261.403c1.403-.258 2.55 1.549 2.406 2.123-.287 1.175-.917 2.208-2.177 2.437-.802.144-2.406-.774-2.406-1.749.028-1.319.658-2.897 2.177-2.811.4.029-.745-.028 0 0z"
        fill="#fff"
      />
      <path
        d="M149.958 325.992c-.029 1.205-.544 2.409-1.661 2.524-1.891.172-3.066-.602-2.894-2.036.516-3.987 4.584-2.065 4.555-.488z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M82.352 213.478c.057-.574.888-1.176 1.404-1.492.286-.172 1.06-.057 1.375 0 .601.058 1.088.517 1.518.947 1.088 1.176.2 2.495-.917 3.155-1.117.688-3.695.114-3.38-2.61z"
        fill="#fff"
      />
      <path
        d="M86.62 282.856c.143 1.148-1.06 2.639-2.349 2.524-1.346-.114-2.463-1.032-2.148-2.581.372-1.95 4.21-2.036 4.497.057z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M70.664 284.635c-1.29.344-2.721-.316-2.807-1.75-.2-3.155 4.354-3.298 4.383-.201-.029.975-.401 1.635-1.576 1.951z"
        fill="#fff"
      />
      <path
        d="M101.803 300.696c-.086.487-.43 1.061-.716 1.405-.458.516-1.175.717-1.834.66-1.145-.115-2.406-1.09-1.976-2.266 1.432-3.843 4.784-1.405 4.526.201z"
        fill="#007FC8"
      />
      <path
        d="M64.591 252.111c1.06-.086 2.149.717 2.005 1.864-.086.688-.315 2.409-2.263 2.495-2.607.086-2.807-4.331.258-4.359 1.347-.115-.63 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M154.857 325.16c.057-1.204.974-2.093 2.148-2.265 2.55-.402 2.55 2.638 1.49 3.642-.516.488-1.032.746-1.605.832-1.203.201-2.005-1.119-2.033-2.209.057-1.405 0 .115 0 0z"
        fill="#007FC8"
      />
      <path
        d="M159.297 311.365c2.807.029 2.177 3.729 1.146 4.13-.43.172-1.375.402-1.834.402-1.174-.029-1.833-1.406-1.747-2.467.114-1.233 1.289-2.007 2.435-2.065.401.115-1.547.058 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M81.149 204.156c.573-.057 1.203.058 1.661.459.315.287.974.918.888 1.377-.114.574-.573 1.176-1.002 1.577-.487.431-3.094 1.406-3.667-1.462-.258-1.119 1.031-1.865 2.12-1.951z"
        fill="#fff"
      />
      <path
        d="M158.466 308.927c-.716 1.061-1.805 1.348-2.95.717-1.347-.745-1.06-2.122-.316-3.212.86-1.291 2.722-.746 3.266-.201.544.545.602 1.778 0 2.696z"
        fill="#3BB2F4"
      />
      <path
        d="M184.477 291.833c1.175-.258 2.464.345 2.894 1.893.2.775.172 2.84-2.722 2.18-3.065-.717-1.547-3.757-.172-4.073z"
        fill="#007FC8"
      />
      <path
        d="M132.541 313.573c-.029-1.032.716-2.093 1.719-2.409.974-.315 2.463.574 2.349 1.664-.115 1.147-.373 2.438-1.662 2.753-1.146.287-2.492-.889-2.406-2.008-.057-1.204-.057.804 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M135.835 330.438c-1.002.459-2.349.028-2.75-1.033-1.117-2.782 3.065-4.187 4.068-1.692.544 1.405-.258 2.237-1.318 2.725z"
        fill="#fff"
      />
      <path
        d="M179.407 347.302c.945-.029 1.862 1.147 1.89 2.008.029 1.348-1.231 2.466-2.578 2.323-.344-.029-.916-.488-1.203-.688-.458-.287-.516-.832-.487-1.348.029-1.119 1.232-2.324 2.378-2.295.573-.029-.917-.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M84.901 319.998c-2.807.201-2.721-3.7-.372-4.388 1.031-.316 2.349.946 2.32 1.979 0 1.061-.945 2.151-1.948 2.409-1.174.086.086-.029 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M174.594 301.27c-1.174.172-2.034-1.234-1.776-2.238.258-1.089 1.518-2.351 2.75-1.95.745.258 1.662 1.434 1.49 2.237-.287 1.463-1.146 1.864-2.464 1.951-.601.086.974-.087 0 0zM152.937 303.937c.201 1.09-.773 2.352-1.948 2.524-1.174.172-2.263-1.233-2.206-2.065.258-3.04 3.839-2.295 4.154-.459zM44.137 250.304c-2.95.086-1.805-4.933.63-4.188.573.172 1.118.717 1.576 1.119.315.287.086 1.09 0 1.434-.315 1.032-1.175 1.549-2.206 1.635-1.031.028 1.633-.144 0 0zM126.983 287.474c1.118.086 2.206 1.119 2.12 2.295-.057.631-.401 1.147-.83 1.577-1.204 1.09-3.18-.516-3.238-1.635-.057-1.262.659-2.18 1.948-2.237.888.086-1.002.029 0 0zM59.607 210.753c1.145.258 1.833.861 1.776 2.151-.029 1.09-.946 1.721-1.977 1.75-1.318.028-2.263-.775-2.263-1.979 0-1.033 1.146-2.237 2.464-1.922zM168.292 333.535c.516 1.176-.573 2.209-1.518 2.668-1.146.573-2.55-.488-2.493-1.635.144-3.413 3.639-1.95 4.011-1.033z"
        fill="#3BB2F4"
      />
      <path
        d="M93.238 218.468c-.058 1.004-1.031 1.865-2.005 1.951-1.175.114-2.407-.431-2.264-2.352.115-1.922 4.383-2.123 4.269.401zM151.906 286.958c2.435-.057 3.037 4.044.315 4.072-2.549 0-3.008-3.986-.315-4.072z"
        fill="#91E7F2"
      />
      <path
        d="M102.634 211.068c.028.947-1.032 1.865-1.977 1.893-1.174.058-2.005-1.09-2.005-2.208 0-1.033.888-1.979 1.948-1.922 1.232.086 1.976 1.062 2.034 2.237z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M149.758 346.355c-.058 2.553-4.813 2.065-4.097-.344.287-.975 1.346-2.093 2.435-1.864.888.201 1.719 1.291 1.662 2.208-.029.717.057-.889 0 0zM100.514 281.594c-1.117.029-2.149-.631-2.263-1.778-.057-.774.773-2.122 1.575-2.323.946-.229 2.435.545 2.435 1.52.029 1.377-.429 2.553-1.747 2.581z"
        fill="#fff"
      />
      <path
        d="M102.92 245.801c-1.06-.029-2.206-1.09-2.034-2.065.143-.861.974-2.065 1.891-1.864 3.323.631 1.805 3.957.143 3.929z"
        fill="#3BB2F4"
      />
      <path
        d="M132.254 308.267c-1.031.201-1.776-.229-2.119-.831-.545-.947-.402-2.61.916-2.983.602-.172 2.292.201 2.521.946.344.975.086 2.61-1.318 2.868z"
        fill="#007FC8"
      />
      <path
        d="M85.961 307.006c.058 2.323-4.096 2.868-3.981.229.085-2.524 3.924-2.897 3.981-.229 0 .602-.028-.947 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M195.678 349.367c.057.947-1.575 1.721-2.349 1.549-1.089-.23-1.862-1.606-1.461-2.639.372-.918 1.662-1.807 2.607-1.262 1.089.631 1.117 1.205 1.203 2.352.029.688-.115-1.262 0 0z"
        fill="#fff"
      />
      <path
        d="M170.727 322.465c-.029-.947.573-2.123 1.633-2.209 1.26-.086 2.263.603 2.148 1.864-.086 1.033-.659 1.893-1.747 2.037-1.117.143-2.091-.488-2.034-1.692-.057-1.148-.057 1.09 0 0zM184.621 309.186c.057 2.667-4.212 2.38-4.126.057.029-.947.946-1.893 1.891-1.95 1.318-.058 1.891.774 2.235 1.893.028.631-.058-.259 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M106.358 241.298c-.258-.201-.43-1.348-.459-1.692-.085-1.147.917-1.75 1.948-1.807.888-.057 1.977.688 2.034 1.635.029.516-.429 1.147-.687 1.549-.201.286-1.604 1.29-2.836.315z"
        fill="#fff"
      />
      <path
        d="M66.74 263.325c.859-.115 2.463.832 2.349 1.864-.058.631.171 2.036-2.092 2.065-2.263.029-2.463-3.671-.257-3.929z"
        fill="#007FC8"
      />
      <path
        d="M134.202 387.111c-2.32.057-3.093-3.327-.63-3.844 1.117-.229 1.834.23 2.292 1.291.573 1.291-.659 2.065-1.662 2.553-1.059.028.058-.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M181.67 322.637c2.12.831 1.375 4.216-1.031 3.757-2.435-.488-1.862-4.905 1.031-3.757z"
        fill="#007FC8"
      />
      <path
        d="M91.662 276.346c.029-1.061.688-2.237 1.776-2.209.516 0 2.177.574 2.177 1.291 0 .889-.4 2.123-1.289 2.438-1.318.459-2.32-.258-2.664-1.52.029-.746.057.201 0 0zM191.582 305.572c-.974.028-2.005-1.348-1.805-2.266.573-2.524 4.039-1.348 3.953.201-.114 1.52-1.06 2.007-2.148 2.065z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M71.122 209.749c.201 1.147.115 1.807-.802 2.581-.83.689-2.55.631-2.778-.458-.917-3.901 3.437-2.955 3.58-2.123zM162.992 345.782c.974-.861 1.834-1.147 2.75-.058.63.746.917 1.836.029 2.524-.372.316-.917.459-1.375.603-.688.172-1.289-.058-1.69-.832-.373-.746-.545-1.492.286-2.237z"
        fill="#fff"
      />
      <path
        d="M36.918 241.929c1.146-.201 1.748.487 2.091 1.491.344 1.004-.114 1.922-1.117 2.295-1.375.487-2.664-.545-2.836-1.578-.114-1.061.716-2.007 1.862-2.208zM103.207 274.109c-.917.029-1.891-.746-2.149-1.606-.315-1.061.802-2.065 1.776-2.209.802-.114 2.349.717 2.263 1.606-.086 1.148-.773 1.922-1.89 2.209-1.032.029.601-.143 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M97.535 310.476c-.917.287-1.805-.488-2.063-1.319-.344-1.062.143-1.635.974-2.266 1.146-.889 2.865.229 2.893 1.291 0 1.061-.716 1.95-1.804 2.294z"
        fill="#fff"
      />
      <path
        d="M131.166 295.533c2.349-.029 2.234 3.528.086 3.872-2.464.402-2.722-3.815-.086-3.872z"
        fill="#007FC8"
      />
      <path
        d="M61.898 273.736c-.315 1.119-.487 1.577-1.69 1.979-.802.258-1.948-.545-2.148-1.377-.258-1.09.687-2.122 1.69-2.323a1.99 1.99 0 011.46.258c.66.316.63.832.688 1.463-.086.344-.028-.373 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M56.255 245.858c1.088-.143 2.005.66 2.062 1.75.058 1.032-1.03 2.036-2.034 2.036-.916 0-2.062-1.061-1.833-2.065.2-.975.83-1.692 1.805-1.721 1.232-.172-.831.029 0 0z"
        fill="#007FC8"
      />
      <path
        d="M85.016 262.005c-.63.746-2.034.717-2.721.115-.602-.516-.831-1.549-.602-2.294.344-1.234 2.292-1.176 3.094-.775.773.402 1.031 2.037.23 2.954z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M83.584 223.373c-.917-.488-1.347-1.578-.946-2.553.344-.86 2.092-1.004 2.865-.688 1.29.545 1.146 2.093.773 2.667-.372.602-1.747 1.061-2.692.574z"
        fill="#fff"
      />
      <path
        d="M136.924 289.596c.802-.057 1.891 1.004 1.805 1.807-.058.631-.201 1.922-1.862 1.893-2.178 0-2.235-3.527.057-3.7z"
        fill="#3BB2F4"
      />
      <path
        d="M47.833 213.306c.028.975-.573 1.979-1.576 2.151-.945.172-2.177-.43-2.292-1.434-.114-1.062.688-1.779 1.605-2.123 1.145-.459 1.976.373 2.263 1.406.028 1.032-.058-.144 0 0zM37.92 309.673c-.286.717-1.69 1.09-2.176.918-1.06-.373-1.948-1.377-1.318-2.352 1.833-2.926 4.096-.115 3.495 1.434z"
        fill="#91E7F2"
      />
      <path
        d="M98.222 324.702a2.154 2.154 0 012.091-1.865c2.665-.086 1.948 3.987-.086 3.614-1.03 0-2.148-.631-2.005-1.749z"
        fill="#3BB2F4"
      />
      <path
        d="M184.878 304.424c-.945-.172-1.289-1.548-.974-2.409.344-.918 1.519-1.663 2.464-1.118.458.258.888.745 1.117 1.233.229.487-.458 2.696-2.607 2.294z"
        fill="#91E7F2"
      />
      <path
        d="M106.043 336.174c-.115-.975.859-1.922 1.747-2.122.917-.201 2.378.774 2.034 1.807-.286.86-.917 1.806-1.891 1.95a1.637 1.637 0 01-1.89-1.635c-.115-.975 0 1.176 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M130.793 347.904c-2.721 0-1.833-4.072.516-3.614 2.378.459 1.747 3.614-.516 3.614zM102.576 227.646c-.229-1.061.373-2.036 1.461-2.294.831-.201 2.407.631 2.264 1.634-.402 2.324-3.238 2.897-3.725.66z"
        fill="#fff"
      />
      <path
        d="M86.334 273.707c-2.493.23-2.292-3.728.086-3.786.515 0 1.231.373 1.46.832.889 1.836-.572 2.868-1.546 2.954-1.26.115 1.174-.086 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M157.32 303.564c-1.031-.86-1.002-1.922 0-2.839.63-.574 1.49-.717 2.206-.23 1.146.746 1.06 2.122.229 2.839-.487.402-1.432 1.062-2.435.23z"
        fill="#3BB2F4"
      />
      <path
        d="M84.128 233.956c-.372.774-1.547 1.549-2.406 1.204-1.118-.459-1.375-1.577-.946-2.581.487-1.176 1.576-1.463 2.636-1.004 1.06.459 1.232 1.32.716 2.381z"
        fill="#007FC8"
      />
      <path
        d="M127.012 321.633c.143 1.147-.945 1.979-1.976 2.208-1.089.23-1.776-.946-1.691-1.893.23-2.036 3.438-2.438 3.667-.315.115.918 0-.144 0 0zM33.28 215.973c2.55 0 2.034 3.929-.43 3.614-.888-.115-1.69-.976-1.718-1.865 0-.774 1.403-1.864 2.148-1.749 1.232 0-.601-.086 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M119.277 335.715c.029-1.061.889-2.065 2.006-2.036.773.028 1.948.975 1.719 1.835-.172.66-.974 1.951-1.777 1.922-.974-.058-1.976-.631-1.948-1.721zM131.653 337.379c-1.031-.23-2.349-.689-2.034-2.037.487-2.093 3.294-2.352 3.581.172.086.89-.802 2.037-1.547 1.865z"
        fill="#fff"
      />
      <path
        d="M94.355 225.065c-.688.487-1.776.631-2.263-.258-.602-1.09-.373-1.893.544-2.639 1.003-.803 2.32-.287 2.664.459.315.688.115 1.664-.945 2.438z"
        fill="#007FC8"
      />
      <path
        d="M86.907 230.112c-1.003 0-1.461-.286-1.69-1.261-.23-.918-.058-1.521.687-2.094.946-.717 1.862-.373 2.55.545.773 1.004-.315 2.782-1.547 2.81-1.089 0 .515 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M140.075 336.432c-1.031-.057-1.69-.803-1.747-1.836-.058-.975 1.346-1.72 2.234-1.749 1.747 0 2.034 3.728-.487 3.585z"
        fill="#91E7F2"
      />
      <path
        d="M79.459 247.636c.315-1.032.687-2.208 2.034-1.807.945.287 1.518 1.033 1.69 1.979.172.947-1.06 1.463-1.776 1.52-1.118.115-1.891-.602-1.948-1.692.114-.344.028.574 0 0z"
        fill="#007FC8"
      />
      <path
        d="M50.497 208.2c-2.521.086-2.578-3.929-.057-3.642.458.057 1.718.315 1.89 1.52.23 1.807-.86 2.122-1.833 2.122-1.318 0 .286-.172 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M65.966 220.591c-.115-2.295 3.065-2.639 3.695-.545.287 1.003-.888 1.95-1.747 2.208-1.175.402-1.604-.803-1.948-1.663-.057-.918.143.344 0 0z"
        fill="#007FC8"
      />
      <path
        d="M113.519 265.304c-.487-2.266 3.667-3.04 3.667-.545.029 1.606-3.18 2.897-3.667.545z"
        fill="#3BB2F4"
      />
      <path
        d="M96.761 216.088c.888-.947 1.89-1.004 2.865-.144.888.775.315 1.893-.43 2.553-.83.745-2.206.43-2.693-.201-.515-.602-.229-1.692.258-2.208z"
        fill="#007FC8"
      />
      <path
        d="M105.326 294.042c-.859 0-1.976-.574-1.862-1.577.144-1.234.631-1.979 1.862-2.037 2.235-.086 2.206 3.614 0 3.614zM84.873 200.342c-.2-2.495 3.781-2.639 3.523-.029-.114 1.061-1.289 1.778-2.291 1.52-.917-.229-1.232-.516-1.232-1.491-.058-.803 0 1.061 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M195.678 331.499c-2.091-.115-2.435-2.553-.458-3.27 1.146-.43 2.12-.115 2.263 1.147.114 1.148-.516 2.18-1.805 2.123z"
        fill="#91E7F2"
      />
      <path
        d="M188.23 354.444c-.802-.029-1.748-.861-1.776-1.693-.058-1.003.888-1.721 1.805-1.921.802-.201 2.148 1.29 2.005 2.036-.201.832-1.175 1.635-2.034 1.578z"
        fill="#007FC8"
      />
      <path
        d="M191.209 311.25c.029 2.295-3.495 2.266-3.638.144-.057-.746.688-1.922 1.49-2.008 1.232-.115 1.833.861 2.148 1.864 0 .947-.057-.143 0 0zM156.575 295.419c-.172.717-.802 1.72-1.633 1.606-.716-.086-2.062-.775-1.976-1.664.229-2.552 3.581-2.524 3.609.058-.114.487-.028-1.176 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M119.535 305.428c.172.689-.086 1.348-.343 1.979-.23.574-1.691.832-2.149.574-.831-.488-1.117-1.606-.802-2.438.945-2.323 3.18-.574 3.294-.115z"
        fill="#3BB2F4"
      />
      <path
        d="M138.728 341.222c2.292-.086 1.748 3.269-.229 3.528-2.492.344-2.148-3.7.229-3.528.602-.029-.544-.029 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M105.584 246.489c.573-.631 1.891-.946 2.579-.315.487.459.888 2.208.171 2.61-.286.172-1.718 1.176-2.635.229-.917-.918-.859-1.721-.115-2.524z"
        fill="#fff"
      />
      <path
        d="M96.847 318.994c-1.06 0-1.976-1.032-1.747-2.008.229-1.003.974-1.692 2.062-1.491 2.149.459 1.49 3.47-.315 3.499zM92.951 239.262c-2.263.172-1.804-4.13.66-3.413 1.059.315 1.575.889 1.288 2.036-.229.947-1.06 1.291-1.948 1.377z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M141.994 284.95c-.372 1.176-.802 1.893-2.091 1.778-.888-.086-1.633-1.118-1.404-1.979.287-1.004.946-1.434 1.977-1.434.687-.287 1.776.832 1.518 1.635z"
        fill="#fff"
      />
      <path
        d="M160.213 332.732c-.572 1.922-3.781 1.922-3.466-.401.115-.918.86-1.779 1.834-1.779 1.289 0 1.432 1.148 1.632 2.18-.028.115-.085-.401 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M141.106 363.535c.516.803-1.174 1.75-1.805 1.721-.974-.028-1.747-.832-1.862-1.778-.086-.746 1.261-1.635 1.92-1.664 1.031 0 1.833.631 1.747 1.721.258.431.057-.717 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M94.126 229.166c-.115-.803.659-1.491 1.346-1.778.774-.287 1.834.172 2.149.946.43 1.09.086 2.295-1.633 2.639-1.003.229-1.748-.889-1.862-1.807z"
        fill="#fff"
      />
      <path
        d="M28.64 252.914c.085-2.209 2.72-2.868 3.523-.689.773 2.037-3.61 3.098-3.524.689z"
        fill="#91E7F2"
      />
      <path
        d="M73.7 297.799c-.83.631-1.518 1.377-2.606.918-.946-.402-1.089-1.348-.716-2.209.458-1.032 1.403-1.204 2.377-.803.974.459.917 1.119.946 2.094-.401.316 0-.115 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M74.732 310.017c-.888 0-1.461-.574-1.633-1.434-.143-.832.745-1.692 1.461-1.95.945-.344 1.862.545 2.091 1.434.287 1.061-.974 1.95-1.919 1.95z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M120.022 326.709c.143.918-.859 1.606-1.661 1.778-.917.201-1.862-.717-2.034-1.577-.287-1.663 3.867-2.61 3.695-.201.058.516.058-1.032 0 0z"
        fill="#fff"
      />
      <path
        d="M133.486 272.216c-.544-.86.315-1.807 1.347-2.036 1.146-.23 2.349.43 2.291 1.548-.057 1.119-2.034 3.127-3.638.488z"
        fill="#91E7F2"
      />
      <path
        d="M173.162 359.205c-2.063-.029-1.662-2.955.028-3.356.946-.23 1.862.602 2.006 1.52.229 1.118-1.032 1.864-2.034 1.836-.831 0 .716.028 0 0z"
        fill="#007FC8"
      />
      <path
        d="M87.623 296.021c.286 0 .602 0 .802.229.2.258.172.402.43.631.172.172.286.402.258.631-.058.803-.86 1.979-1.72 1.95-2.52-.086-2.176-3.47.23-3.441.401-.029-1.576-.029 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M71.695 242.273c0 .889-.544 1.75-1.403 1.979-.774.201-2.063-.315-2.149-1.205-.114-1.003.716-2.179 1.776-2.179 1.118 0 1.662 1.004 1.776 1.405 0 1.061-.028-.086 0 0z"
        fill="#fff"
      />
      <path
        d="M89.399 328.315c-.115-.917.83-2.093 1.748-2.093.945 0 1.46.286 1.661 1.721.2 1.634-3.123 2.581-3.409.372z"
        fill="#007FC8"
      />
      <path
        d="M150.187 358.172c-.802.144-1.805-.717-1.747-1.52.086-.975.945-1.606 1.862-1.835 1.948-.517 2.32 3.298-.115 3.355-.773.115.688-.028 0 0zM117.53 294.702c0 .774-.659 1.95-1.547 1.95-.888 0-1.919-.803-1.948-1.75-.029-1.835 3.466-2.466 3.495-.2z"
        fill="#91E7F2"
      />
      <path
        d="M98.108 257.79c-.66-.689-.344-1.75.257-2.324.831-.774 1.748-.688 2.493.172.601.689.429 1.836.114 2.295-.343.43-2.177.574-2.864-.143z"
        fill="#3BB2F4"
      />
      <path
        d="M62.9 247.235c-2.205-.029-2.32-3.184-.228-3.442 2.263-.258 2.349 3.471.229 3.442z"
        fill="#91E7F2"
      />
      <path
        d="M31.59 231.26c.86.028 1.604.315 1.719 1.233.114.774-.258 1.721-.974 2.122-.688.402-2.235-.717-2.292-1.462-.057-1.033.372-1.922 1.547-1.893z"
        fill="#3BB2F4"
      />
      <path
        d="M83.985 291.747c1.146.689.63 1.607-.058 2.352-.601.66-1.317.803-2.09.316-.918-.574-1.06-1.778-.516-2.495.544-.746 1.718-.746 2.664-.173zM162.047 320.801c-1.003-.057-1.977-.946-1.69-1.922.258-.86 1.461-1.778 2.32-1.376 1.948.86.946 3.384-.63 3.298z"
        fill="#007FC8"
      />
      <path
        d="M46.658 230.141c-.83.115-1.747-.573-1.776-1.434-.057-.774.745-1.807 1.49-1.921 1.432-.23 1.92 1.204 1.92 1.749-.03.488-.774 1.492-1.634 1.606z"
        fill="#3BB2F4"
      />
      <path
        d="M127.127 278.21c-.172.43-.344.803-.745.975-.917.402-2.005.316-2.406-.688-.258-.631-.058-1.664.572-1.979 2.321-1.291 2.865.918 2.579 1.692z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M78.112 256.355c-.916.029-1.833-.315-1.776-1.434.057-.918.716-1.807 1.69-1.778 2.149.029 2.063 3.241.086 3.212-.687.029.802.029 0 0z"
        fill="#fff"
      />
      <path
        d="M90.316 283.717c-.373-2.036 3.724-2.18 3.323.057-.115.631-.774 1.721-1.518 1.721-.974.029-1.805-.832-1.805-1.778-.144-.746 0 .975 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M58.46 289.797c-2.291-.115-1.947-2.811-.057-3.413.86-.258 1.748.803 1.805 1.577.086 1.09-.83 1.607-1.747 1.836-.888-.029.028 0 0 0z"
        fill="#007FC8"
      />
      <path
        d="M108.535 268.803c-.802-.172-1.776-.975-1.404-1.922.373-.889 1.146-1.549 2.149-1.291 2.148.603 1.117 3.241-.745 3.213-.544-.115 1.26.028 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M170.555 340.218c.859-.029 1.289.803 1.318 1.577.028.976-1.146 1.664-2.006 1.721-.974.058-1.546-1.118-1.317-1.95.258-.889 1.146-1.319 2.005-1.348z"
        fill="#007FC8"
      />
      <path
        d="M15.806 259.51c2.09-.028 1.719 3.155-.143 3.385-2.149.287-2.149-3.356.143-3.385z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M129.161 268.803c-.631.029-1.576-.516-1.691-1.147-.143-.889.545-2.123 1.461-2.324 1.06-.258 1.834.832 1.805 1.75.029.889-.544 1.663-1.575 1.721zM192.47 338.038c-.831.287-1.891-.43-1.977-1.291-.086-.975.201-1.72 1.203-1.95 1.146-.258 1.834.258 2.063 1.119.229.86-.544 1.864-1.289 2.122z"
        fill="#fff"
      />
      <path
        d="M167.834 290.084c-.516.746-1.891.975-2.521.23-.688-.804-1.06-2.238-.172-2.697 2.607-1.434 3.437 1.406 2.693 2.467z"
        fill="#007FC8"
      />
      <path
        d="M101.402 285.266c.716-.115 1.661.229 1.976.889.373.774-.372 1.921-1.117 2.18-1.031.372-2.148-.402-2.32-1.062-.172-.659.43-1.835 1.461-2.007z"
        fill="#3BB2F4"
      />
      <path
        d="M178.662 334.683c.086 2.065-3.466 1.778-3.266-.431.086-.917.573-1.577 1.92-1.462 1.403.114 1.317 1.348 1.346 1.893.029.745-.029-.89 0 0zM80.547 275.772c-.172.889-1.146 1.692-2.062 1.463-.888-.229-1.49-1.434-1.232-2.266.2-.659 1.117-1.09 1.776-1.061.659 0 1.747.717 1.518 1.864z"
        fill="#007FC8"
      />
      <path
        d="M41.588 257.933c.802.029 1.547.373 1.718 1.233.172.832-.601 1.492-1.289 1.864-.916.459-2.005-.487-2.005-1.405 0-1.205.573-1.434 1.576-1.692 1.203.057-.058.029 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M62.414 297.139c-.516-.717.029-1.835.83-2.208 1.146-.545 2.15.315 2.292 1.147.172.803-1.633 3.212-3.122 1.061z"
        fill="#3BB2F4"
      />
      <path
        d="M33.309 204.099c-.774-.143-1.347-1.147-1.203-1.893.143-.832 1.03-1.835 1.833-1.549 2.865.976 1.318 3.786-.63 3.442zM166.602 301.47c.143-.889 1.146-1.692 2.034-1.434.773.201 1.403 1.061 1.26 1.864-.143.918-1.203 1.52-2.034 1.406-.83-.144-1.403-.918-1.26-1.836zM204.186 293.153c.144-.889 1.146-1.692 2.034-1.434.774.2 1.404 1.061 1.261 1.864-.144.918-1.203 1.52-2.034 1.405-.831-.143-1.404-.917-1.261-1.835z"
        fill="#007FC8"
      />
      <path
        d="M21.822 244.424c-2.034.029-2.378-3.126-.23-3.154.888 0 1.977.659 1.834 1.606-.143 1.061-.66 1.233-1.604 1.548-.974.029.143-.057 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M125.265 357.111c-.974 0-1.49-.717-1.375-1.664.057-.401.229-.774.572-1.003.287-.201.831-.66 1.175-.689.859-.057 1.661 1.09 1.576 1.893-.086.574-1.404 1.635-1.948 1.463-.774 0 .515.172 0 0zM56.684 298.086c-.143.659-.83 1.749-1.604 1.721-.888-.029-1.633-.89-1.633-1.75 0-1.032.888-1.721 1.834-1.434.974.287 1.432.373 1.404 1.463 0 .028.028-.889 0 0z"
        fill="#fff"
      />
      <path
        d="M116.728 258.736c-.602.717-1.518.774-2.005.373-.602-.488-.917-1.377-.545-2.123.401-.774 1.519-.946 2.12-.774.63.172 1.232 1.549.43 2.524z"
        fill="#91E7F2"
      />
      <path
        d="M129.877 368.755c0-.889.515-1.52 1.403-1.635.717-.086 1.605 1.033 1.662 1.692.086.947-.602 1.492-1.432 1.721-1.06.287-1.605-.975-1.633-1.778 0-.947 0 .172 0 0z"
        fill="#007FC8"
      />
      <path
        d="M171.042 384.788c.086-1.807 2.406-2.409 3.037-.516.744 2.237-3.094 2.753-3.037.516.086-1.176 0 .745 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M84.214 365.055c0 .803-1.174 1.52-1.92 1.32-.887-.23-1.374-1.205-1.231-2.065.315-2.008 3.151-1.033 3.151.745z"
        fill="#007FC8"
      />
      <path
        d="M167.576 317.13c-2.235-.029-1.719-3.643.172-3.184.659.172 1.547.947 1.547 1.664.028.717-1.06 1.52-1.719 1.52z"
        fill="#3BB2F4"
      />
      <path
        d="M154.856 364.195c.058 1.692-2.606 2.61-3.036.717-.487-2.036 2.979-3.097 3.036-.717z"
        fill="#91E7F2"
      />
      <path
        d="M60.838 222.053c-.802.029-1.518-.832-1.432-1.577.114-.947 1.003-1.492 1.89-1.52.688 0 1.347.917 1.404 1.548.058.889-1.117 1.52-1.862 1.549-.659 0 .917-.029 0 0z"
        fill="#007FC8"
      />
      <path
        d="M75.19 322.981c2.091.028 1.404 3.298-.4 3.269-2.092-.028-1.462-3.269.4-3.269z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M74.589 336.203c2.406.516 1.403 3.298-.401 3.269-2.092-.057-2.063-3.556.4-3.269z"
        fill="#fff"
      />
      <path
        d="M29.757 238.86c2.005-.029 2.034 2.524.229 2.954-2.091.516-2.636-2.897-.23-2.954z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M38.666 221.508c.916.603.687 1.664.229 2.438-.458.774-1.461.975-2.292.201-.573-.545-.63-1.52-.2-2.151.601-.889 1.375-.775 2.263-.488.229.545-.401-.258 0 0z"
        fill="#fff"
      />
      <path
        d="M76.852 286.843c.888.258 1.518.459 1.633 1.52.086.66-.716 1.52-1.29 1.692-1.919.603-2.492-3.269-.343-3.212.286.086-.86-.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M46.572 287.273c.115-1.864 2.98-2.352 3.065-.229.115 1.95-3.18 2.438-3.065.229z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M18.7 216.862c-1.806.201-2.293-3.442.085-3.011 1.833.315 1.948 3.068-.086 3.011-.63.057.487 0 0 0z"
        fill="#fff"
      />
      <path
        d="M126.926 331.212c-.831-.115-1.518-.344-1.604-1.291-.086-.831.63-1.921 1.547-1.893.687.029 1.719.976 1.547 1.693-.143.516-.888 1.491-1.49 1.491-1.117-.143.487 0 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M88.97 339.73c.658.086 1.546.201 1.69 1.033.114.803-.86 1.893-1.605 1.979-.86.086-1.633-.803-1.719-1.578-.114-.774.974-1.376 1.633-1.434.66.086-.573.058 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M24.657 208.659c-.515-.373-.945-1.462-.486-2.036.372-.488 1.26-.803 1.861-.775 1.175.058 1.232 1.635 1.003 2.295-.229.688-1.547 1.119-2.378.516z"
        fill="#fff"
      />
      <path
        d="M110.569 287.273c.659-.172 1.805.689 1.69 1.434-.086.516-.63 1.377-1.117 1.606-.888.431-2.206-.372-2.12-1.118.143-1.434.315-1.606 1.547-1.922z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M176.198 326.91c1.834-.057 1.519 3.212-.057 3.27-2.148.086-1.805-3.069.057-3.27.688-.029-.716.086 0 0zM75.276 206.164c-.63.602-1.432.918-2.234.488-.688-.373-1.06-1.234-.544-1.922.343-.488 1.26-1.09 1.89-.86.917.315 1.175 1.462.888 2.294-.945.947.143-.459 0 0z"
        fill="#fff"
      />
      <path
        d="M104.353 221.336c-.631.603-1.433.918-2.235.488-.687-.373-1.06-1.233-.544-1.922.344-.487 1.26-1.09 1.89-.86.889.315 1.146 1.462.889 2.294-.974.947.143-.459 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M152.135 313.688c-.859.316-1.747-.143-1.833-1.061-.086-.631.143-1.463.745-1.75.859-.401 2.12.201 2.291.976.144.745-.343 1.52-1.203 1.835z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M49.294 333.707c-.258.803-.888 1.664-1.862 1.234-.803-.373-1.204-1.09-1.032-1.951.144-.717.602-1.061 1.318-1.118 1.089-.086 1.49.889 1.576 1.835-.086.258-.086-1.118 0 0z"
        fill="#fff"
      />
      <path
        d="M102.204 249.07c.115.775-.687 1.377-1.318 1.635-1.088.43-1.976-.43-1.804-1.434.4-2.639 3.008-1.061 3.122-.201zM69.833 273.994c-.2-.832.66-1.348 1.347-1.491.658-.115 1.862.631 1.919 1.348.029.659-1.146 1.577-1.719 1.634-.86.086-1.518-.659-1.547-1.491-.143-.516.029.574 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M97.048 268.918c.114 1.692-2.865 2.266-3.094.258-.2-1.979 3.237-2.352 3.094-.258.057.487.057-.602 0 0zM162.792 329.692c-.774 0-1.576-.803-1.461-1.606.114-.803.974-1.319 1.719-1.377 2.148-.229 1.547 3.04-.258 2.983-.659 0 .487.029 0 0z"
        fill="#fff"
      />
      <path
        d="M51.843 220.046c-1.977-.115-1.89-2.811-.057-3.012 2.12-.258 2.091 3.098.057 3.012z"
        fill="#91E7F2"
      />
      <path
        d="M39.382 235.418c-.487-.43-.63-1.434-.23-1.979.43-.602 1.662-.946 2.264-.487.745.545.487 1.749.172 2.323-.344.516-1.519.717-2.206.143zM43.392 219.845c.03-1.75 3.094-1.778 3.037-.115-.029.717-.688 1.606-1.375 1.778-.86.23-1.69-.889-1.662-1.663zM133.114 321.719c-.63.086-1.633-.344-1.805-1.004-.229-.918.258-1.692 1.146-1.979 1.089-.344 1.862.545 1.919 1.205.086.659-.687 1.72-1.26 1.778z"
        fill="#007FC8"
      />
      <path
        d="M69.404 234.874c-.459.745-1.26 1.061-2.063.659-.888-.43-.86-1.233-.487-2.036.459-1.004 2.005-.832 2.464-.43.4.373.458 1.147.086 1.807z"
        fill="#91E7F2"
      />
      <path
        d="M153.424 320.715c-.716-.144-1.489-.66-1.547-1.377-.057-.745.888-1.491 1.633-1.405.831.115 1.175.746 1.232 1.491 0 .086-.573 1.463-1.318 1.291z"
        fill="#3BB2F4"
      />
      <path
        d="M70.893 224.749c.745-.2 1.633.918 1.719 1.606.115.861-.401 1.348-1.232 1.463-.916.115-1.747-.315-1.89-1.147-.172-.832.601-1.692 1.403-1.922z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M119.335 288.42c.745.23 1.661.947 1.289 1.922-.344.832-1.289 1.176-2.091.889-1.69-.602-.716-2.839.802-2.811.258.086-1.232-.028 0 0zM62.357 306.231c.028-1.95 3.409-1.807 3.008-.258-.201.774-.688 1.491-1.49 1.606-.917.144-1.232-.66-1.518-1.348 0-.688.057.143 0 0z"
        fill="#fff"
      />
      <path
        d="M20.905 227.818c.744-.029 1.633.43 1.862 1.176.229.803-1.26 1.377-1.805 1.463-.802.114-1.375-.603-1.404-1.348-.057-.947.545-1.033 1.347-1.291.86-.029-.23.086 0 0zM180.18 301.441c2.034-.086 1.662 3.184-.057 2.926-.716-.115-1.576-.43-1.404-1.319.143-.746.659-1.578 1.461-1.607.545-.028-.429.029 0 0z"
        fill="#007FC8"
      />
      <path
        d="M186.568 329.606c-.802-.058-1.174-.832-1.145-1.578.028-.774.687-1.233 1.375-1.434.916-.258 1.661.832 1.575 1.635-.114.803-.917 1.434-1.805 1.377zM119.306 345.897c-2.12 0-1.604-3.012.229-3.012.63 0 1.49 1.004 1.146 1.606-.286.602-.601 1.52-1.375 1.406-.487 0 .458.086 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M.852 238.688c-.086-.717.43-1.405 1.117-1.549.63-.143 1.662-.028 1.72 1.148.056 1.032-.316 1.606-1.462 1.663-1.146.086-1.289-.43-1.375-1.262z"
        fill="#91E7F2"
      />
      <path
        d="M180.037 292.12c-.029.775-.573 1.807-1.49 1.778-1.89-.057-1.575-2.925-.057-2.896.859.028 1.576.315 1.547 1.118z"
        fill="#3BB2F4"
      />
      <path
        d="M114.178 244.998c0 1.835-3.58 1.147-2.864-.459.315-.746.687-1.291 1.575-1.176.86.115 1.261.803 1.289 1.635 0 .602-.028-1.262 0 0z"
        fill="#007FC8"
      />
      <path
        d="M106.014 364.596c.229 1.922-2.177 1.951-2.607.316-.172-.689.487-1.492 1.089-1.721 1.002-.43 1.461.602 1.518 1.405.086.689 0-.114 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M112.775 311.222c-1.891.029-2.12-2.667-.058-2.639.573 0 1.461.287 1.461 1.004 0 .746-.573 1.606-1.403 1.635z"
        fill="#91E7F2"
      />
      <path
        d="M166.401 369.013c-.258.775-.888 1.406-1.747 1.233-.716-.143-1.175-.659-1.146-1.405.057-.918 1.031-1.262 1.833-1.29.23.114 1.347.544 1.06 1.462zM36.718 273.621c-.172 1.721-2.55 2.352-2.894.373-.114-.717.573-1.377 1.204-1.52.974-.23 1.375.344 1.69 1.147-.115 1.061-.115-.287 0 0zM86.82 252.713c1.662.172.975 2.897-.372 2.696-1.346-.201-1.432-2.897.373-2.696z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M130.965 273.65c1.662.172.974 2.897-.372 2.696-1.347-.201-1.432-2.897.372-2.696z"
        fill="#fff"
      />
      <path
        d="M64.448 321.059c2.635.574.258 3.212-.716 2.553-1.375-.975-1.032-2.553.716-2.553.086 0-1.175 0 0 0zM178.29 342.684c-.144.775-.172 1.836-1.261 1.75-.888-.086-1.404-.688-1.26-1.577.114-.89.343-1.176 1.26-1.176.86 0 1.089.2 1.261 1.003-.086.459-.058-.2 0 0zM3.774 212.847c-1.575.057-1.92-2.381-.43-2.84 2.034-.602 2.607 2.496.43 2.84-.802.114.086-.058 0 0z"
        fill="#007FC8"
      />
      <path
        d="M166.946 324.759c-1.748 0-1.519-2.811-.058-2.668.43.029 1.519 0 1.633 1.033.086.774-.687 1.778-1.575 1.635-.888 0 .601.086 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M97.65 349.883c-.517.66-1.003 1.319-1.949 1.205-.945-.115-.916-.947-.802-1.692.115-.717.917-1.062 1.604-.861.774.23.974.631 1.146 1.348-.172.258-.2-.918 0 0z"
        fill="#fff"
      />
      <path
        d="M163.651 308.382c-.573-.487-.544-1.377.029-1.864.515-.43 1.031-.918 1.69-.402.773.603.773 1.721.258 2.18-.459.459-1.404.574-1.977.086z"
        fill="#3BB2F4"
      />
      <path
        d="M137.468 307.321c-.573-.488-.544-1.377.029-1.864.515-.43 1.031-.918 1.69-.402.773.603.773 1.721.258 2.18-.459.459-1.404.574-1.977.086z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M184.649 318.765c-1.633.028-2.12-2.668-.458-2.668 1.747-.028 2.377 2.639.458 2.668z"
        fill="#fff"
      />
      <path
        d="M77.625 212.302c.143.487.487.975.143 1.434-.257.373-.4.631-.888.659-.544.029-1.088-.286-1.289-.745-.687-1.75 1.776-2.237 2.034-1.348z"
        fill="#91E7F2"
      />
      <path
        d="M190.951 376.671c.659.143 1.146.401.974 1.176-.143.602-.487 1.52-1.231 1.405-1.977-.315-1.633-2.581.257-2.581.115.029-.974 0 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M170.011 331.355c.773-2.151 2.836-.803 2.578.144-.172.631-.63 1.491-1.404 1.262-.659-.201-1.088-.717-1.174-1.406.114-.286.086.689 0 0z"
        fill="#fff"
      />
      <path
        d="M39.869 291.92c.086 1.692-2.607 1.921-2.75.315-.115-1.606 2.664-1.893 2.75-.315z"
        fill="#007FC8"
      />
      <path
        d="M52.015 312.168c-.057-1.835 2.865-1.319 2.464.143-.459 1.75-2.264 1.693-2.464-.143-.029-.975.029.201 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M115.725 356.107c-.229.717-.372 1.577-1.203 1.749-.687.144-1.232-.258-1.375-.917-.172-.66.057-1.32.716-1.578.774-.315 1.318.258 1.862.746-.143.373-.229-.201 0 0z"
        fill="#fff"
      />
      <path
        d="M98.25 335.227c0-.717.201-1.147.975-1.147.601 0 1.518.287 1.461 1.033-.057.631-.344 1.663-1.146 1.606-.802-.086-1.347-.66-1.29-1.492 0-.831 0 .029 0 0z"
        fill="#007FC8"
      />
      <path
        d="M178.605 372.742c-1.805 0-1.461-2.409.229-2.438.659 0 1.031.545 1.089 1.147.085.918-.487 1.291-1.318 1.291z"
        fill="#3BB2F4"
      />
      <path
        d="M121.311 315.581c-.63-.172-.744-1.119-.544-1.606.287-.66.945-.975 1.805-.603.888.373.515 2.639-1.261 2.209zM118.332 254.376c-.63-.172-.745-1.118-.544-1.606.286-.659.945-.975 1.805-.602.888.401.515 2.667-1.261 2.208z"
        fill="#007FC8"
      />
      <path
        d="M124.864 305.113c-1.691.086-1.891-2.639-.086-2.266.487.115 1.231.315 1.26.918 0 .889-.286 1.29-1.174 1.348zM117.816 275.571c-.057-.917.344-1.261 1.032-1.376 1.69-.258 2.177 2.266.086 2.18-.401 0-1.089-.259-1.118-.804z"
        fill="#3BB2F4"
      />
      <path
        d="M64.706 227.617c-.058-.918.343-1.262 1.03-1.377 1.691-.258 2.178 2.266.087 2.18-.401 0-1.06-.258-1.117-.803zM63.445 215.772c-.057-.918.344-1.262 1.031-1.377 1.69-.258 2.178 2.266.086 2.18-.4.029-1.06-.258-1.117-.803z"
        fill="#007FC8"
      />
      <path
        d="M98.022 225.151c-.058-.918.343-1.262 1.03-1.377 1.691-.258 2.178 2.266.087 2.18-.401.029-1.06-.23-1.117-.803z"
        fill="#3BB2F4"
      />
      <path
        d="M170.612 350.113c-.057-.918.344-1.262 1.032-1.377 1.69-.258 2.177 2.266.085 2.18-.372 0-1.059-.258-1.117-.803z"
        fill="#91E7F2"
      />
      <path
        d="M183.389 394.195c-.058-.918.343-1.262 1.031-1.377 1.69-.258 2.177 2.266.086 2.18-.401 0-1.089-.258-1.117-.803z"
        fill="#3BB2F4"
      />
      <path
        d="M93.581 259.539c-.057-.918.344-1.262 1.032-1.377 1.69-.258 2.177 2.266.086 2.18-.373 0-1.06-.258-1.118-.803zM107.933 261.69c-.057-.918.344-1.262 1.032-1.377 1.69-.258 2.234 2.352.172 2.237-.43.029-1.175-.315-1.204-.86z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M89.829 232.321c-.057-.918.344-1.262 1.031-1.377 1.69-.258 2.177 2.266.086 2.18-.401 0-1.089-.258-1.117-.803z"
        fill="#fff"
      />
      <path
        d="M82.323 240.065c-.057-.918.344-1.262 1.032-1.377 1.69-.258 2.177 2.266.085 2.18-.4 0-1.088-.258-1.117-.803z"
        fill="#91E7F2"
      />
      <path
        d="M153.51 283.659c-.057-.917.344-1.262 1.032-1.376 1.69-.258 2.177 2.265.086 2.179-.402 0-1.06-.258-1.118-.803zM191.754 294.329c-.058-.918.343-1.262 1.031-1.377 1.69-.258 2.177 2.266.086 2.18-.401.029-1.089-.258-1.117-.803z"
        fill="#3BB2F4"
      />
      <path
        d="M120.309 365.715c-.086-1.348 2.893-1.491 2.521.115-.373 1.577-2.435 1.577-2.521-.115-.029-.488.028.602 0 0zM83.813 332.79c.086-.832.458-1.148 1.232-1.234.601-.086 1.346.316 1.289 1.004-.057.746-.888 1.463-1.433 1.492-.515-.029-1.145-.574-1.088-1.262z"
        fill="#007FC8"
      />
      <path
        d="M154.083 374.52c-.057-1.319 1.49-2.036 2.263-.775.802 1.205-2.091 2.668-2.263.775-.028-.861.058.602 0 0zM18.842 280.906c1.805-.43 2.006 2.667.143 2.581-1.804-.086-1.604-2.237-.143-2.581zM97.22 243.793c0 1.32-2.006 1.148-2.177 0-.258-1.606 2.205-1.778 2.177 0z"
        fill="#3BB2F4"
      />
      <path
        d="M75.42 234.415c0 1.319-2.006 1.147-2.178 0-.258-1.606 2.177-1.778 2.177 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M27.522 218.956c-.114 1.032-2.349 1.549-2.291.029.057-1.463 2.32-1.693 2.291-.029 0 .029 0-.574 0 0zM107.246 329.606c-.745-.344-1.06-.459-.831-1.319.143-.574.544-.775 1.117-.746.516.029 1.204.229 1.118.86a1.353 1.353 0 01-.459.861c-.229.258-.859.143-.945.344-.287-.143 0-.029 0 0z"
        fill="#fff"
      />
      <path
        d="M106.644 348.277c1.375-.086 1.089 1.864-.028 1.721-.631-.086-.802-.459-.774-1.061 0-.574.287-.602.802-.66z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M337.107 208.315c1.031-.028 2.062.287 2.922.832.573.373.916 2.008 1.002 2.696.144 1.061-.114 1.577-.802 2.323-.515.545-1.346 1.004-2.206 1.09-2.606.287-4.268-1.864-4.325-3.356-.057-2.151 1.461-3.613 3.409-3.585 1.06-.028-1.031-.028 0 0zM230.971 288.363c.63.43 3.581 4.503-.229 6.339-2.607 1.261-4.613-1.463-4.813-2.438-.602-2.754 1.031-3.7 2.234-4.159.573-.201 2.063-.229 2.808.258z"
        fill="#fff"
      />
      <path
        d="M317.025 277.636c1.748-.086 3.753 1.262 3.724 3.184-.028 1.664-.802 2.897-2.435 3.356-2.062.602-4.239-.689-4.612-2.639-.487-2.61 2.263-3.843 3.323-3.901z"
        fill="#3BB2F4"
      />
      <path
        d="M291.501 296.25c.63.144 1.891.689 2.263 1.262.43.631 1.06 1.692.974 2.467-.114 1.004-.659 1.95-1.432 2.581-1.69 1.405-3.81.258-4.698-1.434-1.461-2.753 1.518-5.191 2.893-4.876zM229.624 340.132c-.544.631-1.403.946-2.234.946s-3.237-.057-3.438-3.47c-.057-.832.401-2.237.946-2.897.487-.602 1.804-.573 2.521-.545.888.029 1.661.775 2.32 1.348.315.287 1.375 2.897-.115 4.618z"
        fill="#91E7F2"
      />
      <path
        d="M314.934 249.357c.602-.573 1.518-.745 2.321-.774a3.498 3.498 0 012.492.918c.745.717.888 1.348 1.06 2.323.114.832.086 1.663-.573 2.266-.573.516-1.232 1.118-2.063 1.147-5.471.143-3.953-5.22-3.237-5.88z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M281.131 341.021c.458 1.491-1.089 4.13-2.807 4.015-.459-.029-1.576 0-2.521-.688-.716-.516-1.261-1.692-1.375-2.553-.115-.832.172-1.692.745-2.323.658-.746 1.231-.832 2.148-1.004 1.69.029 3.294.975 3.81 2.553z"
        fill="#fff"
      />
      <path
        d="M200.004 335.055c-.086-.86.343-1.606.945-2.179.716-.717 1.404-.631 2.349-.459 1.518.287 3.123 1.778 2.893 3.355-.257 1.779-1.117 3.27-3.151 3.155-1.719-.086-3.008-2.294-3.036-3.872-.115-1.004 0 .975 0 0zM278.009 273.994c.658-.602.802-.745 1.661-.745.888 0 3.18 0 3.781 2.839.172.832-.143 1.807-.544 2.553-.315.602-1.862 1.175-2.549 1.118-4.727-.43-2.894-5.249-2.349-5.765z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M215.416 316.241c.487-.889 1.375-.832 2.263-.717 1.862.258 3.437 1.778 2.807 3.757-.258.803-.687 1.577-1.375 2.094-.745.544-1.346.602-2.177.372-.716-.2-1.404-.545-1.805-1.204-.229-.258-.974-1.979.287-4.302z"
        fill="#fff"
      />
      <path
        d="M246.182 301.126c.401-1.663 2.263-2.581 3.839-2.151 1.661.459 2.778 1.95 2.406 3.757-.315 1.549-1.575 2.266-3.094 2.266-3.179 0-3.38-2.954-3.151-3.872z"
        fill="#3BB2F4"
      />
      <path
        d="M253.315 285.122c2.12.115 3.696.918 3.782 3.241.085 2.094-2.178 2.925-3.925 2.467-1.69-.431-2.636-2.324-2.521-3.155.258-1.549 1.891-2.524 2.664-2.553.917.029-.888.029 0 0zM270.732 271.986c.287 1.951-.515 3.786-2.578 3.93-3.094.2-3.409-2.639-3.466-3.241-.115-1.893 1.461-2.868 2.607-2.926 1.203-.086 3.208.66 3.437 2.237z"
        fill="#91E7F2"
      />
      <path
        d="M278.095 246.518c.372-.746 1.06-1.348 1.862-1.52 1.489-.344 3.924.946 3.81 2.696-.029.688 0 3.069-1.891 3.269-4.698.488-4.154-3.699-3.781-4.445z"
        fill="#3BB2F4"
      />
      <path
        d="M281.876 267.082c-1.518-.287-3.094-1.893-2.951-3.528.058-.659.287-1.491.745-1.979.201-.201 1.547-.831 2.177-.803 1.519.144 3.467 2.237 3.123 3.815-.115.602-.802 1.52-1.261 1.95-.487.516-1.174.574-1.833.545-.602-.115 1.346.029 0 0z"
        fill="#007FC8"
      />
      <path
        d="M296.114 244.109c1.317.028 3.122 1.749 3.151 3.069.028 1.548-1.261 3.068-2.836 3.126-1.748.057-3.037-1.578-3.152-3.213-.143-1.52 1.29-2.982 2.837-2.982.773 0-.631 0 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M261.078 275.313c-.458.775-1.718.861-2.52 1.004-1.691.287-2.894-1.692-2.836-3.183.086-1.922 1.518-2.955 3.323-2.955 1.174 0 3.523 2.553 2.033 5.134z"
        fill="#fff"
      />
      <path
        d="M265.146 296.078c-.515.774-1.375.918-2.234 1.147-1.404.373-2.865-1.95-2.951-3.155-.114-1.807 1.089-2.667 2.779-2.868.774-.086 1.604.431 2.12 1.004.344.43 1.461 2.065.286 3.872z"
        fill="#3BB2F4"
      />
      <path
        d="M316.367 202.464c.028-1.491 1.117-2.552 2.578-2.868.773-.143 3.151.316 3.438 2.581.2 1.606-1.06 3.499-3.008 3.327-2.75-.229-2.808-2.323-3.008-3.04 0-1.348.172.66 0 0z"
        fill="#007FC8"
      />
      <path
        d="M249.649 325.189c-.373.545-1.29.889-1.92 1.004-1.547.201-3.151-1.721-3.008-3.155.086-.746.43-1.463.946-2.008a3.09 3.09 0 01.916-.688c.573-.287.917-.057 1.519-.086 1.604-.029 3.008 2.725 1.547 4.933z"
        fill="#3BB2F4"
      />
      <path
        d="M295.884 275.027c-2.32.057-2.778-1.922-2.979-2.696-.43-1.521 1.06-2.983 2.521-2.983 1.747-.029 2.979.975 3.208 2.696.201 1.635-1.06 3.269-2.75 2.983-.63.086.716.114 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M274.772 253.143c.945.459 1.919.803 2.062 1.979.115 1.004-.659 2.065-1.661 2.237-.974.172-2.493-.717-2.664-1.749-.086-.517 0-1.434.343-1.836.459-.574 1.261-.545 1.92-.631.401.172-.316.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M306.082 218.984c1.49-.028 2.751 1.262 2.98 2.696.143.775-.172 1.463-.545 2.094-.343.602-1.117.803-1.776.947-.63.143-1.317-.115-1.862-.459-3.208-1.951-.2-5.278 1.203-5.278 1.49 0-.888 0 0 0z"
        fill="#fff"
      />
      <path
        d="M299.121 288.593c-.831-.029-1.203.057-1.805-.488-.573-.545-1.117-1.147-1.375-1.922-.401-1.319 1.146-2.782 2.435-2.896a2.739 2.739 0 011.805.487c.43.287 1.203 1.09 1.174 1.635-.028.717.058 1.635-.429 2.237-.401.516-1.117 1.004-1.805.947-.372 0 .831.057 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M334.557 247.407c-1.833-.029-2.779-1.119-3.266-2.782-.372-1.319.974-2.524 2.178-2.553 1.403-.028 2.492.201 2.921 1.721.43 1.377-.085 3.643-1.833 3.614-.917 0 .802 0 0 0z"
        fill="#fff"
      />
      <path
        d="M237.445 336.575c-1.661.23-3.122-1.032-3.122-2.552-.029-1.922 1.489-2.897 2.32-2.811 1.547.143 2.836 1.319 2.922 2.954.086 1.578-.945 2.237-2.12 2.409zM341.203 238.946c-1.318.172-3.38-1.348-2.95-2.925.372-1.291 1.174-2.438 2.692-2.438.659 0 1.49.631 1.977 1.033.63.573 1.404 3.957-1.719 4.33z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M300.353 256.069c.229 1.405-.888 2.724-2.263 2.896-1.432.173-3.008-.774-2.922-2.38.029-.574.143-2.467 2.206-2.811 2.034-.315 2.778 1.061 2.979 2.295zM271.305 279.788c3.209-.173 3.495 5.162.143 5.277-1.403.057-2.635-1.205-2.635-2.581 0-.603.143-1.578.487-2.094.401-.545 1.404-.516 2.005-.602 1.117-.058-.315.057 0 0z"
        fill="#fff"
      />
      <path
        d="M291.73 233.095c-.114-.717-.257-1.434.316-1.921.687-.603 3.265-1.779 4.898 1.176.688 1.233-.258 3.384-1.919 3.155-1.375 0-3.037-.947-3.295-2.41zM309.663 250.332c.058 1.32-.802 3.127-2.32 2.983-1.432-.143-3.18-1.29-3.123-2.897.029-.975 1.49-2.552 2.521-2.323 1.261.287 2.836.66 2.922 2.237.058 1.549-.028-.631 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M351.659 251.881c1.175.058 2.578.947 2.836 2.151.287 1.32-.716 3.041-2.206 2.897-1.575-.143-3.351-.975-3.151-2.811.144-1.462 1.146-2.122 2.521-2.237.917.029-1.976.172 0 0zM355.326 235.447c-2.149.287-3.037-1.032-3.323-2.036-.43-1.463 1.003-3.069 2.492-2.84 1.261.172 2.378 1.062 2.636 2.352.257 1.291-.459 2.495-1.805 2.524-1.031.201.859-.029 0 0z"
        fill="#007FC8"
      />
      <path
        d="M286.832 238.487c-1.604.431-2.951-1.032-3.008-2.552-.057-1.606 1.203-2.897 2.836-2.467.63.172 2.034.717 2.034 2.639 0 .086 0 1.893-1.862 2.38z"
        fill="#3BB2F4"
      />
      <path
        d="M292.389 224.921c-1.346.144-2.721-.746-3.18-2.007-.458-1.234.487-2.639 1.719-2.926.802-.201 3.151.602 3.409 1.979.258 1.377-.344 2.782-1.948 2.954z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M361.285 206.852c-1.146.259-2.894-1.434-2.779-2.523.43-4.044 5.3-2.782 5.128-.029a3.272 3.272 0 01-.602 1.692c-.487.717-.974.774-1.747.86-.774.173.286-.028 0 0z"
        fill="#fff"
      />
      <path
        d="M268.211 315.38c.43 1.377-.601 2.897-1.976 2.811-.688-.057-1.146.143-1.748-.373-.63-.516-1.117-1.262-1.174-2.065-.057-.574.229-1.176.515-1.692.487-.832 3.467-1.549 4.383 1.319z"
        fill="#3BB2F4"
      />
      <path
        d="M239.193 345.38c.229 1.348-1.404 2.869-2.693 2.668-1.318-.23-2.464-1.406-2.263-2.811.114-.688.974-2.237 2.263-2.237 1.031 0 2.435.889 2.693 2.38z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M268.212 343.401c1.26.488 2.119 1.692 2.062 3.069 0 .574-.773 1.377-1.26 1.578-3.18 1.204-3.925-1.721-3.81-2.782.2-1.893 2.492-2.065 3.008-1.865z"
        fill="#fff"
      />
      <path
        d="M256.18 337.78c1.375.029 2.12 1.205 2.464 2.352.171.631.085 1.233-.287 1.778-.286.402-.945 1.176-1.49 1.205-1.145.028-2.091-.832-2.435-1.836-.2-.631-.544-1.434-.2-2.065.372-.631 1.289-1.205 1.948-1.434 1.003.029-.086.029 0 0z"
        fill="#007FC8"
      />
      <path
        d="M260.162 324.042c-1.318.115-2.579-.918-2.607-2.208-.029-1.721 1.489-2.238 1.719-2.352 1.489-.717 2.75.545 2.836 2.065.143 1.348-.63 2.409-1.948 2.495z"
        fill="#91E7F2"
      />
      <path
        d="M225.9 320.572c-2.52.2-3.781-3.844-1.346-4.876 1.232-.516 4.268.975 3.037 3.9-.287.717-.974.861-1.691.976-1.174.086.258-.058 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M199.001 352.464c-.258-2.495 3.781-3.871 4.841-1.434.545 1.234-.744 2.897-2.148 3.184-1.69.344-2.55-1.061-2.693-1.75-.114-1.204.057.259 0 0z"
        fill="#fff"
      />
      <path
        d="M205.361 303.449c1.317-.459 2.692.602 3.065 1.836.43 1.29-.888 2.552-2.063 2.724-.573.086-1.375.23-1.833-.258-.458-.487-.831-1.032-1.003-1.692-.229-1.061.86-2.266 1.834-2.61z"
        fill="#91E7F2"
      />
      <path
        d="M310.866 289.654c.086 3.47-4.956 2.896-4.726-.201.086-1.205 1.26-1.807 2.291-2.122 1.175-.316 2.521 1.233 2.435 2.323 0 .717.029-.603 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M314.275 266.365c.201.201 1.032 2.065-.573 3.184-1.117.774-2.291.114-3.179-.631-1.204-.976-.058-3.012.515-3.442.544-.43 2.378-.057 3.237.889z"
        fill="#fff"
      />
      <path
        d="M236.041 318.822c-.172-1.176.688-2.524 2.206-2.524 1.175 0 2.091.545 2.32 1.922.258 1.52-.601 2.61-1.919 2.61-1.547.028-2.378-.488-2.607-2.008z"
        fill="#91E7F2"
      />
      <path
        d="M359.881 216.145c-1.032-.086-2.063-.488-2.178-1.664-.114-1.319.573-2.896 2.034-2.925 1.519-.029 2.435 1.205 2.636 2.581.172 1.147-1.518 2.037-2.492 2.008-.144 0 .659.029 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M201.465 294.874c-.029 1.147-.917 2.294-2.12 2.351-.716.029-1.432-.544-2.005-.889-.602-.344-.487-1.434-.344-2.007.286-1.119 1.117-2.151 2.349-1.865 1.06.287 2.177 1.234 2.12 2.41-.029 1.233.086-1.463 0 0z"
        fill="#fff"
      />
      <path
        d="M314.247 215.657c1.06 0 2.492.689 2.377 2.41-.057 1.319-1.203 2.38-2.549 2.122-1.232-.229-2.235-1.233-2.235-2.467 0-1.29 1.347-2.065 2.407-2.065z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M289.639 247.206c1.089 0 2.492 1.148 2.292 2.295-.201 1.09-1.06 2.438-2.263 2.438-1.261 0-2.321-1.377-2.378-2.582-.029-1.147 1.289-2.151 2.349-2.151z"
        fill="#fff"
      />
      <path
        d="M278.582 288.162c-2.951-.086-2.435-4.961.401-4.388 1.174.23 2.205.975 2.263 2.266.028 1.405-1.461 2.122-2.664 2.122-1.204-.028 1.518 0 0 0z"
        fill="#007FC8"
      />
      <path
        d="M246.726 279.271c-.057-1.29 1.175-2.007 2.378-1.806.573.114 1.175.516 1.633.831.258.201 1.318 3.012-1.719 3.442-1.146.172-2.292-1.147-2.292-2.467-.028-.803 0 .689 0 0zM242.229 309.013c-1.089.172-2.349-.803-2.492-1.864-.143-.975.544-2.667 1.719-2.725 1.145-.057 2.606.861 2.778 2.037a2.53 2.53 0 01-.401 1.749c-.458.602-.945.631-1.604.803-.945.115.344-.086 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M259.732 305.399c-.258-1.405 1.547-2.552 2.12-2.409 1.174.287 2.206.918 2.435 2.18.143.803-.774 2.409-1.748 2.409-1.317-.029-2.864-.66-2.807-2.18.057-.401-.029.746 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M324.244 245.112c1.204.029 2.407.545 2.521 1.664.172 1.893-.601 3.069-2.034 2.897-3.981-.488-2.062-4.589-.487-4.561z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M211.892 312.828c-.573-.058-1.174-.889-1.49-1.406-.171-.287-.057-1.061 0-1.376.058-.603.516-1.09.946-1.521 1.174-1.089 2.492-.2 3.151.918.659 1.09.114 3.671-2.607 3.385z"
        fill="#fff"
      />
      <path
        d="M281.16 308.526c1.146-.144 2.635 1.061 2.521 2.351-.115 1.348-1.032 2.467-2.578 2.151-1.948-.372-2.006-4.216.057-4.502z"
        fill="#3BB2F4"
      />
      <path
        d="M282.936 324.529c.344 1.291-.315 2.725-1.747 2.811-3.152.201-3.295-4.359-.201-4.388 1.003 0 1.662.401 1.948 1.577z"
        fill="#91E7F2"
      />
      <path
        d="M299.007 293.354c.487.086 1.06.43 1.403.717.516.459.717 1.176.659 1.835-.114 1.147-1.088 2.409-2.263 1.979-3.867-1.434-1.403-4.818.201-4.531z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M250.45 330.581c-.086-1.061.717-2.151 1.862-2.008.688.086 2.407.316 2.493 2.266.086 2.61-4.326 2.811-4.355-.258-.086-1.348 0 .631 0 0z"
        fill="#fff"
      />
      <path
        d="M323.442 240.208c-1.203-.057-2.091-.975-2.263-2.151-.401-2.553 2.636-2.553 3.638-1.491.487.516.745 1.032.831 1.606.172 1.204-1.146 2.036-2.206 2.036-1.432-.057.115 0 0 0zM309.635 235.763c.028-2.811 3.724-2.18 4.125-1.148.172.431.401 1.377.401 1.836-.029 1.176-1.404 1.836-2.464 1.75-1.203-.115-2.005-1.262-2.062-2.438.143-.402.057 1.548 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M202.553 314.004c-.057-.574.058-1.205.459-1.664.286-.315.916-.975 1.375-.889.573.115 1.174.574 1.575 1.004.43.487 1.404 3.097-1.461 3.671-1.088.258-1.862-1.032-1.948-2.122z"
        fill="#007FC8"
      />
      <path
        d="M307.2 236.623c1.06.717 1.346 1.807.716 2.954-.745 1.348-2.12 1.062-3.209.316-1.289-.86-.744-2.725-.2-3.27.544-.545 1.805-.602 2.693 0z"
        fill="#91E7F2"
      />
      <path
        d="M291.53 207.77c-.258-1.176.344-2.466 1.891-2.896.773-.201 2.836-.172 2.177 2.724-.716 3.069-3.753 1.549-4.068.172zM311.84 262.579c-1.031.029-2.091-.717-2.406-1.721-.315-.975.573-2.466 1.662-2.352 1.145.115 2.435.373 2.75 1.664.286 1.118-.888 2.466-2.006 2.409-1.203.029.802.057 0 0zM328.713 259.252c.459 1.004.029 2.352-1.031 2.754-2.779 1.118-4.182-3.069-1.69-4.073 1.403-.516 2.234.258 2.721 1.319z"
        fill="#007FC8"
      />
      <path
        d="M345.529 215.629c-.029-.947 1.145-1.864 2.005-1.893 1.346-.029 2.464 1.233 2.32 2.581-.028.344-.487.918-.687 1.205-.287.458-.831.516-1.347.487-1.117-.029-2.32-1.233-2.291-2.38 0-.545 0 .917 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M318.257 310.246c.201 2.811-3.695 2.725-4.383.373-.315-1.032.946-2.352 1.977-2.323 1.088.029 2.177.947 2.406 1.95.086 1.176 0-.086 0 0zM299.551 220.476c.172 1.176-1.232 2.036-2.235 1.778-1.088-.258-2.349-1.52-1.948-2.753.258-.746 1.433-1.664 2.235-1.492 1.461.258 1.862 1.119 1.948 2.467.114.602-.057-1.004 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M302.244 242.13c1.088-.201 2.349.774 2.521 1.95.172 1.176-1.232 2.266-2.063 2.209-3.036-.259-2.32-3.844-.458-4.159zM248.674 351.088c.086 2.954-4.927 1.807-4.182-.631.172-.574.716-1.119 1.117-1.578.287-.315 1.089-.086 1.433 0 1.002.287 1.518 1.176 1.632 2.209.029 1.004-.171-1.635 0 0zM285.772 268.114c.086-1.118 1.117-2.208 2.292-2.122.63.057 1.146.402 1.575.832 1.089 1.204-.515 3.183-1.633 3.241-1.231.057-2.177-.66-2.234-1.951.086-.889.057 1.004 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M209.142 335.572c.258-1.148.859-1.836 2.149-1.779 1.088.029 1.718.947 1.747 1.979.029 1.32-.773 2.266-1.977 2.266-1.002 0-2.234-1.118-1.919-2.466zM331.778 226.757c1.175-.516 2.206.574 2.665 1.52.572 1.147-.487 2.553-1.633 2.495-3.381-.143-1.948-3.614-1.032-4.015z"
        fill="#fff"
      />
      <path
        d="M217.507 303.306c.659.028 1.232.688 1.318 1.348.086.803-.287 1.634-1.576 1.52-1.289-.058-1.432-2.954.258-2.868zM283.48 243.162c-.057-2.438 4.039-3.04 4.068-.315-.029 2.552-4.011 3.011-4.068.315z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M207.452 297.455c.688-.029 1.346.746 1.375 1.434.029.86-.802 1.463-1.604 1.463-.745 0-1.461-.631-1.404-1.406.086-.917.802-1.462 1.633-1.491zM344.612 245.342c2.55.058 2.063 4.819-.344 4.101-.974-.286-2.091-1.348-1.862-2.437.201-.918 1.261-1.721 2.206-1.664.716 0-.888-.057 0 0z"
        fill="#fff"
      />
      <path
        d="M279.899 294.616c.029 1.118-.63 2.151-1.776 2.265-.773.058-2.119-.774-2.32-1.577-.229-.947.544-2.438 1.518-2.438 1.375-.029 2.55.43 2.578 1.75z"
        fill="#91E7F2"
      />
      <path
        d="M238.133 294.73c-.029 1.061-1.089 2.209-2.063 2.037-.859-.144-2.063-.976-1.862-1.893.659-3.327 3.982-1.779 3.925-.144z"
        fill="#3BB2F4"
      />
      <path
        d="M306.541 262.837c.2 1.033-.229 1.778-.831 2.123-.945.544-2.607.401-2.979-.918-.172-.603.2-2.295.945-2.524 1.003-.344 2.607-.086 2.865 1.319z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M305.28 309.214c2.321-.057 2.865 4.101.23 3.987-2.493-.086-2.894-3.93-.23-3.987.602-.029-.945 0 0 0z"
        fill="#fff"
      />
      <path
        d="M343.953 202.837c.946-.057 1.719 1.577 1.547 2.352-.229 1.09-1.604 1.864-2.635 1.463-.917-.373-1.805-1.664-1.261-2.61.602-1.09 1.204-1.119 2.349-1.205.659-.057-1.26.115 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M320.721 224.319c-.946.029-2.12-.574-2.206-1.635-.086-1.262.602-2.266 1.862-2.151 1.031.086 1.891.66 2.034 1.75.143 1.147-.458 2.093-1.69 2.036-1.146.057 1.088.057 0 0zM307.457 210.409c2.665-.058 2.378 4.216.058 4.13-.946-.029-1.891-.947-1.948-1.893-.029-1.291.802-1.864 1.89-2.237.631 0-.229.086 0 0z"
        fill="#007FC8"
      />
      <path
        d="M239.651 288.793c-.201.259-1.347.431-1.69.459-1.146.086-1.748-.917-1.805-1.95-.057-.889.687-1.979 1.633-2.036.515-.029 1.146.43 1.547.688.286.172 1.289 1.606.315 2.839z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M200.72 303.592c-.201.259-1.346.431-1.69.459-1.146.086-1.748-.917-1.805-1.95-.057-.889.688-1.979 1.633-2.036.516-.029 1.146.43 1.547.688.315.172 1.289 1.606.315 2.839z"
        fill="#fff"
      />
      <path
        d="M261.652 328.459c-.115-.861.83-2.467 1.862-2.352.63.057 2.033-.172 2.062 2.094.029 2.265-3.638 2.437-3.924.258z"
        fill="#007FC8"
      />
      <path
        d="M385.29 260.887c.058 2.323-3.323 3.097-3.838.631-.229-1.119.229-1.836 1.289-2.295 1.289-.573 2.062.66 2.549 1.664.029 1.061-.028-.057 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M320.893 213.363c.83-2.122 4.211-1.377 3.752 1.033-.487 2.438-4.898 1.864-3.752-1.033z"
        fill="#fff"
      />
      <path
        d="M274.657 303.478c-1.06-.029-2.234-.688-2.206-1.778 0-.517.573-2.18 1.289-2.18.888 0 2.12.402 2.435 1.291.459 1.348-.258 2.323-1.518 2.667-.745 0 .229-.057 0 0z"
        fill="#007FC8"
      />
      <path
        d="M303.848 203.439c.029.976-1.346 2.008-2.263 1.807-2.521-.573-1.347-4.044.2-3.958 1.547.115 2.006 1.062 2.063 2.151z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M208.139 324.042c1.146-.201 1.805-.115 2.579.803.687.832.63 2.553-.459 2.782-3.896.918-2.95-3.442-2.12-3.585z"
        fill="#fff"
      />
      <path
        d="M344.039 232.063c-.859-.975-1.146-1.836-.057-2.754.745-.631 1.833-.917 2.521-.028.315.373.458.917.601 1.376.172.689-.057 1.291-.83 1.693-.774.372-1.49.544-2.235-.287zM240.31 358.287c-.201-1.148.487-1.75 1.489-2.094 1.003-.344 1.92.115 2.292 1.118.487 1.377-.544 2.668-1.576 2.84-1.088.143-2.005-.717-2.205-1.864zM272.423 291.919c.028.918-.745 1.893-1.605 2.151-1.059.316-2.062-.803-2.205-1.778-.115-.803.716-2.352 1.604-2.266 1.146.086 1.919.775 2.206 1.893.057 1.061-.144-.573 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M308.775 297.598c.287.918-.487 1.807-1.317 2.065-1.06.345-1.633-.143-2.264-.975-.888-1.147.23-2.868 1.29-2.896 1.031 0 1.919.745 2.291 1.806z"
        fill="#007FC8"
      />
      <path
        d="M293.851 263.956c-.029-2.352 3.523-2.237 3.867-.086.372 2.438-3.839 2.696-3.867.086z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M272.05 333.277c1.117.316 1.576.488 1.977 1.692.258.803-.545 1.951-1.375 2.151-1.089.258-2.12-.688-2.321-1.692-.114-.516 0-1.032.258-1.463.315-.659.831-.631 1.461-.688.344.086-.372.029 0 0z"
        fill="#fff"
      />
      <path
        d="M244.205 338.927c-.143-1.09.659-2.007 1.748-2.065 1.031-.057 2.034 1.033 2.034 2.037 0 .917-1.06 2.065-2.063 1.835-.974-.201-1.69-.803-1.719-1.807-.143-1.233.029.832 0 0z"
        fill="#007FC8"
      />
      <path
        d="M210.632 300.811c-.144-1.09.659-2.008 1.747-2.065 1.031-.058 2.034 1.032 2.034 2.036 0 .918-1.06 2.065-2.063 1.835-1.002-.2-1.69-.831-1.718-1.806-.172-1.234 0 .831 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M196.709 316.183c-.143-1.089.659-2.007 1.748-2.065 1.031-.057 2.034 1.033 2.034 2.037 0 .918-1.06 2.065-2.063 1.835-.974-.172-1.69-.803-1.719-1.807-.143-1.233.029.861 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M194.962 342.942c-.143-1.089.659-2.007 1.747-2.065 1.032-.057 2.034 1.033 2.034 2.037 0 .918-1.06 2.065-2.062 1.835-.974-.2-1.69-.831-1.719-1.807-.143-1.233.029.832 0 0zM260.333 310.132c.745.631.717 2.036.115 2.724-.516.603-1.547.832-2.292.603-1.231-.344-1.174-2.295-.773-3.098.401-.774 2.034-1.032 2.95-.229z"
        fill="#fff"
      />
      <path
        d="M221.775 311.566c-.487.918-1.575 1.348-2.549.946-.86-.344-1.003-2.093-.688-2.868.544-1.29 2.091-1.147 2.664-.774.602.373 1.06 1.749.573 2.696z"
        fill="#3BB2F4"
      />
      <path
        d="M287.892 258.162c-.057-.803 1.003-1.893 1.805-1.807.63.058 1.919.201 1.89 1.865 0 2.208-3.523 2.265-3.695-.058zM211.692 347.388c.974-.029 1.976.574 2.148 1.577.172.918-.43 2.18-1.432 2.295-1.06.114-1.776-.689-2.12-1.606-.43-1.148.372-2.008 1.404-2.266 1.031-.029-.144.029 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M305.137 357.828c.659-.172 2.148-.918 2.807-.516.315.2.344.659.573.946.287.373.516.746.373 1.205-.373 1.061-1.261 1.634-2.349 1.319-.545-.143-1.175-.258-1.203-.889-.086-.66-.144-1.377-.201-2.065.716-.201.086.86 0 0z"
        fill="#007FC8"
      />
      <path
        d="M322.955 296.939a2.156 2.156 0 01-1.862-2.094c-.086-2.667 3.982-1.95 3.61.086 0 1.004-.631 2.151-1.748 2.008z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M302.702 210.151c-.172.946-1.547 1.29-2.406.975-.917-.344-1.662-1.52-1.117-2.467.257-.459.744-.889 1.231-1.118.516-.23 2.722.459 2.292 2.61zM334.414 289.08c-.974.115-1.919-.86-2.12-1.75-.201-.917.773-2.38 1.805-2.036.859.287 1.804.918 1.948 1.893a1.638 1.638 0 01-1.633 1.893c-.974.115 1.203 0 0 0z"
        fill="#fff"
      />
      <path
        d="M346.131 264.3c0 2.724-4.068 1.835-3.61-.516.458-2.381 3.61-1.75 3.61.516z"
        fill="#3BB2F4"
      />
      <path
        d="M221.604 293.325c-1.06.229-2.034-.373-2.292-1.463-.201-.832.63-2.409 1.633-2.266 2.349.402 2.922 3.213.659 3.729z"
        fill="#91E7F2"
      />
      <path
        d="M272.022 308.841c.229 2.495-3.724 2.295-3.782-.086 0-.516.373-1.233.831-1.463 1.833-.917 2.893.574 2.951 1.549.114 1.233-.086-1.204 0 0zM301.843 237.742c-.86 1.032-1.92 1.003-2.836 0-.573-.631-.717-1.492-.23-2.209.745-1.147 2.12-1.061 2.836-.229.402.516 1.06 1.434.23 2.438z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M232.317 311.021c.774.373 1.547 1.548 1.203 2.409-.458 1.118-1.575 1.377-2.578.946-1.174-.487-1.461-1.577-1.003-2.638.459-1.033 1.318-1.234 2.378-.717z"
        fill="#fff"
      />
      <path
        d="M319.919 268.086c1.146-.144 1.976.946 2.205 1.979.23 1.09-.945 1.778-1.89 1.692-2.063-.23-2.435-3.413-.315-3.671.888-.115-.172.028 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M214.356 361.958c0-2.553 3.924-2.036 3.609.43-.114.889-.974 1.692-1.862 1.721-.773-.029-1.833-1.434-1.747-2.151 0-1.262-.058.573 0 0zM333.956 275.83c-1.06-.029-2.063-.889-2.034-2.008.028-.774.974-1.95 1.833-1.721.659.172 1.948.975 1.92 1.778-.058.976-.602 2.008-1.719 1.951zM335.646 263.468c-.229 1.033-.688 2.352-2.034 2.037-2.091-.488-2.349-3.299.172-3.586.859-.114 2.034.775 1.862 1.549z"
        fill="#91E7F2"
      />
      <path
        d="M223.437 300.81c.487.689.63 1.779-.258 2.266-1.089.603-1.891.373-2.635-.545-.803-1.003-.287-2.323.458-2.667.716-.344 1.661-.143 2.435.946z"
        fill="#007FC8"
      />
      <path
        d="M228.507 308.239c0 1.004-.286 1.463-1.26 1.692-.917.23-1.519.058-2.091-.688-.717-.947-.373-1.864.544-2.553 1.002-.774 2.75.344 2.807 1.549-.028 1.09-.028-.516 0 0zM334.672 255.007c-.057 1.033-.802 1.693-1.833 1.75-.974.057-1.719-1.348-1.748-2.237 0-1.75 3.724-2.008 3.581.487z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M245.982 315.696c-1.032-.316-2.206-.688-1.805-2.036.286-.947 1.031-1.52 1.977-1.693.945-.172 1.461 1.062 1.518 1.779.114 1.118-.573 1.893-1.69 1.95-.344-.115.573-.029 0 0z"
        fill="#fff"
      />
      <path
        d="M206.621 344.721c.086 2.524-3.925 2.581-3.638.057.057-.459.315-1.721 1.518-1.893 1.805-.258 2.12.861 2.12 1.836-.029 1.29-.172-.287 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M218.968 329.204c-2.292.115-2.636-3.069-.544-3.7 1.002-.286 1.948.89 2.206 1.75.401 1.176-.774 1.635-1.662 1.95-.888.058.344-.114 0 0zM263.657 281.595c-2.263.487-3.037-3.672-.545-3.672 1.605 0 2.865 3.184.545 3.672z"
        fill="#fff"
      />
      <path
        d="M211.434 295.447c-.946-.889-1.003-1.893-.144-2.868.774-.889 1.891-.315 2.55.43.745.832.43 2.209-.2 2.696-.602.488-1.662.201-2.206-.258z"
        fill="#3BB2F4"
      />
      <path
        d="M292.332 289.797c0 .861-.573 1.979-1.576 1.865-1.231-.144-1.976-.631-2.034-1.865-.086-2.237 3.61-2.179 3.61 0z"
        fill="#91E7F2"
      />
      <path
        d="M198.772 310.275c-2.492.201-2.635-3.786-.029-3.528 1.06.115 1.776 1.291 1.519 2.295-.23.918-.545 1.233-1.49 1.233-.831.057 1.06 0 0 0zM329.744 199.338c-.114 2.094-2.549 2.438-3.265.459-.43-1.147-.115-2.122 1.146-2.266 1.145-.114 2.177.517 2.119 1.807zM352.691 206.795c-.029.803-.86 1.75-1.691 1.778-1.002.058-1.718-.889-1.919-1.806-.2-.804 1.289-2.152 2.034-2.008.831.201 1.604 1.204 1.576 2.036z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M309.52 203.841c2.292-.029 2.263 3.499.143 3.642-.745.058-1.919-.688-2.005-1.491-.115-1.262.859-1.864 1.862-2.151.974-.029-.115.028 0 0z"
        fill="#fff"
      />
      <path
        d="M293.707 238.487c.716.172 1.719.803 1.604 1.635-.086.717-.773 2.065-1.661 1.979-2.55-.229-2.493-3.556.057-3.614.487.115-1.175.029 0 0zM303.733 275.572c.688-.172 1.347.086 1.977.344.573.229.831 1.692.573 2.151-.487.832-1.604 1.118-2.435.803-2.32-.947-.573-3.155-.115-3.298zM339.484 256.355c-.085-2.294 3.266-1.749 3.524.23.315 2.495-3.724 2.18-3.524-.23-.028-.602-.057.574 0 0z"
        fill="#007FC8"
      />
      <path
        d="M244.836 289.539c-.63-.574-.945-1.893-.315-2.581.458-.488 2.206-.889 2.607-.172.171.286 1.174 1.72.229 2.638-.917.918-1.69.861-2.521.115zM317.255 298.287c0 1.061-1.032 1.979-2.006 1.749-1.002-.229-1.69-.975-1.489-2.065.458-2.151 3.495-1.491 3.495.316z"
        fill="#3BB2F4"
      />
      <path
        d="M237.617 302.216c.172 2.266-4.125 1.807-3.409-.66.315-1.061.888-1.577 2.034-1.29.945.2 1.318 1.032 1.375 1.95zM283.251 253.086c1.175.373 1.891.803 1.776 2.094-.086.889-1.117 1.634-1.977 1.405-1.002-.287-1.432-.947-1.432-1.979-.258-.688.86-1.778 1.633-1.52z"
        fill="#91E7F2"
      />
      <path
        d="M330.976 234.874c1.92.573 1.92 3.785-.401 3.47-.916-.115-1.776-.861-1.776-1.836.029-1.319 1.175-1.462 2.177-1.634.115.028-.401.057 0 0z"
        fill="#007FC8"
      />
      <path
        d="M361.771 254.004c.803-.517 1.748 1.175 1.719 1.806-.028.976-.831 1.75-1.776 1.865-.745.086-1.633-1.262-1.661-1.922-.029-1.061.63-1.864 1.718-1.749.43-.287-.744-.086 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M227.562 301.011c-.802.115-1.49-.659-1.776-1.348-.287-.774.172-1.835.945-2.151 1.089-.43 2.292-.086 2.636 1.635.2 1.004-.888 1.75-1.805 1.864z"
        fill="#007FC8"
      />
      <path
        d="M251.253 366.575c-2.206-.086-2.865-2.724-.688-3.527 2.063-.746 3.123 3.642.688 3.527zM296.113 321.46c.631.832 1.375 1.521.917 2.61-.401.947-1.346 1.09-2.206.717-1.031-.459-1.203-1.405-.802-2.38.43-.975 1.117-.918 2.091-.947.315.402-.143 0 0 0zM308.288 320.428c0 .889-.573 1.463-1.432 1.635-.831.143-1.69-.746-1.948-1.463-.344-.946.544-1.864 1.432-2.093 1.06-.287 1.948.975 1.948 1.921z"
        fill="#3BB2F4"
      />
      <path
        d="M324.961 275.113c.916-.144 1.604.86 1.776 1.663.2.918-.716 1.864-1.576 2.036-1.661.287-2.607-3.871-.2-3.699.515-.086-1.003-.086 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M272.795 265.103c-.859.545-1.805-.316-2.034-1.348-.229-1.147.43-2.352 1.547-2.295 1.146.029 3.123 2.037.487 3.643z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M357.417 221.881c-.028 2.065-2.95 1.664-3.351-.028-.23-.947.601-1.865 1.518-2.008 1.117-.201 1.862 1.032 1.833 2.036 0 .861.029-.717 0 0z"
        fill="#fff"
      />
      <path
        d="M294.309 307.522c0-.287 0-.603.229-.803.258-.201.401-.172.63-.431.172-.172.401-.286.63-.258.802.058 1.977.861 1.948 1.721-.057 2.524-3.466 2.18-3.437-.229-.029-.402 0 1.577 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M240.625 323.468c.888 0 1.747.545 1.976 1.406.201.774-.315 2.065-1.203 2.151-1.003.114-2.177-.717-2.177-1.779 0-1.118 1.031-1.663 1.404-1.778 1.088 0-.086.029 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M326.565 305.744c-.917.114-2.091-.832-2.091-1.75 0-.946.286-1.463 1.718-1.664 1.633-.172 2.607 3.127.373 3.414zM356.386 244.883c.143.803-.716 1.807-1.518 1.75-.974-.087-1.605-.947-1.834-1.865-.515-1.95 3.323-2.294 3.352.115.143.774 0-.688 0 0z"
        fill="#fff"
      />
      <path
        d="M292.991 277.608c.773 0 1.948.659 1.948 1.548 0 .89-.802 1.922-1.748 1.951-1.804 0-2.435-3.499-.2-3.499zM256.123 297.053c-.688.66-1.748.345-2.321-.258-.773-.831-.687-1.749.172-2.495.688-.602 1.833-.43 2.292-.115.458.316.573 2.151-.143 2.868z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M245.581 332.273c-.029 2.209-3.18 2.323-3.438.23-.258-2.238 3.466-2.352 3.438-.23z"
        fill="#fff"
      />
      <path
        d="M229.624 363.621c.029-.86.315-1.606 1.232-1.72.774-.115 1.719.258 2.12.975.401.688-.716 2.237-1.461 2.294-1.031.058-1.919-.344-1.891-1.549z"
        fill="#3BB2F4"
      />
      <path
        d="M290.04 311.164c.688-1.147 1.604-.631 2.349.057.659.603.802 1.32.315 2.094-.573.918-1.776 1.061-2.492.516-.745-.545-.745-1.72-.172-2.667z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M319.059 233.009c-.057 1.004-.945 1.979-1.919 1.692-.86-.258-1.776-1.462-1.375-2.323.859-1.95 3.409-.917 3.294.631z"
        fill="#fff"
      />
      <path
        d="M228.536 348.535c.115.832-.573 1.75-1.432 1.778-.774.058-1.805-.745-1.92-1.491-.229-1.434 1.203-1.922 1.748-1.922.544 0 1.489.775 1.604 1.635zM276.519 267.971c.43.172.802.344.974.746.401.918.315 2.007-.687 2.409-.631.258-1.662.057-1.977-.574-1.26-2.323.945-2.868 1.69-2.581z"
        fill="#91E7F2"
      />
      <path
        d="M254.719 317.072c.028.918-.315 1.836-1.432 1.779-.917-.058-1.805-.717-1.777-1.693.029-2.151 3.209-2.093 3.209-.086 0 .66 0-.831 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M282.048 304.826c-2.034.373-2.177-3.729.057-3.327.63.115 1.719.774 1.719 1.52 0 .975-.831 1.807-1.776 1.807-.745.143.945 0 0 0z"
        fill="#fff"
      />
      <path
        d="M288.121 336.719c-.115 2.294-2.807 1.95-3.409.057-.258-.86.802-1.749 1.576-1.807 1.06-.057 1.575.861 1.833 1.75-.057.889-.029-.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M267.152 286.585c-.172.803-.974 1.778-1.92 1.405-.888-.372-1.547-1.147-1.289-2.151.573-2.151 3.209-1.09 3.209.746-.144.545 0-1.233 0 0zM338.453 224.491c-.029-.86.802-1.29 1.576-1.319.974-.029 1.661 1.147 1.719 2.008.057.975-1.118 1.548-1.948 1.319-.889-.23-1.318-1.147-1.347-2.008z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M257.841 379.424c-.028-2.093 3.151-1.72 3.381.144.286 2.151-3.323 2.151-3.381-.144z"
        fill="#fff"
      />
      <path
        d="M267.581 265.189c.029.631-.515 1.578-1.146 1.692-.888.144-2.12-.545-2.32-1.462-.258-1.062.831-1.836 1.747-1.807.917 0 1.691.573 1.719 1.577z"
        fill="#3BB2F4"
      />
      <path
        d="M336.305 202.579c.286.832-.43 1.893-1.289 1.979-.974.086-1.719-.201-1.948-1.204-.258-1.148.257-1.836 1.117-2.065.859-.23 1.833.516 2.12 1.29z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M289.897 227.474c.745.516.974 1.893.229 2.524-.802.688-2.234 1.061-2.692.172-1.461-2.61 1.375-3.442 2.463-2.696z"
        fill="#fff"
      />
      <path
        d="M283.566 293.726c-.114-.717.229-1.663.888-1.979.774-.372 1.92.373 2.177 1.119.373 1.032-.401 2.151-1.06 2.323-.658.143-1.833-.43-2.005-1.463z"
        fill="#007FC8"
      />
      <path
        d="M332.924 216.374c2.063-.086 1.777 3.471-.429 3.27-.917-.086-1.576-.574-1.461-1.922.143-1.405 1.346-1.319 1.89-1.348.745-.028-.888.029 0 0zM274.084 314.606c.888.172 1.69 1.147 1.461 2.065-.229.889-1.432 1.491-2.263 1.233-.659-.201-1.089-1.118-1.06-1.778.029-.66.716-1.75 1.862-1.52z"
        fill="#3BB2F4"
      />
      <path
        d="M256.266 353.612c.029-.803.372-1.549 1.232-1.721.83-.172 1.489.602 1.862 1.291.458.917-.487 2.007-1.404 2.007-1.203.029-1.432-.545-1.69-1.577.086-1.205.029.086 0 0z"
        fill="#007FC8"
      />
      <path
        d="M295.426 332.761c-.716.516-1.834-.029-2.206-.832-.544-1.147.315-2.151 1.146-2.294.802-.172 3.208 1.634 1.06 3.126zM209.056 358.315c-.143.775-1.146 1.348-1.89 1.205-.831-.143-1.834-1.033-1.547-1.836 1.002-2.839 3.81-1.29 3.437.631z"
        fill="#91E7F2"
      />
      <path
        d="M299.751 228.478c-.888-.144-1.69-1.148-1.432-2.037.201-.774 1.06-1.405 1.862-1.262.917.144 1.518 1.205 1.404 2.037-.143.803-.917 1.405-1.834 1.262z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M242.802 373.43c.029 2.036-3.123 2.381-3.151.229 0-.889.659-1.979 1.604-1.835 1.06.143 1.203.631 1.547 1.606 0 .946-.057-.172 0 0zM355.354 269.835c0 .976-.716 1.492-1.661 1.377-.401-.057-.774-.229-1.003-.574-.2-.286-.659-.831-.687-1.176-.057-.86 1.088-1.663 1.89-1.577.545.086 1.633 1.405 1.461 1.95 0 .803.144-.487 0 0zM296.371 338.497c.659.143 1.748.832 1.719 1.606-.029.889-.888 1.635-1.748 1.635-1.031 0-1.718-.889-1.432-1.836.287-.975.401-1.434 1.461-1.405.029 0-.888-.029 0 0z"
        fill="#fff"
      />
      <path
        d="M257.068 278.382c.716.602.773 1.52.372 2.008-.487.602-1.375.918-2.12.545-.773-.402-.945-1.52-.773-2.123.201-.602 1.547-1.204 2.521-.43z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M366.957 265.247c-.888 0-1.519-.517-1.633-1.406-.086-.717 1.031-1.606 1.69-1.663.945-.086 1.49.602 1.719 1.434.286 1.032-.946 1.577-1.776 1.635-.946 0 .171-.029 0 0z"
        fill="#fff"
      />
      <path
        d="M382.97 224.003c-1.805-.086-2.406-2.409-.515-3.04 2.263-.745 2.75 3.098.515 3.04-1.174-.057.774.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M363.29 310.935c.802 0 1.518 1.176 1.318 1.921-.229.89-1.203 1.377-2.063 1.234-2.005-.316-1.031-3.155.745-3.155zM315.393 227.474c-.029 2.237-3.639 1.721-3.18-.172.172-.66.945-1.549 1.661-1.549.745-.029 1.519 1.061 1.519 1.721z"
        fill="#91E7F2"
      />
      <path
        d="M362.402 240.208c1.69-.057 2.607 2.61.716 3.04-2.034.488-3.065-2.982-.716-3.04z"
        fill="#007FC8"
      />
      <path
        d="M220.429 334.367c.029.803-.831 1.52-1.576 1.434-.945-.115-1.489-1.004-1.518-1.893 0-.688.917-1.348 1.547-1.405.888-.087 1.547 1.089 1.547 1.864.029.631 0-.947 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M321.265 319.969c.029-2.094 3.294-1.405 3.266.402-.058 2.093-3.295 1.462-3.266-.402z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M334.443 320.571c.515-2.409 3.294-1.405 3.265.402-.057 2.122-3.523 2.065-3.265-.402z"
        fill="#fff"
      />
      <path
        d="M237.244 365.457c-.028-2.008 2.521-2.036 2.951-.229.487 2.093-2.922 2.638-2.951.229z"
        fill="#3BB2F4"
      />
      <path
        d="M219.884 356.537c.602-.918 1.662-.688 2.435-.229.774.459.974 1.463.201 2.294-.544.574-1.518.631-2.149.201-.859-.602-.773-1.348-.487-2.266.545-.229-.257.402 0 0zM285.142 318.306c.258-.889.458-1.52 1.518-1.635.659-.086 1.518.717 1.69 1.291.63 1.921-3.237 2.495-3.208.344.086-.258 0 .86 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M285.6 348.621c-1.862-.114-2.349-2.983-.229-3.069 1.919-.086 2.406 3.213.229 3.069z"
        fill="#fff"
      />
      <path
        d="M215.244 376.528c.2 1.807-3.438 2.294-3.008-.086.315-1.807 3.094-1.951 3.008.086.086.631.028-.488 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M329.458 268.172c-.115.832-.344 1.52-1.289 1.606-.831.086-1.92-.631-1.891-1.549.029-.688.974-1.721 1.69-1.549.516.144 1.49.89 1.49 1.492-.143 1.118 0-.488 0 0z"
        fill="#007FC8"
      />
      <path
        d="M337.966 306.174c.086-.66.201-1.549 1.032-1.692.802-.115 1.89.86 1.976 1.606.086.86-.802 1.635-1.575 1.721-.774.114-1.347-.976-1.433-1.635.086-.66.058.573 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M207.051 370.591c-.373.516-1.461.946-2.034.487-.487-.372-.802-1.262-.773-1.864.057-1.176 1.632-1.233 2.291-1.004.688.23 1.146 1.52.516 2.381z"
        fill="#007FC8"
      />
      <path
        d="M285.571 284.549c-.172-.66.688-1.807 1.433-1.692.515.086 1.375.631 1.604 1.118.43.889-.373 2.209-1.117 2.123-1.404-.144-1.605-.316-1.92-1.549zM325.161 218.841c-.057-1.836 3.208-1.52 3.266.057.086 2.151-3.065 1.807-3.266-.057-.029-.66.086.746 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M204.559 319.883c.601.631.916 1.434.487 2.237-.373.689-1.232 1.061-1.92.545-.487-.344-1.088-1.262-.859-1.893.315-.917 1.461-1.147 2.292-.889.945.975-.459-.143 0 0z"
        fill="#007FC8"
      />
      <path
        d="M311.955 242.933c.315.86-.143 1.749-1.06 1.835-.63.086-1.461-.143-1.747-.745-.401-.861.2-2.123.974-2.295.745-.143 1.518.373 1.833 1.205z"
        fill="#91E7F2"
      />
      <path
        d="M331.979 345.896c.802.259 1.661.89 1.232 1.865-.373.803-1.089 1.204-1.948 1.032-.716-.143-1.06-.602-1.117-1.319-.086-1.09.859-1.491 1.833-1.578.229.087-1.117.087 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M242.83 291.747c.774-.114 1.376.689 1.633 1.32.43 1.09-.429 1.979-1.432 1.807-2.635-.402-1.06-3.012-.201-3.127z"
        fill="#007FC8"
      />
      <path
        d="M272.337 325.361c-.831.201-1.347-.659-1.49-1.348-.114-.659.63-1.864 1.347-1.921.658-.029 1.575 1.147 1.632 1.72.058.832-.687 1.492-1.489 1.549-.544.115.573-.028 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M267.237 298.086c1.691-.115 2.263 2.839.258 3.097-1.976.201-2.32-3.241-.258-3.097.487-.029-.601-.058 0 0z"
        fill="#fff"
      />
      <path
        d="M327.94 232.264c0 .774-.802 1.577-1.604 1.462-.802-.114-1.318-.975-1.375-1.72-.201-2.151 3.036-1.521 2.979.258 0 .659.029-.488 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M218.424 343.344c-.115 1.979-2.808 1.893-3.008.057-.229-2.122 3.122-2.093 3.008-.057z"
        fill="#91E7F2"
      />
      <path
        d="M233.778 355.849c-.43.488-1.432.631-1.977.23-.601-.431-.945-1.664-.487-2.266.545-.746 1.748-.488 2.321-.172.544.344.745 1.491.143 2.208z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M218.252 351.805c-1.748-.029-1.776-3.098-.115-3.04.716.028 1.604.688 1.776 1.376.201.861-.917 1.692-1.661 1.664z"
        fill="#fff"
      />
      <path
        d="M187.944 346.298c-1.748-.029-1.776-3.097-.115-3.04.716.029 1.604.688 1.776 1.377.201.86-.917 1.663-1.661 1.663z"
        fill="#007FC8"
      />
      <path
        d="M319.976 262.006c.086.631-.344 1.634-1.003 1.806-.916.23-1.69-.258-1.976-1.147-.344-1.09.544-1.864 1.203-1.921.687-.115 1.719.659 1.776 1.262z"
        fill="#3BB2F4"
      />
      <path
        d="M233.234 325.763c.745.459 1.06 1.262.659 2.065-.43.889-1.232.86-2.034.487-1.003-.458-.831-2.007-.43-2.466.373-.402 1.175-.459 1.805-.086z"
        fill="#91E7F2"
      />
      <path
        d="M319.002 241.642c-.143.717-.659 1.491-1.375 1.549-.745.057-1.49-.889-1.404-1.635.115-.832.745-1.176 1.49-1.233.057 0 1.432.573 1.289 1.319zM223.15 324.271c-.2-.745.917-1.634 1.604-1.72.86-.115 1.347.401 1.461 1.233.115.918-.315 1.749-1.146 1.893-.859.172-1.69-.603-1.919-1.406z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M286.746 275.801c.229-.746.945-1.664 1.919-1.291.831.344 1.175 1.291.888 2.094-.601 1.692-2.836.688-2.807-.803.057-.258-.029 1.204 0 0z"
        fill="#fff"
      />
      <path
        d="M304.536 332.818c-1.948-.029-1.805-3.413-.258-3.011.773.201 1.489.688 1.604 1.491.115.918-.688 1.262-1.346 1.52-.717 0 .114-.029 0 0zM226.215 374.348c-.028-.746.43-1.635 1.175-1.864.802-.23 1.375 1.261 1.461 1.806.115.804-.602 1.377-1.346 1.406-.974.028-1.032-.574-1.29-1.348-.028-.889.058.201 0 0zM299.752 214.855c-.086-2.037 3.179-1.664 2.921.057-.114.717-.429 1.577-1.317 1.405-.745-.143-1.604-.659-1.604-1.462-.029-.545 0 .43 0 0z"
        fill="#007FC8"
      />
      <path
        d="M327.854 208.487c-.057.803-.831 1.176-1.576 1.148-.773-.029-1.231-.689-1.432-1.377-.258-.918.831-1.664 1.633-1.578.802.086 1.461.889 1.375 1.807z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M344.154 275.801c0 2.122-3.008 1.606-3.008-.229 0-.631 1.003-1.492 1.604-1.148.573.287 1.519.603 1.404 1.377 0 .516.057-.43 0 0z"
        fill="#fff"
      />
      <path
        d="M237.073 394.396c-.717.086-1.404-.43-1.547-1.119-.144-.631-.029-1.663 1.145-1.721 1.032-.057 1.605.316 1.662 1.463.057 1.147-.458 1.291-1.26 1.377z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M291.902 213.506c.774.029 1.805.574 1.776 1.492-.057 1.893-2.922 1.577-2.893.057 0-.86.315-1.577 1.117-1.549z"
        fill="#fff"
      />
      <path
        d="M244.148 280.992c1.834 0 1.146 3.585-.458 2.868-.745-.315-1.289-.688-1.175-1.577.115-.861.802-1.262 1.633-1.291.63 0-1.26.058 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M362.803 289.109c1.919-.23 1.948 2.179.315 2.61-.688.172-1.49-.488-1.719-1.09-.401-1.004.63-1.463 1.404-1.52.716-.086-.115.028 0 0z"
        fill="#fff"
      />
      <path
        d="M309.491 282.34c.029 1.893-2.664 2.123-2.635.058 0-.574.286-1.463 1.002-1.463.745.028 1.633.573 1.633 1.405z"
        fill="#91E7F2"
      />
      <path
        d="M367.243 228.65c.773.258 1.404.889 1.232 1.749-.143.717-.659 1.176-1.404 1.147-.917-.057-1.26-1.032-1.289-1.835.086-.201.516-1.348 1.461-1.061zM271.935 358.487c1.719.172 2.349 2.553.373 2.897-.716.115-1.375-.545-1.518-1.205-.23-.975.372-1.376 1.145-1.692 1.06.115-.257.115 0 0zM251.052 308.325c.172-1.664 2.893-.975 2.693.373-.201 1.348-2.893 1.434-2.693-.373z"
        fill="#007FC8"
      />
      <path
        d="M319.317 330.725c.573-2.639 3.208-.259 2.549.717-.974 1.405-2.52 1.032-2.549-.717.029-.087.029 1.175 0 0zM340.917 216.747c.773.144 1.833.172 1.747 1.262-.086.889-.687 1.406-1.575 1.262-.888-.115-1.175-.344-1.175-1.262 0-.86.201-1.09 1.003-1.262.458.086-.201.058 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M211.233 391.499c.057 1.577-2.377 1.922-2.836.43-.601-2.065 2.492-2.61 2.836-.43.115.774-.028-.115 0 0z"
        fill="#fff"
      />
      <path
        d="M323.041 228.105c0 1.75-2.807 1.52-2.664.057.029-.43 0-1.52 1.031-1.634.745-.086 1.748.688 1.633 1.577 0 .889.086-.602 0 0zM348.136 297.483c.659.517 1.317 1.004 1.203 1.951-.115.946-.946.917-1.69.803-.717-.115-1.06-.918-.86-1.606.201-.775.602-.976 1.347-1.148.229.201-.917.23 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M306.655 231.403c-.487.574-1.375.545-1.862-.029-.429-.516-.916-1.032-.401-1.692.602-.774 1.719-.774 2.177-.258.459.459.573 1.405.086 1.979zM305.595 257.617c-.487.574-1.375.545-1.862-.028-.429-.517-.916-1.033-.401-1.693.602-.774 1.719-.774 2.177-.258.459.459.573 1.406.086 1.979z"
        fill="#fff"
      />
      <path
        d="M317.054 210.38c.029 1.635-2.664 2.123-2.664.459-.029-1.721 2.607-2.38 2.664-.459z"
        fill="#91E7F2"
      />
      <path
        d="M210.718 317.531c.487-.143.974-.487 1.432-.143.372.258.63.401.659.889.029.545-.287 1.09-.745 1.291-1.747.688-2.263-1.75-1.346-2.037z"
        fill="#007FC8"
      />
      <path
        d="M189.662 297.828c.487-.144.974-.488 1.433-.144.372.258.63.402.659.889.028.545-.287 1.09-.745 1.291-1.748.66-2.235-1.778-1.347-2.036z"
        fill="#3BB2F4"
      />
      <path
        d="M374.892 204.071c.143-.66.401-1.148 1.174-.976.602.144 1.518.488 1.404 1.234-.344 1.979-2.578 1.634-2.578-.258 0-.115 0 1.003 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M329.601 225.036c-2.148-.774-.802-2.839.144-2.581.63.172 1.489.631 1.26 1.405-.2.66-.716 1.09-1.404 1.176-.286-.115.717-.086 0 0zM290.241 355.361c1.69-.086 1.919 2.61.315 2.754-1.633.114-1.919-2.696-.315-2.754z"
        fill="#fff"
      />
      <path
        d="M310.466 343.172c-1.834.057-1.318-2.868.143-2.467 1.719.459 1.69 2.266-.143 2.467-.974.029.2-.029 0 0zM354.323 279.415c.717.229 1.576.373 1.748 1.204.143.689-.258 1.234-.917 1.377-.659.172-1.318-.057-1.575-.717-.316-.803.257-1.319.744-1.864.373.115-.171.201 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M333.468 296.881c-.716 0-1.145-.2-1.145-.975 0-.602.286-1.52 1.031-1.463.63.058 1.661.345 1.604 1.148-.086.831-.659 1.348-1.49 1.29-.83 0 .029 0 0 0zM370.967 216.46c0 1.807-2.406 1.463-2.435-.229 0-.66.544-1.033 1.146-1.09.888-.115 1.26.459 1.289 1.319z"
        fill="#fff"
      />
      <path
        d="M347.993 198.306c0 1.807-2.407 1.462-2.435-.23 0-.659.544-1.032 1.145-1.09.917-.086 1.29.488 1.29 1.32z"
        fill="#3BB2F4"
      />
      <path
        d="M313.846 273.793c-.172.631-1.118.746-1.605.545-.658-.287-.974-.946-.601-1.807.372-.889 2.664-.516 2.206 1.262z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M303.39 270.237c.086 1.692-2.636 1.893-2.263.086.114-.488.315-1.233.916-1.262.888 0 1.318.287 1.347 1.176z"
        fill="#fff"
      />
      <path
        d="M273.884 277.321c-.917.057-1.261-.344-1.375-1.032-.258-1.692 2.263-2.18 2.177-.086 0 .401-.258 1.061-.802 1.118z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M249.219 296.279c-.917.057-1.261-.344-1.375-1.033-.258-1.692 2.263-2.179 2.177-.086 0 .373-.258 1.062-.802 1.119z"
        fill="#fff"
      />
      <path
        d="M225.986 330.466c-.916.058-1.26-.344-1.375-1.032-.258-1.692 2.263-2.18 2.177-.086.029.401-.229 1.09-.802 1.118z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M214.184 331.728c-.917.058-1.261-.344-1.375-1.032-.258-1.692 2.263-2.18 2.177-.086 0 .401-.258 1.09-.802 1.118z"
        fill="#fff"
      />
      <path
        d="M348.336 224.434c-.916.057-1.26-.344-1.375-1.033-.257-1.692 2.263-2.18 2.178-.086 0 .402-.258 1.09-.803 1.119zM392.366 211.671c-.917.057-1.26-.344-1.375-1.033-.258-1.692 2.263-2.179 2.177-.086 0 .373-.258 1.061-.802 1.119z"
        fill="#3BB2F4"
      />
      <path
        d="M257.87 301.556c-.917.057-1.26-.344-1.375-1.032-.258-1.693 2.263-2.18 2.177-.087 0 .402-.258 1.09-.802 1.119z"
        fill="#91E7F2"
      />
      <path
        d="M233.606 287.646c-.916.057-1.26-.344-1.375-1.033-.258-1.692 2.263-2.179 2.177-.086 0 .402-.258 1.09-.802 1.119z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M260.019 287.216c-.917.057-1.261-.344-1.375-1.033-.258-1.692 2.349-2.237 2.234-.172.029.402-.286 1.148-.859 1.205z"
        fill="#fff"
      />
      <path
        d="M230.684 305.313c-.916.058-1.26-.344-1.375-1.032-.258-1.692 2.263-2.18 2.177-.086 0 .401-.257 1.09-.802 1.118z"
        fill="#3BB2F4"
      />
      <path
        d="M223.551 297.082c-.916.057-1.26-.344-1.375-1.033-.258-1.692 2.263-2.179 2.177-.086 0 .373-.257 1.062-.802 1.119zM238.419 312.856c-.917.058-1.26-.344-1.375-1.032-.258-1.692 2.263-2.18 2.177-.086 0 .373-.258 1.061-.802 1.118z"
        fill="#007FC8"
      />
      <path
        d="M363.949 274.797c-1.347.086-1.49-2.897.114-2.524 1.547.373 1.576 2.467-.114 2.524-.487.029.601-.029 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M331.034 311.336c-.831-.086-1.146-.459-1.232-1.233-.086-.602.315-1.348 1.003-1.291.745.058 1.461.89 1.489 1.434 0 .545-.573 1.148-1.26 1.09zM372.714 240.982c-1.317.058-2.033-1.491-.773-2.265 1.203-.775 2.693 2.122.773 2.265-.859.029.602-.057 0 0z"
        fill="#007FC8"
      />
      <path
        d="M279.212 376.384c-.43-1.807 2.664-2.008 2.578-.143-.086 1.835-2.234 1.634-2.578.143z"
        fill="#91E7F2"
      />
      <path
        d="M242.143 297.914c1.318 0 1.146 2.008 0 2.18-1.604.258-1.776-2.209 0-2.18z"
        fill="#3BB2F4"
      />
      <path
        d="M217.822 295.218c1.318 0 1.146 2.007 0 2.179-1.604.259-1.776-2.208 0-2.179z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M232.776 319.74c1.317 0 1.145 2.007 0 2.179-1.605.287-1.777-2.179 0-2.179zM217.364 367.694c1.031.115 1.547 2.352.028 2.294-1.489-.057-1.69-2.323-.028-2.294 0 0-.602 0 0 0z"
        fill="#fff"
      />
      <path
        d="M327.883 287.904c-.344.746-.459 1.061-1.318.832-.573-.144-.774-.545-.745-1.119.029-.516.229-1.204.859-1.118.316.028.631.201.86.459.229.229.114.831.344.946-.144.287-.058-.029 0 0zM346.532 288.478c-.086-1.377 1.862-1.09 1.718.029-.086.631-.458.803-1.06.774-.572 0-.63-.258-.658-.803z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M206.621 56.135c-.029-1.032.287-2.065.831-2.925.372-.574 2.005-.918 2.693-1.004 1.06-.143 1.575.115 2.32.803.544.516 1.003 1.348 1.089 2.209.286 2.61-1.862 4.273-3.352 4.33-2.12.029-3.581-1.462-3.581-3.413-.029-1.061 0 1.033 0 0z"
        fill="#fff"
      />
      <path
        d="M285.972 159.96c.43-.631 4.498-3.586 6.331.229 1.261 2.61-1.461 4.618-2.435 4.818-2.75.603-3.695-1.032-4.153-2.237-.201-.573-.23-2.093.257-2.81zM275.889 76.212c-.086-1.75 1.26-3.758 3.179-3.729 1.662.029 2.894.803 3.352 2.438.602 2.065-.687 4.245-2.635 4.618-2.607.516-3.868-2.237-3.896-3.327z"
        fill="#91E7F2"
      />
      <path
        d="M294.48 101.795c.144-.631.688-1.893 1.261-2.266.63-.43 1.69-1.061 2.464-.975 1.002.115 1.948.66 2.578 1.434 1.403 1.692.258 3.814-1.433 4.704-2.778 1.462-5.213-1.521-4.87-2.897z"
        fill="#3BB2F4"
      />
      <path
        d="M338.31 163.745c.63.545.945 1.406.945 2.237 0 .832-.057 3.241-3.466 3.442-.831.057-2.234-.401-2.893-.946-.602-.488-.573-1.807-.544-2.524.028-.889.773-1.664 1.346-2.323.258-.316 2.865-1.377 4.612.114z"
        fill="#91E7F2"
      />
      <path
        d="M247.615 78.334c-.573-.602-.745-1.52-.774-2.323a3.512 3.512 0 01.917-2.495c.716-.746 1.346-.89 2.32-1.061.831-.115 1.662-.087 2.263.573.516.574 1.117 1.233 1.146 2.065.172 5.478-5.185 3.958-5.872 3.241z"
        fill="#007FC8"
      />
      <path
        d="M339.169 112.177c1.49-.459 4.125 1.09 4.011 2.811-.029.459 0 1.577-.688 2.524-.515.717-1.69 1.262-2.549 1.377-.831.114-1.69-.172-2.321-.746-.745-.66-.83-1.233-1.002-2.151.028-1.721.974-3.298 2.549-3.815zM330.031 193.688c-.859.086-1.604-.344-2.177-.946-.716-.717-.63-1.406-.458-2.352.286-1.52 1.776-3.126 3.351-2.897 1.776.258 3.266 1.119 3.151 3.155-.086 1.721-2.291 3.011-3.867 3.04-1.003.115.974-.029 0 0zM272.251 115.304c-.602-.66-.745-.804-.745-1.664 0-.889 0-3.184 2.836-3.786.831-.172 1.805.144 2.549.545.602.316 1.175 1.864 1.118 2.553-.43 4.732-5.271 2.868-5.758 2.352z"
        fill="#3BB2F4"
      />
      <path
        d="M314.419 177.971c-.889-.488-.831-1.377-.717-2.266.258-1.864 1.776-3.441 3.753-2.81.802.258 1.576.688 2.091 1.376.545.746.602 1.348.373 2.18-.201.717-.545 1.405-1.203 1.807-.258.229-1.948.975-4.297-.287z"
        fill="#91E7F2"
      />
      <path
        d="M299.35 147.168c-1.661-.402-2.578-2.266-2.148-3.843.458-1.664 1.948-2.782 3.753-2.409 1.546.315 2.263 1.577 2.263 3.097 0 3.184-2.951 3.384-3.868 3.155z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M283.337 140.026c.115-2.122.917-3.7 3.237-3.786 2.091-.086 2.922 2.18 2.464 3.93-.43 1.692-2.321 2.638-3.152 2.524-1.546-.259-2.492-1.893-2.549-2.668.057-.918.057.889 0 0z"
        fill="#fff"
      />
      <path
        d="M269.157 117.34c1.948-.287 3.781.516 3.924 2.581.201 3.098-2.635 3.413-3.237 3.471-1.89.114-2.864-1.463-2.922-2.61-.057-1.205.688-3.213 2.235-3.442z"
        fill="#91E7F2"
      />
      <path
        d="M248.187 114.501c-.544.172-1.174.143-1.661-.172-.888-.574-1.461-2.381-.544-3.184.343-.316 1.489-1.52 2.521-.66 2.549 2.037.229 3.844-.316 4.016z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M265.347 111.432c-.287 1.52-1.891 3.097-3.524 2.954-.658-.058-1.489-.287-1.976-.746-.201-.201-.831-1.549-.802-2.18.143-1.52 2.234-3.47 3.81-3.126.601.115 1.518.803 1.948 1.262.515.488.544 1.176.544 1.836-.115.602.029-1.348 0 0zM242.372 97.177c.029-1.319 1.748-3.126 3.065-3.155 1.547-.028 3.066 1.262 3.123 2.84.057 1.75-1.576 3.04-3.209 3.155-1.518.143-2.979-1.29-2.979-2.84.029-.774 0 .631 0 0zM273.712 131.078c.63.373.716 1.406.83 2.094.23 1.377-1.403 2.38-2.635 2.352-1.576-.058-2.435-1.234-2.435-2.725.029-1.004 2.12-2.954 4.24-1.721z"
        fill="#fff"
      />
      <path
        d="M294.28 128.181c.773.517.917 1.377 1.146 2.237.372 1.406-1.948 2.868-3.151 2.955-1.805.114-2.664-1.09-2.865-2.783-.086-.774.43-1.606 1.003-2.122.429-.373 2.091-1.463 3.867-.287zM200.806 76.9c-1.49-.029-2.55-1.119-2.865-2.581-.143-.775.315-3.155 2.578-3.442 1.605-.2 3.495 1.061 3.323 3.012-.229 2.753-2.349 2.81-3.036 3.011-1.347 0 .63-.172 0 0zM323.385 143.669c.544.373.888 1.29 1.003 1.921.2 1.549-1.719 3.155-3.151 3.012a3.541 3.541 0 01-2.006-.947 3.091 3.091 0 01-.687-.917c-.287-.574-.058-.918-.086-1.52-.029-1.578 2.693-2.983 4.927-1.549z"
        fill="#007FC8"
      />
      <path
        d="M273.282 97.407c.057 2.323-1.919 2.782-2.693 2.983-1.518.43-2.979-1.061-2.979-2.524-.029-1.75.974-2.983 2.693-3.213 1.604-.2 3.237 1.033 2.979 2.754.057.63.115-.717 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M250.966 116.938c.602-1.262 1.089-2.581 2.664-2.782 1.347-.143 2.75.889 3.008 2.237.229 1.291-.945 3.356-2.349 3.557-.716.115-1.89 0-2.463-.43-.803-.631-.745-1.692-.86-2.582.229-.516.057.431 0 0zM217.306 87.197c-.028-1.492 1.261-2.754 2.693-2.983.774-.144 1.461.172 2.091.545.602.344.803 1.118.946 1.778.143.63-.115 1.32-.459 1.864-1.976 3.212-5.299.201-5.271-1.204-.028-1.492-.028.889 0 0z"
        fill="#fff"
      />
      <path
        d="M286.832 94.166c-.029.832.057 1.205-.487 1.807-.544.573-1.146 1.118-1.919 1.376-1.318.402-2.779-1.147-2.894-2.437a2.755 2.755 0 01.487-1.807c.287-.43 1.089-1.205 1.633-1.176.716.028 1.633-.058 2.235.43.487.401.974 1.119.945 1.807-.029.373.057-.832 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M245.695 58.688c-.029 1.835-1.117 2.782-2.779 3.27-1.317.372-2.52-.976-2.549-2.18-.029-1.406.2-2.496 1.719-2.926 1.346-.43 3.609.086 3.609 1.836-.029.918 0-.803 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M334.758 155.915c.229 1.664-1.032 3.127-2.55 3.127-1.919.028-2.893-1.492-2.807-2.323.143-1.549 1.317-2.84 2.95-2.926 1.576-.086 2.235.947 2.407 2.122z"
        fill="#3BB2F4"
      />
      <path
        d="M237.244 52.034c.172 1.32-1.346 3.384-2.922 2.954-1.289-.373-2.435-1.176-2.435-2.696 0-.66.631-1.492 1.032-1.979.544-.631 3.924-1.405 4.325 1.72zM254.318 92.933c1.403-.23 2.721.889 2.893 2.265.172 1.434-.773 3.012-2.378 2.926-.573-.029-2.463-.144-2.807-2.209-.315-2.036 1.06-2.782 2.292-2.982zM278.037 122.015c-.172-3.212 5.157-3.499 5.271-.144.058 1.406-1.203 2.639-2.578 2.639-.602 0-1.576-.143-2.091-.488-.573-.401-.516-1.405-.602-2.007-.057-1.119.029.315 0 0z"
        fill="#007FC8"
      />
      <path
        d="M230.312 101.193c-.716.115-1.432.258-1.919-.316-.602-.688-1.776-3.27 1.174-4.904 1.232-.688 3.381.258 3.151 1.921.029 1.377-.916 3.041-2.406 3.299z"
        fill="#91E7F2"
      />
      <path
        d="M248.588 83.611c1.318-.057 3.123.803 2.98 2.323-.144 1.434-1.289 3.184-2.894 3.127-.974-.03-2.549-1.492-2.32-2.524.286-1.262.659-2.84 2.234-2.926 1.576-.057-.601.029 0 0zM250.135 41.565c.058-1.176.946-2.58 2.149-2.839 1.318-.287 3.037.717 2.893 2.208-.143 1.578-.974 3.356-2.807 3.155-1.432-.143-2.12-1.147-2.235-2.524.058-.917.172 1.98 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M233.721 37.894c.286 2.151-1.031 3.04-2.034 3.327-1.461.43-3.065-1.004-2.836-2.495.172-1.262 1.06-2.38 2.349-2.639 1.289-.258 2.492.43 2.521 1.807.229 1.033 0-.86 0 0z"
        fill="#fff"
      />
      <path
        d="M237.559 106.355c.43 1.606-1.031 2.954-2.549 3.011-1.604.058-2.893-1.204-2.464-2.839.172-.631.716-2.036 2.636-2.036.057 0 1.862 0 2.377 1.864z"
        fill="#91E7F2"
      />
      <path
        d="M223.064 101.996c.144 1.348-.744 2.724-2.005 3.183-1.232.459-2.635-.487-2.922-1.721-.2-.803.602-3.154 1.977-3.413 1.375-.258 2.778.345 2.95 1.951z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M205.189 31.929c.258 1.147-1.432 2.896-2.521 2.782-4.039-.43-2.779-5.306-.029-5.134a3.26 3.26 0 011.691.602c.687.488.773.975.859 1.75.172.774-.029-.287 0 0z"
        fill="#fff"
      />
      <path
        d="M313.588 125.084c1.375-.431 2.893.602 2.807 1.979-.057.688.143 1.147-.372 1.749-.516.631-1.261 1.119-2.063 1.176-.573.057-1.174-.229-1.69-.516-.831-.459-1.576-3.471 1.318-4.388z"
        fill="#91E7F2"
      />
      <path
        d="M343.552 154.166c1.347-.229 2.865 1.405 2.664 2.696-.229 1.319-1.403 2.467-2.807 2.266-.688-.115-2.234-.975-2.234-2.266 0-1.032.859-2.438 2.377-2.696z"
        fill="#3BB2F4"
      />
      <path
        d="M341.547 125.112c.487-1.262 1.69-2.122 3.065-2.065.573 0 1.375.775 1.575 1.262 1.204 3.184-1.718 3.93-2.778 3.815-1.891-.201-2.063-2.495-1.862-3.012z"
        fill="#007FC8"
      />
      <path
        d="M335.961 137.158c.028-1.376 1.203-2.122 2.349-2.466.63-.172 1.231-.086 1.776.287.401.286 1.174.946 1.203 1.491.029 1.147-.831 2.094-1.833 2.438-.631.201-1.433.545-2.063.201-.659-.373-1.203-1.291-1.432-1.951 0-1.032.028.086 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M322.239 133.172c.115 1.319-.917 2.581-2.206 2.61-1.718.028-2.234-1.492-2.349-1.721-.716-1.492.545-2.754 2.063-2.84 1.346-.143 2.378.631 2.492 1.951z"
        fill="#007FC8"
      />
      <path
        d="M318.744 167.474c.201 2.524-3.839 3.786-4.87 1.348-.516-1.233.974-4.274 3.896-3.04.745.287.859.975.974 1.692.115 1.176-.029-.258 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M351.946 192.569c-2.493.259-3.868-3.785-1.433-4.847 1.232-.545 2.894.746 3.18 2.151.344 1.693-1.031 2.582-1.747 2.696-1.175.144.257-.028 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M301.957 189.558c-.458-1.319.602-2.696 1.834-3.069 1.289-.43 2.549.889 2.721 2.065.086.574.229 1.377-.258 1.836-.487.459-1.031.832-1.69 1.004-1.06.258-2.263-.861-2.607-1.836z"
        fill="#fff"
      />
      <path
        d="M287.892 82.407c3.466-.086 2.893 4.962-.201 4.732-1.203-.086-1.805-1.262-2.12-2.294-.315-1.176 1.203-2.524 2.321-2.438.716 0-.631-.029 0 0zM264.602 78.994c.2-.201 2.062-1.033 3.18.573.773 1.119.114 2.295-.631 3.184-.974 1.204-3.007.057-3.437-.516-.43-.545-.029-2.38.888-3.241zM316.997 157.321c-1.175.172-2.521-.688-2.521-2.208 0-1.176.544-2.094 1.919-2.324 1.518-.258 2.607.603 2.607 1.922.029 1.52-.487 2.38-2.005 2.61zM214.47 33.334c-.086 1.033-.487 2.065-1.661 2.18-1.318.114-2.894-.574-2.922-2.037-.029-1.52 1.203-2.437 2.578-2.638 1.146-.172 2.034 1.52 2.005 2.495-.028.143 0-.66 0 0zM294.996 189.157c1.146.028 2.292.917 2.349 2.122.029.717-.544 1.434-.888 2.008-.343.602-1.432.487-2.005.344-1.117-.287-2.149-1.119-1.862-2.352.286-1.061 1.232-2.18 2.406-2.122 1.232.028-1.461-.086 0 0z"
        fill="#007FC8"
      />
      <path
        d="M213.983 79.022c0-1.06.688-2.495 2.407-2.38 1.317.057 2.377 1.205 2.12 2.553-.23 1.233-1.232 2.237-2.464 2.237-1.289 0-2.063-1.348-2.063-2.41zM245.466 103.659c0-1.09 1.146-2.495 2.292-2.294 1.088.201 2.435 1.061 2.435 2.266 0 1.261-1.375 2.323-2.578 2.38-1.118.029-2.12-1.291-2.149-2.352zM286.402 114.73c-.086 2.954-4.956 2.438-4.383-.402.229-1.175.974-2.208 2.263-2.265 1.375-.029 2.12 1.462 2.12 2.667-.029 1.205 0-1.52 0 0zM279.011 145.275c-1.289.057-2.005-1.176-1.804-2.381.114-.573.515-1.175.83-1.634.201-.259 3.008-1.32 3.438 1.72.172 1.148-1.146 2.266-2.464 2.295-.802.029.688 0 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M307.2 151.126c.171 1.09-.803 2.352-1.862 2.495-.974.143-2.665-.545-2.722-1.721-.057-1.147.859-2.61 2.034-2.782a2.52 2.52 0 011.747.402c.631.459.659.946.803 1.606.143.946-.086-.344 0 0zM303.59 133.602c-1.404.258-2.549-1.549-2.406-2.122.286-1.176.916-2.209 2.177-2.438.802-.144 2.406.774 2.406 1.749 0 1.32-.63 2.868-2.177 2.811-.372-.057.774.029 0 0z"
        fill="#fff"
      />
      <path
        d="M243.375 69.013c.029-1.205.544-2.41 1.661-2.524 1.891-.172 3.066.602 2.894 2.036-.487 3.958-4.555 2.065-4.555.488z"
        fill="#007FC8"
      />
      <path
        d="M312.9 182.503c-.057.573-.888 1.175-1.403 1.491-.287.172-1.06.057-1.376 0-.601-.057-1.088-.516-1.518-.947-1.088-1.175-.2-2.495.917-3.154 1.117-.66 3.695-.115 3.38 2.61z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M306.741 112.149c-.143-1.147 1.06-2.639 2.349-2.524 1.347.115 2.464 1.032 2.149 2.581-.401 1.95-4.211 2.008-4.498-.057z"
        fill="#fff"
      />
      <path
        d="M322.697 110.37c1.289-.344 2.722.316 2.808 1.75.2 3.155-4.355 3.298-4.383.201 0-1.004.401-1.664 1.575-1.951z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M291.559 94.28c.085-.487.429-1.06.716-1.405.458-.516 1.174-.717 1.833-.66 1.146.115 2.406 1.09 1.977 2.266-1.433 3.872-4.784 1.406-4.526-.2z"
        fill="#fff"
      />
      <path
        d="M328.771 142.894c-1.06.086-2.149-.717-2.006-1.864.086-.688.316-2.409 2.264-2.495 2.578-.115 2.807 4.331-.258 4.359-1.347.086.63 0 0 0zM238.505 69.816c-.057 1.204-.974 2.094-2.149 2.266-2.549.401-2.549-2.639-1.489-3.643.515-.487 1.031-.745 1.604-.831 1.203-.173 2.005 1.147 2.034 2.208-.086 1.405-.029-.115 0 0z"
        fill="#007FC8"
      />
      <path
        d="M234.036 83.64c-2.807-.029-2.177-3.728-1.146-4.13.43-.172 1.375-.402 1.834-.402 1.174.03 1.833 1.406 1.747 2.467-.086 1.204-1.26 2.008-2.435 2.065-.401-.143 1.547-.086 0 0zM312.213 190.849c-.573.057-1.203-.058-1.662-.459-.315-.287-.974-.918-.888-1.377.115-.574.573-1.176 1.003-1.577.487-.431 3.094-1.406 3.667 1.462.257 1.09-1.032 1.865-2.12 1.951zM234.896 86.078c.716-1.061 1.804-1.348 2.95-.717 1.347.746 1.06 2.122.315 3.212-.859 1.29-2.721.746-3.265.201-.545-.574-.602-1.807 0-2.696z"
        fill="#91E7F2"
      />
      <path
        d="M207.595 101.881c-1.175.258-2.464-.344-2.893-1.893-.201-.774-.172-2.84 2.721-2.18 3.037.717 1.547 3.757.172 4.073z"
        fill="#3BB2F4"
      />
      <path
        d="M260.821 81.432c.028 1.032-.717 2.093-1.719 2.409-.974.315-2.464-.574-2.349-1.663.114-1.148.372-2.438 1.661-2.754 1.117-.287 2.493.89 2.407 2.008.028 1.204.057-.803 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M257.526 64.538c1.003-.458 2.349-.028 2.75 1.033 1.118 2.782-3.065 4.188-4.067 1.692-.545-1.405.257-2.237 1.317-2.725zM213.955 47.703c-.946.029-1.862-1.147-1.891-2.008-.029-1.348 1.232-2.466 2.578-2.323.344.029.917.488 1.203.689.459.286.516.831.487 1.348-.057 1.118-1.26 2.294-2.377 2.294-.573 0 .916 0 0 0zM308.431 75.007c2.808-.2 2.722 3.7.373 4.388-1.032.316-2.349-.946-2.321-1.979.029-1.09.974-2.18 1.948-2.409 1.203-.086-.086 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M218.767 93.736c1.175-.172 2.034 1.233 1.776 2.237-.257 1.09-1.518 2.352-2.75 1.95-.744-.258-1.661-1.434-1.489-2.237.286-1.463 1.117-1.864 2.463-1.95.602-.115-1.002.057 0 0z"
        fill="#fff"
      />
      <path
        d="M240.424 91.04c-.2-1.09.774-2.352 1.948-2.524 1.175-.172 2.263 1.233 2.206 2.065-.286 3.04-3.839 2.323-4.154.459z"
        fill="#007FC8"
      />
      <path
        d="M349.224 144.673c2.951-.086 1.805 4.933-.63 4.187-.573-.172-1.117-.717-1.575-1.118-.316-.287-.086-1.09 0-1.434.315-1.004 1.174-1.52 2.205-1.635 1.003-.029-1.632.172 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M266.378 107.531c-1.117-.086-2.206-1.119-2.12-2.294.057-.631.401-1.148.831-1.578 1.203-1.09 3.18.516 3.237 1.635.029 1.233-.659 2.18-1.948 2.237-.888-.086 1.003-.057 0 0z"
        fill="#007FC8"
      />
      <path
        d="M333.755 184.252c-1.146-.258-1.833-.86-1.776-2.151.029-1.09.945-1.721 1.977-1.749 1.317-.029 2.263.774 2.263 1.979 0 1.003-1.146 2.237-2.464 1.921zM321.036 196.757c-1.146-.258-1.833-.861-1.776-2.151.029-1.09.945-1.721 1.977-1.75 1.317-.028 2.263.775 2.263 1.979 0 1.004-1.146 2.237-2.464 1.922zM225.041 61.47c-.516-1.176.573-2.209 1.518-2.668 1.146-.573 2.55.488 2.492 1.635-.114 3.385-3.609 1.95-4.01 1.033z"
        fill="#3BB2F4"
      />
      <path
        d="M297.059 178.458c.086-1.003 1.031-1.864 2.005-1.95 1.174-.115 2.693.488 2.263 2.352-.688 3.069-4.555 2.409-4.268-.402zM240.911 112.292c-2.435.057-3.036-4.044-.315-4.073 2.578.029 3.008 4.016.315 4.073z"
        fill="#007FC8"
      />
      <path
        d="M291.558 184.711c-.028-.947 1.032-1.864 1.977-1.893 1.175-.057 2.005 1.09 2.005 2.208 0 1.033-.888 1.979-1.948 1.922-1.26-.115-2.005-1.09-2.034-2.237z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M243.604 48.62c.057-2.552 4.813-2.064 4.096.345-.286.975-1.346 2.094-2.435 1.864-.916-.2-1.718-1.262-1.661-2.208.029-.717-.057.889 0 0zM292.819 113.382c1.117-.029 2.149.631 2.263 1.778.057.774-.773 2.122-1.575 2.323-.946.23-2.435-.545-2.435-1.52 0-1.377.458-2.524 1.747-2.581z"
        fill="#fff"
      />
      <path
        d="M290.441 149.204c1.06.029 2.206 1.09 2.034 2.065-.143.861-.974 2.065-1.89 1.864-3.352-.659-1.805-3.986-.144-3.929z"
        fill="#007FC8"
      />
      <path
        d="M261.107 86.738c1.031-.201 1.776.23 2.12.831.544.947.401 2.61-.917 2.983-.601.172-2.291-.2-2.521-.946-.343-1.004-.086-2.61 1.318-2.868z"
        fill="#91E7F2"
      />
      <path
        d="M307.4 88c-.057-2.324 4.097-2.868 3.982-.23-.086 2.495-3.925 2.897-3.982.23 0-.631.029.946 0 0z"
        fill="#007FC8"
      />
      <path
        d="M199.431 49.51c-.057-.946 1.575-1.72 2.349-1.549 1.089.23 1.862 1.607 1.461 2.639-.372.918-1.662 1.807-2.607 1.262-1.06-.631-1.089-1.205-1.203-2.352-.029-.688.114 1.262 0 0zM222.635 72.54c.028.947-.573 2.123-1.633 2.209-1.261.086-2.263-.602-2.149-1.864.086-1.033.659-1.893 1.748-2.037 1.117-.143 2.091.46 2.034 1.693.028 1.147.057-1.09 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M208.712 85.82c-.057-2.667 4.212-2.38 4.126-.058-.029.947-.946 1.893-1.891 1.95-1.289.03-1.862-.802-2.235-1.892 0-.631.086.23 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M287.004 153.707c.258.201.43 1.348.458 1.692.086 1.147-.917 1.75-1.948 1.807-.888.057-1.976-.688-2.034-1.635-.028-.516.43-1.147.688-1.549.172-.315 1.604-1.29 2.836-.315z"
        fill="#fff"
      />
      <path
        d="M326.622 131.68c-.859.115-2.463-.831-2.349-1.864.057-.631-.172-2.036 2.091-2.065 2.263-.057 2.435 3.643.258 3.929z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M259.159 7.894c2.321-.057 3.094 3.327.63 3.844-1.117.229-1.833-.23-2.291-1.291-.573-1.29.63-2.065 1.661-2.553 1.032-.028-.086.029 0 0zM211.692 72.369c-2.12-.832-1.375-4.216 1.031-3.758 2.406.46 1.862 4.905-1.031 3.758z"
        fill="#fff"
      />
      <path
        d="M301.699 118.659c-.028 1.061-.687 2.237-1.776 2.209-.515 0-2.177-.574-2.177-1.291 0-.889.401-2.122 1.289-2.438 1.318-.459 2.321.258 2.664 1.52-.028.746-.057-.229 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M201.551 94.31c.974-.03 2.005 1.347 1.804 2.265-.573 2.524-4.039 1.348-3.953-.2.115-1.55 1.06-2.037 2.149-2.066z"
        fill="#007FC8"
      />
      <path
        d="M322.239 185.256c-.2-1.147-.115-1.807.802-2.581.831-.689 2.55-.631 2.779.459.917 3.871-3.438 2.925-3.581 2.122z"
        fill="#91E7F2"
      />
      <path
        d="M230.369 49.195c-.974.86-1.833 1.147-2.75.057-.63-.746-.917-1.836-.029-2.524.373-.315.917-.459 1.376-.602.687-.172 1.289.057 1.69.832.372.774.544 1.49-.287 2.237zM356.443 153.047c-1.146.201-1.747-.487-2.091-1.491-.344-1.004.115-1.921 1.117-2.294 1.375-.488 2.664.545 2.836 1.577.115 1.09-.716 2.008-1.862 2.208zM290.155 120.868c.917-.029 1.891.745 2.148 1.606.315 1.061-.802 2.065-1.776 2.208-.802.115-2.349-.717-2.263-1.606.086-1.119.774-1.893 1.891-2.208 1.031-.029-.602.172 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M295.827 84.5c.916-.286 1.804.488 2.062 1.32.344 1.061-.143 1.635-.974 2.266-1.146.889-2.864-.23-2.893-1.291 0-1.032.716-1.922 1.805-2.294z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M262.196 99.443c-2.349.029-2.235-3.528-.086-3.872 2.463-.401 2.721 3.844.086 3.872z"
        fill="#fff"
      />
      <path
        d="M331.435 121.269c.315-1.118.487-1.577 1.69-1.979.802-.258 1.948.545 2.148 1.377.258 1.09-.687 2.122-1.69 2.323-.515.115-1.031 0-1.461-.258-.63-.315-.601-.832-.687-1.463.114-.344.057.373 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M337.078 149.147c-1.088.143-2.005-.66-2.062-1.75-.058-1.032 1.031-2.036 2.033-2.036.917 0 2.063 1.061 1.834 2.065-.172.975-.802 1.692-1.805 1.721-1.203.143.86-.029 0 0zM308.346 133c.63-.746 2.034-.717 2.721-.115.602.516.831 1.549.602 2.294-.344 1.234-2.292 1.176-3.094.775-.774-.43-1.032-2.037-.229-2.954z"
        fill="#007FC8"
      />
      <path
        d="M309.749 171.604c.917.487 1.347 1.577.945 2.552-.343.861-2.091 1.004-2.864.689-1.289-.545-1.146-2.094-.774-2.668.401-.602 1.776-1.061 2.693-.573z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M256.409 105.409c-.802.057-1.891-1.004-1.805-1.807.058-.631.201-1.922 1.862-1.893 2.206 0 2.263 3.528-.057 3.7zM345.529 181.699c-.029-.975.573-1.979 1.575-2.151.946-.172 2.178.43 2.292 1.434.115 1.061-.687 1.778-1.604 2.123-1.146.43-1.977-.373-2.263-1.406-.029-1.032.028.115 0 0z"
        fill="#fff"
      />
      <path
        d="M357.618 84.386c1.06.373 1.633 1.262 1.317 2.352-.143.544-2.062 2.036-2.979 1.147-2.378-2.266 1.175-3.643 1.662-3.5z"
        fill="#007FC8"
      />
      <path
        d="M295.139 70.303a2.153 2.153 0 01-2.091 1.865c-2.664.086-1.948-3.987.086-3.614 1.031 0 2.149.63 2.005 1.75z"
        fill="#3BB2F4"
      />
      <path
        d="M208.483 90.58c.946.173 1.289 1.55.974 2.41-.343.918-1.518 1.663-2.463 1.118-.459-.258-.888-.745-1.118-1.233-.229-.516.43-2.725 2.607-2.294z"
        fill="#007FC8"
      />
      <path
        d="M287.319 58.831c.114.975-.86 1.922-1.748 2.122-.916.201-2.377-.774-2.034-1.806.287-.86.917-1.807 1.891-1.95 1.003-.173 1.891.602 1.891 1.634.114.975-.029-1.204 0 0zM262.568 47.1c2.722 0 1.834 4.073-.515 3.615-2.407-.46-1.748-3.643.515-3.614zM292.962 168.191c.229 1.061-.372 2.036-1.461 2.294-.831.201-2.406-.631-2.263-1.634.401-2.352 3.209-2.926 3.724-.66z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M307.028 121.298c2.492-.23 2.291 3.728-.086 3.786-.516 0-1.232-.373-1.461-.832-.888-1.836.573-2.897 1.547-2.954 1.26-.115-1.203.086 0 0zM236.041 91.441c1.032.86 1.003 1.922 0 2.84-.63.573-1.489.716-2.205.229-1.146-.746-1.06-2.123-.23-2.84.487-.401 1.433-1.09 2.435-.229z"
        fill="#fff"
      />
      <path
        d="M309.205 161.049c.372-.774 1.547-1.548 2.406-1.204 1.118.459 1.375 1.577.946 2.581-.487 1.176-1.576 1.463-2.636 1.004-1.031-.459-1.232-1.319-.716-2.381z"
        fill="#007FC8"
      />
      <path
        d="M266.35 73.344c-.144-1.148.945-1.98 1.976-2.209 1.089-.23 1.776.947 1.69 1.893-.229 2.065-3.437 2.438-3.666.316-.115-.918 0 .172 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M360.081 179.003c-2.549 0-2.034-3.929.43-3.613.888.114 1.69.975 1.719 1.864 0 .803-1.404 1.864-2.149 1.749-1.231 0 .573.086 0 0z"
        fill="#fff"
      />
      <path
        d="M274.084 59.261c-.028 1.061-.888 2.065-2.005 2.037-.773-.029-1.948-.976-1.719-1.836.172-.66.974-1.95 1.776-1.922.946.087 1.977.632 1.948 1.721z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M261.709 57.598c1.031.23 2.349.688 2.034 2.036-.487 2.094-3.295 2.352-3.581-.172-.086-.86.773-2.036 1.547-1.864zM299.007 169.94c.687-.487 1.776-.631 2.263.258.601 1.09.372 1.893-.545 2.639-1.002.803-2.32.287-2.664-.459-.315-.717-.114-1.663.946-2.438z"
        fill="#fff"
      />
      <path
        d="M306.455 164.864c1.003 0 1.461.287 1.69 1.262.229.918.058 1.52-.687 2.093-.946.717-1.862.373-2.55-.545-.773-1.003.315-2.753 1.547-2.81 1.06.028-.516.028 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M253.287 58.573c1.031.057 1.69.803 1.747 1.836.057.975-1.346 1.72-2.234 1.75-1.777 0-2.034-3.73.487-3.586z"
        fill="#fff"
      />
      <path
        d="M313.874 147.369c-.315 1.032-.687 2.208-2.034 1.807-.945-.287-1.518-1.033-1.69-1.979-.172-.947 1.06-1.463 1.776-1.521 1.146-.114 1.92.574 1.948 1.693-.086.344-.028-.574 0 0zM342.865 186.776c2.521-.086 2.578 3.929.057 3.642-.458-.057-1.719-.315-1.891-1.52-.229-1.807.86-2.122 1.834-2.122 1.318.029-.287.172 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M327.395 174.414c.115 2.295-3.065 2.639-3.695.545-.286-1.003.888-1.95 1.747-2.208 1.175-.402 1.605.774 1.948 1.663.029.89-.143-.344 0 0zM279.842 129.673c.487 2.266-3.667 3.04-3.667.545-.028-1.606 3.151-2.897 3.667-.545z"
        fill="#007FC8"
      />
      <path
        d="M293.507 175.533c-.888.947-1.891 1.004-2.865.143-.888-.774-.315-1.892.43-2.552.83-.746 2.205-.43 2.692.201.487.602.23 1.692-.257 2.208z"
        fill="#3BB2F4"
      />
      <path
        d="M288.035 100.963c.859 0 1.977.574 1.862 1.578-.143 1.233-.63 1.979-1.862 2.036-2.263.086-2.206-3.614 0-3.614zM308.489 194.635c.2 2.495-3.782 2.638-3.524.028.115-1.061 1.289-1.778 2.292-1.52.888.23 1.203.516 1.232 1.492.057.831-.029-1.062 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M197.655 63.506c2.091.115 2.435 2.553.458 3.27-1.146.43-2.12.115-2.263-1.147-.086-1.176.544-2.209 1.805-2.123z"
        fill="#fff"
      />
      <path
        d="M205.131 40.533c.803.029 1.748.86 1.777 1.692.057 1.004-.889 1.721-1.805 1.922-.802.2-2.149-1.29-2.006-2.037.172-.831 1.175-1.606 2.034-1.577z"
        fill="#007FC8"
      />
      <path
        d="M202.152 83.755c-.028-2.295 3.495-2.266 3.638-.144.058.746-.687 1.922-1.489 2.008-1.261.115-1.834-.86-2.149-1.864 0-.975.058.114 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M234.981 100.304c.172-.717.802-1.721 1.633-1.606.716.086 2.063.774 1.977 1.663-.229 2.581-3.552 2.524-3.61-.057.115-.488.029 1.176 0 0zM273.826 89.548c-.172-.688.086-1.348.344-1.979.229-.573 1.69-.831 2.148-.573.831.487 1.118 1.606.803 2.438-.974 2.323-3.18.573-3.295.114z"
        fill="#fff"
      />
      <path
        d="M254.633 53.755c-2.292.086-1.747-3.27.229-3.528 2.492-.344 2.149 3.729-.229 3.528-.602.028.544.057 0 0z"
        fill="#007FC8"
      />
      <path
        d="M287.777 148.516c-.573.631-1.89.946-2.578.316-.487-.459-.888-2.209-.172-2.61.287-.172 1.719-1.176 2.636-.23.916.918.83 1.692.114 2.524z"
        fill="#91E7F2"
      />
      <path
        d="M296.514 76.011c1.06 0 1.977 1.032 1.748 2.008-.23 1.003-.974 1.692-2.063 1.491-2.148-.488-1.49-3.499.315-3.499z"
        fill="#3BB2F4"
      />
      <path
        d="M300.41 155.743c2.263-.172 1.805 4.13-.659 3.413-1.06-.315-1.575-.889-1.289-2.036.201-.946 1.06-1.319 1.948-1.377z"
        fill="#007FC8"
      />
      <path
        d="M251.367 110.055c.373-1.176.802-1.893 2.091-1.778.888.086 1.633 1.118 1.404 1.979-.286 1.003-.945 1.434-1.977 1.434-.687.258-1.776-.861-1.518-1.635zM233.148 62.273c.573-1.922 3.781-1.922 3.466.401-.114.918-.859 1.779-1.833 1.779-1.289-.03-1.461-1.176-1.633-2.18.029-.115.086.373 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M191.066 65.686c1.977-.23 3.266 2.725 1.003 3.327-.888.23-1.977-.086-2.349-.975-.487-1.205.487-1.807 1.346-2.352.115-.029-.315.2 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M252.255 31.441c-.515-.803 1.175-1.75 1.805-1.72.974.028 1.748.831 1.862 1.778.086.745-1.26 1.634-1.919 1.663-1.06.029-1.834-.63-1.748-1.72-.257-.431-.057.745 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M299.236 165.81c.114.803-.659 1.492-1.347 1.779-.773.286-1.833-.173-2.148-.947-.43-1.09-.086-2.294 1.633-2.639.974-.2 1.747.89 1.862 1.807z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M364.693 142.091c-.085 2.209-2.721 2.868-3.523.689-.745-2.065 3.638-3.127 3.523-.689zM319.661 97.177c.831-.63 1.518-1.376 2.607-.918.945.402 1.088 1.349.716 2.209-.458 1.032-1.404 1.204-2.378.803-.974-.43-.945-1.119-.945-2.094.401-.315 0 .144 0 0z"
        fill="#fff"
      />
      <path
        d="M318.63 84.988c.888 0 1.461.574 1.633 1.434.143.832-.745 1.692-1.461 1.95-.946.345-1.862-.544-2.092-1.434-.286-1.06.946-1.95 1.92-1.95zM273.339 68.296c-.143-.918.86-1.606 1.662-1.778.916-.201 1.862.717 2.034 1.577.286 1.664-3.868 2.581-3.696.2-.086-.515-.057 1.005 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M264.717 118.258c.544.86-.315 1.807-1.347 2.036-1.146.229-2.349-.43-2.291-1.549.028-1.147 2.005-3.126 3.638-.487z"
        fill="#fff"
      />
      <path
        d="M220.2 35.772c2.062.029 1.661 2.954-.029 3.356-.945.23-1.862-.603-2.005-1.52-.229-1.09 1.031-1.836 2.034-1.836.83.029-.716 0 0 0z"
        fill="#007FC8"
      />
      <path
        d="M305.71 98.984c-.286 0-.601 0-.802-.23-.201-.257-.172-.4-.43-.63-.172-.172-.286-.402-.258-.631.058-.803.86-1.98 1.719-1.95 2.521.057 2.177 3.47-.229 3.441-.372 0 1.604 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M321.638 152.732c0-.889.544-1.749 1.403-1.979.774-.201 2.063.316 2.149 1.205.115 1.004-.716 2.179-1.776 2.179-1.117-.028-1.633-1.032-1.776-1.405.028-1.09.028.057 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M303.963 66.69c.114.918-.831 2.094-1.748 2.094-.945 0-1.461-.287-1.661-1.721-.201-1.635 3.122-2.61 3.409-.373z"
        fill="#fff"
      />
      <path
        d="M243.174 36.833c.802-.143 1.805.717 1.748 1.52-.086.975-.946 1.606-1.862 1.836-1.948.516-2.321-3.327.114-3.356.774-.143-.687 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M275.832 100.303c0-.774.658-1.95 1.546-1.95.889 0 1.92.803 1.948 1.75 0 1.806-3.466 2.437-3.494.2zM295.254 137.216c.659.688.344 1.749-.258 2.323-.831.774-1.747.688-2.492-.172-.602-.689-.43-1.836-.115-2.295.344-.459 2.178-.602 2.865.144z"
        fill="#007FC8"
      />
      <path
        d="M330.461 147.77c2.206.029 2.32 3.184.229 3.442-2.263.258-2.349-3.471-.229-3.442z"
        fill="#91E7F2"
      />
      <path
        d="M361.772 163.745c-.86-.028-1.605-.315-1.719-1.233-.115-.774.258-1.721.974-2.122.687-.402 2.234.717 2.291 1.462.058 1.033-.372 1.922-1.546 1.893z"
        fill="#3BB2F4"
      />
      <path
        d="M309.377 103.258c-1.146-.689-.631-1.606.057-2.352.602-.66 1.318-.803 2.091-.316.917.574 1.06 1.779.516 2.496-.544.717-1.719.717-2.664.172z"
        fill="#91E7F2"
      />
      <path
        d="M231.315 74.204c1.002.058 1.976.947 1.69 1.922-.258.86-1.461 1.778-2.321 1.376-1.948-.86-.945-3.412.631-3.298z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M346.703 164.835c.831-.114 1.748.574 1.776 1.434.058.775-.745 1.807-1.489 1.922-1.433.229-1.92-1.205-1.92-1.75 0-.487.774-1.491 1.633-1.606z"
        fill="#fff"
      />
      <path
        d="M267.008 127.206c.172-.43.344-.803.745-.975.917-.402 2.005-.316 2.406.688.258.631.058 1.664-.572 1.979-2.321 1.291-2.865-.946-2.579-1.692z"
        fill="#007FC8"
      />
      <path
        d="M315.249 138.621c.917-.029 1.834.316 1.776 1.434-.057.918-.716 1.807-1.69 1.778-2.148-.028-2.091-3.241-.086-3.212.659 0-.802 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M300.496 194.95c.917-.029 1.833.315 1.776 1.434-.057.918-.716 1.807-1.69 1.778-2.148-.029-2.091-3.212-.086-3.212.659 0-.802 0 0 0zM303.046 111.26c.372 2.036-3.724 2.179-3.323-.058.114-.631.773-1.721 1.518-1.721.945 0 1.805.832 1.805 1.779.143.745 0-.947 0 0zM334.901 105.179c2.292.115 1.948 2.811.057 3.413-.859.258-1.747-.803-1.804-1.577-.086-1.061.83-1.578 1.747-1.836.859.058-.029.029 0 0z"
        fill="#007FC8"
      />
      <path
        d="M284.798 126.174c.802.172 1.776.975 1.404 1.921-.373.889-1.146 1.549-2.149 1.291-2.12-.602-1.088-3.212.745-3.212.573.143-1.232 0 0 0zM222.807 54.787c-.86.029-1.29-.803-1.318-1.577-.029-.976 1.146-1.664 2.005-1.721.974-.057 1.547 1.118 1.318 1.95-.258.89-1.175 1.32-2.005 1.348z"
        fill="#91E7F2"
      />
      <path
        d="M377.556 135.495c-2.091.028-1.719-3.155.143-3.385 2.149-.286 2.12 3.327-.143 3.385zM263.886 123.592c.63-.029 1.575.516 1.69 1.147.143.889-.544 2.123-1.461 2.324-1.06.258-1.833-.832-1.805-1.75 0-.889.573-1.663 1.576-1.721z"
        fill="#007FC8"
      />
      <path
        d="M200.892 56.938c.831-.286 1.89.43 1.976 1.291.086.975-.2 1.721-1.203 1.95-1.146.258-1.833-.258-2.062-1.118-.229-.86.515-1.836 1.289-2.123z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M226.445 103.659c.515-.746 1.89-.975 2.521-.229.687.803 1.06 2.237.172 2.696-2.607 1.434-3.438-1.406-2.693-2.467z"
        fill="#fff"
      />
      <path
        d="M291.931 109.711c-.716.115-1.662-.229-1.977-.889-.372-.775.373-1.922 1.117-2.18 1.032-.373 2.149.402 2.321 1.061.143.689-.401 1.836-1.461 2.008z"
        fill="#3BB2F4"
      />
      <path
        d="M214.7 60.322c-.086-2.065 3.466-1.778 3.265.43-.086.918-.573 1.578-1.919 1.463-1.404-.143-1.346-1.348-1.346-1.893-.029-.774.028.89 0 0zM312.814 119.233c.172-.889 1.146-1.692 2.063-1.463.888.23 1.49 1.434 1.232 2.266-.201.66-1.118 1.09-1.776 1.061-.659-.029-1.748-.746-1.519-1.864z"
        fill="#007FC8"
      />
      <path
        d="M351.774 137.072c-.802-.028-1.547-.373-1.719-1.233-.172-.832.602-1.491 1.289-1.864.917-.459 2.005.487 2.005 1.405 0 1.205-.573 1.434-1.575 1.692-1.232-.086.057-.028 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M330.948 97.866c.515.717-.029 1.835-.831 2.208-1.146.545-2.148-.315-2.292-1.147-.172-.832 1.604-3.212 3.123-1.061zM356.472 182.244c.773.144 1.346 1.148 1.203 1.893-.143.832-1.031 1.836-1.833 1.549-2.865-.975-1.318-3.786.63-3.442z"
        fill="#3BB2F4"
      />
      <path
        d="M226.502 95.112c-.143.89-1.146 1.692-2.034 1.434-.773-.2-1.404-1.06-1.26-1.864.143-.918 1.203-1.52 2.033-1.405.831.143 1.404.918 1.261 1.835zM371.54 150.552c2.034-.028 2.378 3.126.229 3.155-.888 0-1.976-.66-1.833-1.606.143-1.061.659-1.233 1.604-1.549.974 0-.172.058 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M268.097 37.865c.974 0 1.49.718 1.375 1.664-.057.401-.229.774-.573 1.004-.286.2-.831.66-1.175.688-.859.058-1.661-1.09-1.575-1.893.086-.545 1.375-1.635 1.948-1.463.773 0-.516-.143 0 0z"
        fill="#fff"
      />
      <path
        d="M336.677 96.92c.143-.66.831-1.75 1.604-1.721.888.028 1.633.889 1.633 1.75 0 1.032-.888 1.72-1.833 1.433-.974-.287-1.433-.401-1.404-1.463 0-.028-.029.89 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M276.003 136.843c.602-.717 1.519-.775 2.006-.373.601.487.916 1.377.544 2.122-.401.775-1.518.947-2.12.775-.602-.172-1.203-1.549-.43-2.524zM263.485 26.25c0 .889-.516 1.52-1.404 1.635-.716.086-1.604-1.033-1.661-1.693-.086-.946.601-1.49 1.432-1.72 1.06-.316 1.604.946 1.633 1.778 0 .946 0-.172 0 0zM222.291 10.217c-.086 1.807-2.406 2.41-3.037.517-.716-2.266 3.123-2.754 3.037-.517-.057 1.176.029-.774 0 0z"
        fill="#fff"
      />
      <path
        d="M309.148 29.921c0-.803 1.174-1.52 1.919-1.32.888.23 1.375 1.205 1.232 2.066-.315 2.007-3.18 1.032-3.151-.746z"
        fill="#91E7F2"
      />
      <path
        d="M225.786 77.875c2.234.029 1.719 3.643-.172 3.184-.659-.172-1.547-.947-1.547-1.664-.029-.745 1.031-1.52 1.719-1.52z"
        fill="#3BB2F4"
      />
      <path
        d="M238.505 30.81c-.057-1.692 2.607-2.61 3.036-.717.459 2.036-2.979 3.069-3.036.717zM332.523 172.952c.803-.029 1.519.831 1.433 1.577-.115.947-1.003 1.492-1.891 1.52-.688 0-1.346-.918-1.404-1.549-.086-.889 1.118-1.548 1.862-1.548.659-.029-.916 0 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M318.143 71.996c-2.092-.029-1.404-3.299.401-3.27 2.12.057 1.489 3.298-.401 3.27z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M318.773 58.803c-2.407-.517-1.404-3.299.401-3.27 2.091.029 2.034 3.528-.401 3.27zM363.605 156.116c-2.005.029-2.034-2.524-.229-2.954 2.091-.487 2.635 2.926.229 2.954zM354.696 173.497c-.917-.602-.688-1.664-.23-2.438.459-.774 1.461-.975 2.292-.201.573.545.63 1.52.201 2.151-.63.861-1.375.775-2.263.488-.23-.545.401.258 0 0z"
        fill="#fff"
      />
      <path
        d="M312.7 198.048c-.917-.603-.688-1.664-.229-2.438.458-.775 1.461-.975 2.291-.201.573.545.631 1.52.201 2.151-.602.86-1.375.774-2.263.488-.229-.545.401.258 0 0zM293.736 199.969c-.917-.602-.688-1.663-.229-2.438.458-.774 1.461-.975 2.291-.201.573.545.631 1.52.201 2.151-.602.89-1.375.775-2.263.488-.229-.545.401.287 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M337.651 196.355c-.917-.602-.688-1.663-.229-2.438.458-.774 1.461-.975 2.292-.2.572.545.63 1.52.2 2.151-.602.889-1.375.803-2.263.487-.229-.516.401.287 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M316.51 108.162c-.888-.258-1.519-.459-1.633-1.52-.086-.66.716-1.52 1.289-1.692 1.919-.631 2.492 3.241.344 3.212-.287-.086.83 0 0 0z"
        fill="#fff"
      />
      <path
        d="M346.761 107.703c-.115 1.865-2.98 2.352-3.065.23-.086-1.922 3.208-2.409 3.065-.23z"
        fill="#91E7F2"
      />
      <path
        d="M374.634 178.143c1.805-.201 2.292 3.442-.086 3.012-1.805-.316-1.919-3.098.086-3.012.63-.086-.458-.029 0 0z"
        fill="#007FC8"
      />
      <path
        d="M266.435 63.793c.831.115 1.519.344 1.605 1.29.086.832-.631 1.922-1.547 1.894-.688-.03-1.719-.976-1.547-1.693.114-.516.888-1.491 1.489-1.491 1.118.143-.487 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M304.363 55.275c-.658-.086-1.546-.2-1.69-1.033-.114-.803.86-1.893 1.605-1.979.859-.086 1.632.803 1.718 1.578.144.774-.974 1.348-1.633 1.434-.63-.086.602-.057 0 0z"
        fill="#007FC8"
      />
      <path
        d="M368.704 186.346c.516.373.946 1.462.487 2.036-.372.488-1.26.803-1.862.774-1.174-.057-1.232-1.634-1.002-2.294.229-.688 1.518-1.147 2.377-.516zM282.793 107.703c-.659.172-1.805-.688-1.691-1.434.086-.516.631-1.377 1.118-1.606.888-.43 2.205.373 2.12 1.119-.172 1.434-.316 1.634-1.547 1.921zM217.163 68.095c-1.833.057-1.518-3.212.057-3.27 2.149-.086 1.805 3.07-.057 3.27-.687 0 .716-.086 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M197.769 101.68c-1.833.058-1.518-3.212.058-3.27 2.148-.085 1.804 3.069-.058 3.27-.658.029.745-.086 0 0zM318.085 188.841c.631-.602 1.433-.918 2.235-.488.687.373 1.06 1.234.544 1.922-.344.487-1.26 1.09-1.891.86-.916-.315-1.174-1.462-.888-2.294.946-.947-.171.459 0 0z"
        fill="#007FC8"
      />
      <path
        d="M241.226 81.317c.86-.316 1.748.143 1.834 1.061.086.63-.143 1.463-.745 1.75-.859.401-2.12-.201-2.292-.976-.143-.745.344-1.52 1.203-1.835z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M344.068 61.269c.258-.803.888-1.663 1.862-1.233.802.372 1.203 1.09 1.031 1.95-.143.717-.601 1.061-1.318 1.119-1.088.086-1.489-.86-1.575-1.836.057-.23.086 1.119 0 0z"
        fill="#fff"
      />
      <path
        d="M291.129 145.935c-.115-.775.687-1.377 1.318-1.635 1.088-.43 1.976.43 1.804 1.434-.372 2.638-3.007 1.061-3.122.201z"
        fill="#3BB2F4"
      />
      <path
        d="M323.528 120.982c.201.832-.659 1.348-1.346 1.492-.659.114-1.862-.631-1.92-1.348-.028-.66 1.146-1.578 1.719-1.635.86-.057 1.519.688 1.547 1.491.143.545-.028-.573 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M296.285 126.087c-.114-1.692 2.836-2.265 3.094-.258.229 1.979-3.208 2.324-3.094.258-.028-.487-.028.603 0 0z"
        fill="#fff"
      />
      <path
        d="M230.57 65.284c.773 0 1.575.803 1.461 1.607-.115.803-.974 1.319-1.719 1.376-2.149.23-1.547-3.04.258-2.983.659.03-.516 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M341.49 174.959c1.976.115 1.89 2.811.057 3.012-2.091.229-2.063-3.126-.057-3.012z"
        fill="#007FC8"
      />
      <path
        d="M353.98 159.587c.487.43.63 1.434.229 1.978-.43.603-1.661.947-2.263.488-.745-.545-.487-1.749-.172-2.323.344-.545 1.49-.746 2.206-.143z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M349.969 175.132c-.028 1.749-3.094 1.778-3.036.115.028-.718.687-1.607 1.375-1.779.859-.2 1.661.918 1.661 1.664zM260.248 73.257c.63-.086 1.633.345 1.804 1.004.23.918-.257 1.693-1.145 1.98-1.089.344-1.862-.546-1.92-1.205-.086-.66.659-1.692 1.261-1.779zM323.958 160.103c.458-.746 1.26-1.061 2.062-.66.888.431.86 1.234.487 2.037-.458 1.004-2.005.831-2.463.43-.43-.344-.487-1.147-.086-1.807z"
        fill="#fff"
      />
      <path
        d="M239.937 74.261c.716.144 1.49.66 1.547 1.377.057.746-.888 1.491-1.633 1.405-.83-.114-1.174-.745-1.231-1.49 0-.058.544-1.435 1.317-1.292z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M194.876 75.523c.716.144 1.49.66 1.547 1.377.057.746-.888 1.491-1.633 1.405-.831-.114-1.174-.745-1.232-1.491 0-.057.573-1.434 1.318-1.29z"
        fill="#fff"
      />
      <path
        d="M172.532 93.678c.716.144 1.489.66 1.547 1.377.057.746-.888 1.491-1.633 1.405-.831-.114-1.175-.745-1.232-1.491 0-.057.573-1.463 1.318-1.29z"
        fill="#007FC8"
      />
      <path
        d="M322.468 170.227c-.745.201-1.633-.918-1.718-1.606-.115-.86.401-1.348 1.231-1.463.917-.114 1.748.316 1.891 1.148.143.86-.602 1.692-1.404 1.921z"
        fill="#91E7F2"
      />
      <path
        d="M274.027 106.556c-.745-.229-1.662-.946-1.289-1.922.343-.831 1.289-1.176 2.091-.889 1.69.603.716 2.84-.802 2.811-.258-.086 1.231.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M331.005 88.745c-.029 1.95-3.409 1.807-3.008.258.2-.774.687-1.491 1.49-1.606.916-.115 1.231.689 1.518 1.348 0 .717-.058-.115 0 0zM372.457 167.158c-.745.029-1.633-.43-1.862-1.176-.23-.803 1.26-1.376 1.804-1.462.802-.115 1.375.602 1.404 1.348.057.975-.544 1.032-1.346 1.29-.86.029.229-.057 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M213.181 93.535c-2.034.086-1.661-3.184.057-2.925.717.114 1.576.43 1.404 1.319-.143.745-.659 1.577-1.461 1.606-.573.029.43 0 0 0z"
        fill="#fff"
      />
      <path
        d="M206.793 65.4c.802.056 1.174.83 1.146 1.577-.029.774-.688 1.233-1.375 1.434-.917.258-1.662-.832-1.576-1.635.115-.803.888-1.463 1.805-1.377z"
        fill="#91E7F2"
      />
      <path
        d="M189.662 99.701c.803.058 1.175.832 1.146 1.578-.028.774-.687 1.233-1.375 1.434-.916.258-1.661-.832-1.575-1.635.114-.803.916-1.463 1.804-1.377z"
        fill="#3BB2F4"
      />
      <path
        d="M274.056 49.08c2.119 0 1.604 3.011-.23 3.011-.63 0-1.489-1.004-1.145-1.606.257-.574.601-1.52 1.375-1.405.487 0-.459-.058 0 0z"
        fill="#007FC8"
      />
      <path
        d="M392.509 156.288c.086.717-.429 1.406-1.117 1.549-.63.143-1.661.029-1.719-1.147-.057-1.033.315-1.606 1.461-1.664 1.118-.057 1.261.459 1.375 1.262z"
        fill="#3BB2F4"
      />
      <path
        d="M211.921 99.443c.028-.774.573-1.807 1.489-1.778 1.891.057 1.576 2.925.058 2.897-.86-.029-1.576-.316-1.547-1.119z"
        fill="#91E7F2"
      />
      <path
        d="M280.014 148.975c0-1.836 3.581-1.148 2.865.458-.316.746-.688 1.291-1.576 1.176-.859-.114-1.26-.803-1.289-1.634 0-.631.029 1.262 0 0z"
        fill="#007FC8"
      />
      <path
        d="M287.348 30.409c-.23-1.922 2.177-1.95 2.606-.316.172.688-.487 1.491-1.088 1.72-1.031.402-1.461-.63-1.518-1.404-.086-.717 0 .114 0 0zM280.558 83.783c1.891-.028 2.12 2.668.058 2.639-.573 0-1.461-.287-1.461-1.004.028-.746.572-1.635 1.403-1.635z"
        fill="#3BB2F4"
      />
      <path
        d="M226.96 25.963c.258-.774.888-1.405 1.748-1.233.716.143 1.174.66 1.146 1.405-.058.918-1.032 1.262-1.834 1.29-.229-.114-1.375-.516-1.06-1.462z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M356.644 121.384c.172-1.721 2.549-2.352 2.893-.373.115.717-.573 1.377-1.203 1.52-1.003.201-1.404-.373-1.69-1.147.114-1.061.114.258 0 0z"
        fill="#fff"
      />
      <path
        d="M306.541 142.292c-1.662-.172-.974-2.897.372-2.696 1.346.201 1.432 2.897-.372 2.696zM292.819 180.868c-1.662-.172-.974-2.897.372-2.696 1.347.201 1.433 2.897-.372 2.696z"
        fill="#3BB2F4"
      />
      <path
        d="M294.108 157.694c-1.661-.173-.974-2.897.373-2.696 1.346.2 1.432 2.896-.373 2.696z"
        fill="#91E7F2"
      />
      <path
        d="M328.885 73.946c-2.636-.574-.258-3.212.716-2.553 1.404.976 1.031 2.524-.716 2.553-.057-.029 1.203-.029 0 0zM215.072 52.32c.143-.774.172-1.835 1.26-1.749.888.086 1.404.688 1.261 1.578-.115.889-.344 1.175-1.261 1.175-.859 0-1.088-.2-1.26-1.003.086-.46.029.2 0 0z"
        fill="#007FC8"
      />
      <path
        d="M389.587 182.158c1.576-.057 1.92 2.381.43 2.84-2.034.602-2.607-2.495-.43-2.84.802-.143-.114.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M226.416 70.217c1.748 0 1.518 2.811.057 2.668-.429-.029-1.518 0-1.632-1.033-.086-.774.687-1.75 1.575-1.635.888 0-.601-.086 0 0zM295.712 45.093c.516-.66 1.003-1.32 1.948-1.204.946.114.917.946.803 1.692-.115.717-.917 1.06-1.605.86-.773-.2-.974-.602-1.146-1.348.172-.258.201.918 0 0zM229.682 86.623c.573.488.544 1.377-.029 1.864-.515.43-1.031.918-1.69.402-.773-.602-.773-1.721-.258-2.18.487-.459 1.404-.574 1.977-.086z"
        fill="#007FC8"
      />
      <path
        d="M255.893 87.684c.573.488.545 1.377-.028 1.864-.516.43-1.032.918-1.69.402-.774-.603-.774-1.721-.258-2.18.458-.459 1.375-.573 1.976-.086zM208.712 76.212c1.633-.029 2.12 2.667.459 2.667-1.748.029-2.378-2.61-.459-2.667z"
        fill="#3BB2F4"
      />
      <path
        d="M201.923 88.487c1.633-.029 2.12 2.667.458 2.667-1.747.029-2.377-2.61-.458-2.667z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M315.736 182.675c-.143-.488-.486-.976-.143-1.434.258-.373.401-.631.888-.66.545-.029 1.089.287 1.289.746.659 1.749-1.776 2.237-2.034 1.348z"
        fill="#fff"
      />
      <path
        d="M202.41 18.305c-.659-.143-1.146-.401-.974-1.175.143-.603.487-1.52 1.232-1.406 1.948.344 1.604 2.581-.258 2.581-.114 0 .974 0 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M223.322 63.65c-.773 2.15-2.836.803-2.578-.144.172-.63.63-1.491 1.404-1.262.687.201 1.117.717 1.174 1.406-.086.258-.086-.717 0 0zM353.493 103.057c-.086-1.692 2.607-1.922 2.75-.315.114 1.634-2.664 1.921-2.75.315z"
        fill="#007FC8"
      />
      <path
        d="M341.318 82.808c.057 1.836-2.865 1.32-2.464-.143.458-1.721 2.292-1.692 2.464.143.028.975 0-.2 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M277.636 38.898c.229-.717.373-1.577 1.203-1.75.688-.143 1.232.259 1.375.918.172.66-.057 1.32-.716 1.578-.773.315-1.317-.258-1.862-.746.115-.373.229.172 0 0z"
        fill="#007FC8"
      />
      <path
        d="M295.111 59.778c0 .717-.201 1.147-.974 1.147-.602 0-1.518-.287-1.461-1.033.057-.63.344-1.663 1.146-1.606.802.086 1.318.66 1.289 1.492-.029.831 0-.03 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M214.757 22.235c1.805 0 1.461 2.409-.229 2.438-.659 0-1.032-.545-1.089-1.148-.086-.889.487-1.262 1.318-1.29zM272.05 79.424c.63.172.745 1.118.544 1.606-.286.66-.945.975-1.804.602-.888-.373-.516-2.667 1.26-2.208zM259.933 120.753c.63.172.744 1.119.544 1.606-.287.66-.945.975-1.805.602-.888-.372-.515-2.667 1.261-2.208zM243.575 103.344c.631.172.745 1.118.545 1.606-.287.659-.946.975-1.805.602-.888-.373-.516-2.667 1.26-2.208zM268.498 89.892c1.69-.086 1.891 2.639.086 2.266-.487-.115-1.232-.315-1.261-.918 0-.889.258-1.319 1.175-1.348z"
        fill="#fff"
      />
      <path
        d="M275.831 125.055c.058.918-.343 1.262-1.031 1.377-1.69.258-2.177-2.266-.086-2.18.401 0 1.06.258 1.117.803zM328.656 167.359c.057.918-.344 1.262-1.031 1.377-1.691.258-2.178-2.266-.086-2.18.372 0 1.06.258 1.117.803zM329.888 179.204c.057.918-.344 1.262-1.031 1.377-1.691.258-2.178-2.266-.086-2.18.401 0 1.088.258 1.117.803z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M222.721 44.892c.057.918-.344 1.262-1.031 1.377-1.691.258-2.178-2.266-.086-2.18.401-.028 1.088.258 1.117.803z"
        fill="#fff"
      />
      <path
        d="M209.973.81c.057.918-.344 1.262-1.032 1.377-1.69.258-2.177-2.266-.085-2.18.401-.029 1.088.258 1.117.803zM299.752 135.466c.057.918-.344 1.262-1.032 1.377-1.69.258-2.177-2.266-.086-2.18.401-.029 1.089.258 1.118.803z"
        fill="#91E7F2"
      />
      <path
        d="M283.48 134.061c.058.917-.343 1.262-1.031 1.376-1.69.258-2.234-2.352-.172-2.237.401-.028 1.146.316 1.203.861z"
        fill="#007FC8"
      />
      <path
        d="M303.533 162.684c.057.918-.344 1.262-1.031 1.377-1.691.258-2.178-2.266-.086-2.18.401-.029 1.06.258 1.117.803zM311.038 154.94c.057.918-.344 1.262-1.031 1.377-1.69.258-2.177-2.266-.086-2.18.401 0 1.089.258 1.117.803z"
        fill="#3BB2F4"
      />
      <path
        d="M273.053 29.261c.086 1.348-2.894 1.492-2.521-.114.372-1.55 2.435-1.578 2.521.114.028.488-.029-.602 0 0zM309.52 62.216c-.086.831-.458 1.147-1.232 1.233-.601.086-1.346-.316-1.289-1.004.057-.746.888-1.463 1.433-1.491.544 0 1.145.573 1.088 1.262z"
        fill="#91E7F2"
      />
      <path
        d="M239.25 20.485c.057 1.32-1.49 2.036-2.263.774-.774-1.204 2.12-2.695 2.263-.774.028.86-.029-.602 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M374.519 114.099c-1.804.43-2.005-2.667-.143-2.581 1.805.086 1.604 2.237.143 2.581z"
        fill="#fff"
      />
      <path
        d="M296.113 151.212c0-1.319 2.006-1.147 2.177 0 .287 1.606-2.177 1.778-2.177 0z"
        fill="#3BB2F4"
      />
      <path
        d="M317.942 160.59c0-1.319 2.005-1.147 2.177 0 .258 1.607-2.206 1.779-2.177 0zM302.817 176.307c0-1.319 2.005-1.147 2.177 0 .286 1.607-2.177 1.779-2.177 0z"
        fill="#007FC8"
      />
      <path
        d="M359.909 192.541c.115-1.033 2.35-1.549 2.292-.029-.057 1.463-2.32 1.692-2.292.029 0-.029 0 .573 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M302.702 182.13c.115-1.033 2.349-1.549 2.292-.029-.057 1.463-2.32 1.692-2.292.029 0-.029 0 .602 0 0z"
        fill="#fff"
      />
      <path
        d="M297.832 185.629c.115-1.033 2.349-1.549 2.292-.029-.058 1.491-2.321 1.692-2.292.029 0 0 0 .602 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M286.116 65.37c.744.344 1.06.46.83 1.32-.143.573-.544.774-1.117.745-.516-.028-1.203-.23-1.117-.86.029-.316.2-.631.458-.86.229-.23.831-.115.946-.345.286.144-.029.058 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M286.717 46.7c-1.375.085-1.088-1.865.029-1.722.63.087.802.46.774 1.062-.029.573-.287.63-.803.66z"
        fill="#fff"
      />
      <path
        d="M57.515 187.063c-1.031.028-2.062-.287-2.922-.832-.573-.373-.917-2.008-1.002-2.696-.144-1.061.114-1.578.802-2.323.515-.545 1.346-1.004 2.206-1.09 2.606-.287 4.268 1.864 4.325 3.356.029 2.122-1.46 3.585-3.409 3.585-1.088.028 1.032 0 0 0zM159.555 105.122c-.631-.43-3.581-4.503.229-6.338 2.607-1.263 4.612 1.462 4.812 2.437.602 2.754-1.031 3.7-2.234 4.159-.573.201-2.063.23-2.807-.258z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M77.568 117.713c-1.748.086-3.753-1.262-3.724-3.184.029-1.663.802-2.897 2.435-3.355 2.062-.603 4.24.688 4.612 2.638.487 2.61-2.263 3.872-3.323 3.901z"
        fill="#fff"
      />
      <path
        d="M103.121 99.099c-.63-.144-1.891-.688-2.263-1.262-.43-.631-1.06-1.692-.974-2.467.114-1.003.659-1.95 1.432-2.58 1.69-1.406 3.81-.259 4.698 1.433 1.432 2.754-1.518 5.22-2.893 4.876z"
        fill="#91E7F2"
      />
      <path
        d="M164.997 55.218c.545-.632 1.404-.947 2.235-.947s3.237.057 3.438 3.47c.057.832-.402 2.237-.946 2.897-.487.602-1.805.574-2.521.545-.888-.029-1.661-.774-2.32-1.348-.315-.258-1.375-2.868.114-4.617z"
        fill="#007FC8"
      />
      <path
        d="M79.688 146.021c-.602.573-1.519.745-2.32.774a3.497 3.497 0 01-2.493-.918c-.745-.717-.888-1.348-1.06-2.323-.114-.832-.086-1.663.573-2.266.573-.516 1.232-1.118 2.063-1.147 5.471-.172 3.924 5.191 3.237 5.88z"
        fill="#3BB2F4"
      />
      <path
        d="M114.236 54.185c-.373-1.147.83-3.212 2.177-3.097.344.028 1.232 0 1.948.516.544.401.974 1.32 1.088 1.979.086.66-.143 1.32-.573 1.807-.515.573-.945.66-1.661.774-1.346-.029-2.607-.746-2.979-1.979z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M194.618 60.294c.086.86-.343 1.606-.945 2.18-.716.717-1.404.63-2.349.459-1.518-.287-3.123-1.779-2.893-3.356.257-1.778 1.117-3.27 3.151-3.155 1.719.086 3.008 2.294 3.036 3.872.115 1.004-.028-.947 0 0z"
        fill="#fff"
      />
      <path
        d="M116.585 121.355c-.659.603-.802.746-1.662.746-.888 0-3.18 0-3.781-2.839-.172-.832.143-1.807.544-2.553.315-.602 1.862-1.176 2.55-1.119 4.755.431 2.893 5.278 2.349 5.765z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M179.206 79.137c-.487.89-1.375.832-2.263.717-1.862-.258-3.437-1.778-2.807-3.757.258-.803.687-1.578 1.375-2.094.745-.545 1.346-.602 2.177-.373.716.201 1.404.545 1.805 1.205.2.258.974 1.95-.287 4.302z"
        fill="#fff"
      />
      <path
        d="M148.44 94.223c-.401 1.664-2.263 2.582-3.839 2.151-1.661-.459-2.779-1.95-2.406-3.757.315-1.549 1.575-2.266 3.094-2.266 3.151 0 3.38 2.954 3.151 3.872z"
        fill="#3BB2F4"
      />
      <path
        d="M141.278 110.256c-2.12-.115-3.695-.918-3.781-3.241-.086-2.094 2.177-2.926 3.924-2.467 1.691.431 2.636 2.323 2.521 3.155-.258 1.549-1.89 2.495-2.664 2.553-.888-.058.888-.058 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M123.861 123.392c-.287-1.951.515-3.786 2.578-3.93 3.094-.2 3.409 2.639 3.466 3.241.115 1.893-1.461 2.868-2.607 2.926-1.174.057-3.208-.689-3.437-2.237z"
        fill="#fff"
      />
      <path
        d="M116.527 148.229c-.372.746-1.06 1.348-1.862 1.52-1.489.344-3.924-.946-3.81-2.696.029-.688 0-3.069 1.891-3.269 4.698-.488 4.154 3.728 3.781 4.445z"
        fill="#3BB2F4"
      />
      <path
        d="M112.746 128.267c1.518.287 3.094 1.893 2.951 3.528-.058.66-.287 1.491-.745 1.979-.201.201-1.547.832-2.177.803-1.519-.143-3.467-2.237-3.123-3.814.115-.603.802-1.521 1.261-1.951.458-.516 1.174-.545 1.833-.545.573.115-1.346-.028 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M98.508 151.269c-1.317-.028-3.122-1.749-3.15-3.069-.03-1.548 1.26-3.068 2.835-3.126 1.748-.057 3.037 1.578 3.152 3.212.143 1.521-1.318 2.955-2.837 2.983-.773-.028.602 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M131.739 119.692c.372-.631 1.375-.688 2.034-.803 1.346-.23 2.349 1.376 2.291 2.581-.057 1.549-1.231 2.381-2.664 2.381-.945 0-2.864-2.065-1.661-4.159zM129.447 99.3c.516-.775 1.375-.918 2.235-1.147 1.403-.373 2.864 1.95 2.95 3.154.115 1.807-1.088 2.668-2.779 2.869-.773.086-1.604-.431-2.119-1.004-.344-.431-1.461-2.094-.287-3.872zM78.255 192.885c-.028 1.491-1.117 2.552-2.578 2.868-.773.143-3.151-.316-3.438-2.581-.2-1.606 1.06-3.499 3.008-3.327 2.722.229 2.808 2.351 3.008 3.04-.028 1.348-.2-.631 0 0z"
        fill="#007FC8"
      />
      <path
        d="M144.945 70.16c.372-.545 1.289-.89 1.919-1.004 1.547-.2 3.151 1.721 3.008 3.155a3.554 3.554 0 01-.945 2.008 3.084 3.084 0 01-.917.688c-.573.287-.917.057-1.518.086-1.604.029-2.979-2.696-1.547-4.933z"
        fill="#3BB2F4"
      />
      <path
        d="M98.738 120.323c2.32-.058 2.779 1.921 2.979 2.696.43 1.52-1.06 2.983-2.52 2.983-1.748.028-2.98-.976-3.21-2.696-.228-1.635 1.032-3.241 2.75-2.983.631-.058-.715-.115 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M118.705 141.059c-1.032-.516-2.149-.889-2.292-2.18-.115-1.118.716-2.266 1.833-2.466 1.06-.201 2.75.774 2.922 1.95.086.573 0 1.577-.372 2.036-.487.602-1.347.545-2.091.66-.43-.201.372-.058 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M88.54 176.365c-1.49.029-2.75-1.262-2.98-2.696-.143-.774.172-1.463.545-2.094.343-.602 1.117-.803 1.776-.946.63-.144 1.317.115 1.862.459 3.18 1.979.172 5.306-1.203 5.277-1.49.029.859 0 0 0z"
        fill="#fff"
      />
      <path
        d="M95.5 106.757c.832.028 1.204-.058 1.805.487.573.545 1.118 1.147 1.375 1.922.401 1.319-1.145 2.782-2.434 2.897a2.744 2.744 0 01-1.805-.488c-.43-.287-1.203-1.09-1.175-1.635.029-.717-.057-1.635.43-2.237.372-.516 1.117-.975 1.805-.946.372 0-.831-.058 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M60.036 147.942c1.834.029 2.779 1.119 3.266 2.782.372 1.319-.974 2.524-2.177 2.553-1.404.028-2.492-.201-2.922-1.721-.401-1.348.086-3.614 1.833-3.614.917.029-.773 0 0 0zM157.177 58.774c1.662-.23 3.123 1.032 3.123 2.553.028 1.921-1.49 2.896-2.321 2.81-1.547-.143-2.836-1.32-2.922-2.954-.086-1.577.917-2.237 2.12-2.41z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M53.419 156.403c1.317-.172 3.38 1.348 2.95 2.925-.372 1.291-1.174 2.438-2.693 2.438-.658 0-1.49-.631-1.976-1.032-.63-.545-1.404-3.958 1.719-4.331z"
        fill="#fff"
      />
      <path
        d="M94.269 139.281c-.23-1.406.888-2.725 2.263-2.897 1.432-.172 3.008.774 2.922 2.38-.029.574-.143 2.467-2.206 2.811-2.062.344-2.779-1.032-2.979-2.294z"
        fill="#007FC8"
      />
      <path
        d="M123.288 115.562c-3.208.172-3.495-5.163-.143-5.277 1.403-.058 2.635 1.204 2.635 2.581 0 .602-.143 1.577-.487 2.094-.372.573-1.375.516-2.005.602-1.089.057.315-.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M102.347 162.254c.115.717.258 1.434-.315 1.922-.687.602-3.266 1.778-4.898-1.176-.688-1.234.257-3.385 1.919-3.155 1.375 0 3.036.946 3.294 2.409zM84.959 145.046c-.058-1.32.802-3.127 2.32-2.983 1.432.143 3.18 1.29 3.123 2.896-.029.976-1.49 2.553-2.521 2.324-1.26-.287-2.836-.66-2.922-2.237-.086-1.578.028.602 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M42.934 143.497c-1.174-.058-2.578-.947-2.836-2.151-.286-1.32.716-3.041 2.206-2.897 1.575.143 3.352.975 3.151 2.811-.143 1.434-1.146 2.093-2.52 2.237-.889-.058 2.004-.172 0 0z"
        fill="#fff"
      />
      <path
        d="M39.267 159.931c2.149-.287 3.037 1.032 3.323 2.036.43 1.463-1.002 3.069-2.492 2.84-1.26-.173-2.378-1.062-2.635-2.352-.23-1.291.458-2.524 1.804-2.524 1.06-.23-.83 0 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M106.272 156.403c1.604-.43 2.951 1.033 3.008 2.553.057 1.606-1.203 2.897-2.836 2.466-.63-.172-2.034-.717-2.034-2.638 0-.058-.029-1.865 1.862-2.381z"
        fill="#fff"
      />
      <path
        d="M102.003 168.879c1.347-.143 2.722.746 3.18 2.008.459 1.233-.487 2.638-1.719 2.925-.802.201-3.151-.602-3.409-1.979-.257-1.376.344-2.782 1.948-2.954z"
        fill="#3BB2F4"
      />
      <path
        d="M33.337 188.497c1.146-.258 2.894 1.434 2.78 2.524-.43 4.044-5.3 2.782-5.129.028a3.272 3.272 0 01.602-1.692c.487-.688.945-.774 1.747-.86.774-.172-.315.028 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M126.382 79.969c-.43-1.377.601-2.897 1.977-2.81.687.057 1.145-.144 1.747.372.63.516 1.117 1.262 1.174 2.065.058.574-.229 1.176-.515 1.692-.487.832-3.466 1.578-4.383-1.319z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M155.401 49.969c-.229-1.348 1.404-2.868 2.693-2.668 1.317.23 2.463 1.406 2.263 2.811-.115.688-.974 2.237-2.263 2.237-1.032 0-2.435-.86-2.693-2.38z"
        fill="#fff"
      />
      <path
        d="M126.41 51.976c-1.26-.487-2.119-1.692-2.062-3.069 0-.573.773-1.376 1.26-1.577 3.18-1.204 3.925 1.72 3.81 2.782-.229 1.893-2.521 2.065-3.008 1.864z"
        fill="#007FC8"
      />
      <path
        d="M138.413 57.57c-1.375-.03-2.119-1.205-2.463-2.353-.172-.63-.086-1.233.286-1.778.287-.401.946-1.176 1.49-1.204 1.146-.03 2.091.831 2.435 1.835.2.631.544 1.434.2 2.065-.343.66-1.289 1.205-1.948 1.434-1.002 0 .115-.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M134.432 71.307c1.317-.114 2.578.918 2.606 2.209.029 1.72-1.489 2.237-1.718 2.352-1.49.717-2.75-.545-2.836-2.066-.144-1.348.659-2.38 1.948-2.495zM168.693 74.806c2.521-.2 3.781 3.844 1.346 4.876-1.231.516-4.268-.975-3.036-3.9.315-.746 1.002-.86 1.69-.976 1.203-.114-.258.03 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M195.592 42.913c.258 2.496-3.781 3.872-4.841 1.434-.544-1.233.745-2.896 2.148-3.183 1.691-.373 2.579 1.032 2.693 1.75.143 1.175-.028-.287 0 0z"
        fill="#007FC8"
      />
      <path
        d="M189.233 91.929c-1.318.459-2.693-.603-3.066-1.836-.429-1.29.888-2.552 2.063-2.724.573-.087 1.375-.23 1.833.258.459.487.831 1.032 1.003 1.692.258 1.061-.859 2.266-1.833 2.61z"
        fill="#91E7F2"
      />
      <path
        d="M83.755 105.696c-.085-3.471 4.956-2.897 4.727.2-.086 1.205-1.26 1.807-2.291 2.123-1.175.315-2.521-1.205-2.436-2.323-.028-.717-.057.631 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M80.347 129.013c-.2-.201-1.032-2.065.573-3.184 1.117-.774 2.291-.114 3.18.631 1.203.975.057 3.012-.516 3.442-.545.401-2.407.029-3.237-.889zM158.552 76.556c.172 1.176-.687 2.524-2.206 2.524-1.174 0-2.091-.545-2.32-1.922-.258-1.52.601-2.61 1.919-2.61 1.547-.057 2.407.488 2.607 2.008z"
        fill="#fff"
      />
      <path
        d="M34.712 179.204c1.032.086 2.063.488 2.178 1.664.114 1.319-.573 2.896-2.034 2.925-1.519.029-2.435-1.204-2.636-2.581-.143-1.147 1.547-2.036 2.492-2.008.144 0-.658 0 0 0zM189.806 95.8c.028-1.147.916-2.294 2.119-2.351.717-.029 1.433.545 2.006.889.601.344.487 1.434.343 2.008-.286 1.118-1.117 2.15-2.349 1.864-1.088-.287-2.205-1.233-2.119-2.41.028-1.233-.115 1.463 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M80.375 179.692c-1.06 0-2.492-.689-2.377-2.409.057-1.32 1.203-2.381 2.55-2.123 1.231.23 2.234 1.234 2.234 2.467-.029 1.29-1.347 2.065-2.407 2.065z"
        fill="#fff"
      />
      <path
        d="M105.498 147.11c-.83 0-1.948-.889-1.776-1.778.144-.832.802-1.893 1.748-1.893.974 0 1.804 1.061 1.833 2.008.029.86-1.003 1.635-1.805 1.663zM116.012 107.187c2.95.086 2.435 4.962-.401 4.388-1.175-.229-2.206-.975-2.263-2.266-.029-1.376 1.461-2.122 2.664-2.122 1.232.029-1.519 0 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M147.409 113.956c.057 1.29-1.175 2.007-2.378 1.806-.573-.114-1.175-.516-1.633-.831-.258-.201-1.318-3.012 1.719-3.442 1.174-.143 2.292 1.176 2.292 2.467.057.803 0-.66 0 0z"
        fill="#fff"
      />
      <path
        d="M152.393 86.365c1.089-.172 2.349.803 2.492 1.864.143.975-.544 2.667-1.719 2.725-1.145.057-2.606-.86-2.778-2.037a2.532 2.532 0 01.401-1.75c.429-.63.916-.659 1.604-.802.945-.144-.372.086 0 0z"
        fill="#007FC8"
      />
      <path
        d="M134.861 89.95c.258 1.405-1.547 2.552-2.12 2.409-1.174-.287-2.205-.918-2.435-2.18-.143-.803.774-2.409 1.748-2.409 1.318.029 2.893.66 2.807 2.18-.028.401.058-.746 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M70.349 150.265c-1.203-.028-2.406-.545-2.52-1.663-.173-1.893.6-3.069 2.033-2.897 3.982.488 2.063 4.56.487 4.56zM182.73 82.55c.573.058 1.174.89 1.49 1.406.171.286.057 1.06 0 1.376-.058.603-.516 1.09-.946 1.52-1.174 1.09-2.492.201-3.151-.918-.687-1.118-.114-3.7 2.607-3.384z"
        fill="#fff"
      />
      <path
        d="M113.434 86.823c-1.146.144-2.636-1.06-2.521-2.351.114-1.348 1.031-2.467 2.578-2.151 1.948.401 2.034 4.216-.057 4.502z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M111.657 70.848c-.343-1.29.316-2.724 1.748-2.81 3.151-.201 3.294 4.36.2 4.388-.974 0-1.632-.402-1.948-1.578z"
        fill="#fff"
      />
      <path
        d="M95.615 102.024c-.487-.086-1.06-.43-1.403-.717-.516-.458-.717-1.175-.66-1.835.115-1.147 1.09-2.41 2.264-1.98 3.838 1.435 1.403 4.791-.2 4.532z"
        fill="#007FC8"
      />
      <path
        d="M144.143 64.768c.086 1.061-.716 2.151-1.862 2.008-.688-.086-2.407-.316-2.493-2.266-.085-2.61 4.355-2.81 4.355.258.114 1.348 0-.63 0 0zM71.18 155.141c1.203.057 2.091.975 2.263 2.151.401 2.553-2.636 2.553-3.638 1.492-.487-.517-.745-1.033-.831-1.607-.2-1.204 1.117-2.007 2.206-2.036 1.403.086-.115 0 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M84.959 159.615c-.029 2.811-3.724 2.18-4.125 1.148-.172-.431-.401-1.377-.401-1.836.028-1.176 1.403-1.836 2.463-1.75 1.232.086 2.034 1.262 2.063 2.438-.115.402-.058-1.577 0 0zM82.982 198.65c-.028 2.81-3.724 2.179-4.125 1.147-.172-.43-.401-1.377-.401-1.836.029-1.176 1.404-1.835 2.463-1.749 1.232.086 2.006 1.262 2.063 2.438-.143.401-.057-1.578 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M192.04 81.346c.057.573-.057 1.204-.458 1.663-.287.316-.917.975-1.375.89-.573-.115-1.175-.574-1.576-1.005-.43-.487-1.404-3.097 1.461-3.67 1.117-.259 1.862 1.032 1.948 2.122z"
        fill="#91E7F2"
      />
      <path
        d="M87.394 158.755c-1.06-.717-1.347-1.807-.716-2.954.744-1.348 2.12-1.061 3.208-.316 1.29.861.745 2.725.2 3.27-.544.545-1.776.602-2.692 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M101.517 187.837c.257 1.176-.344 2.467-1.891 2.897-.774.201-2.836.172-2.177-2.725.716-3.04 3.752-1.549 4.068-.172z"
        fill="#fff"
      />
      <path
        d="M82.782 132.799c1.03-.029 2.09.717 2.406 1.721.315.975-.573 2.466-1.662 2.351-1.145-.114-2.435-.372-2.75-1.663-.286-1.147.86-2.495 2.006-2.409 1.174-.029-.802-.058 0 0zM65.909 136.097c-.459-1.004-.029-2.352 1.031-2.753 2.779-1.119 4.183 3.068 1.69 4.072-1.403.545-2.234-.258-2.721-1.319z"
        fill="#91E7F2"
      />
      <path
        d="M49.065 179.72c.028.947-1.146 1.865-2.006 1.893-1.346.029-2.463-1.233-2.32-2.581.029-.344.487-.918.687-1.205.287-.458.831-.516 1.347-.487 1.117.057 2.32 1.262 2.291 2.38.03.574.03-.917 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M76.336 85.103c-.2-2.81 3.696-2.725 4.383-.373.315 1.032-.945 2.352-1.976 2.323-1.06 0-2.149-.946-2.407-1.95-.086-1.176.029.086 0 0z"
        fill="#fff"
      />
      <path
        d="M95.042 174.902c-.171-1.176 1.232-2.036 2.235-1.778 1.088.258 2.349 1.52 1.948 2.753-.258.746-1.432 1.664-2.235 1.492-1.46-.287-1.833-1.119-1.948-2.467-.086-.602.086 1.004 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M92.378 153.219c-1.088.201-2.349-.774-2.52-1.95-.144-1.176 1.231-2.266 2.062-2.208 3.037.287 2.292 3.843.458 4.158zM145.948 44.29c-.086-2.954 4.927-1.807 4.182.631-.172.574-.716 1.119-1.117 1.578-.287.315-1.089.086-1.432 0-1.032-.316-1.547-1.176-1.633-2.209-.029-1.004.143 1.635 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M108.821 127.235c-.085 1.118-1.117 2.208-2.291 2.122-.631-.057-1.146-.401-1.576-.832-1.088-1.204.516-3.183 1.633-3.241 1.26-.057 2.177.66 2.234 1.951-.057.889-.028-1.004 0 0z"
        fill="#007FC8"
      />
      <path
        d="M185.451 59.778c-.257 1.147-.859 1.835-2.148 1.778-1.089-.029-1.719-.947-1.748-1.98-.028-1.319.774-2.265 1.977-2.265 1.031 0 2.234 1.147 1.919 2.467z"
        fill="#3BB2F4"
      />
      <path
        d="M62.815 168.621c-1.174.516-2.206-.573-2.664-1.52-.573-1.147.487-2.552 1.633-2.495 3.409.115 1.948 3.614 1.031 4.015z"
        fill="#91E7F2"
      />
      <path
        d="M177.745 93.449c-1.002-.058-1.862-1.033-1.948-2.008-.114-1.176.43-2.41 2.349-2.266 1.92.115 2.12 4.388-.401 4.274z"
        fill="#3BB2F4"
      />
      <path
        d="M111.142 152.216c.057 2.437-4.04 3.04-4.068.315 0-2.581 4.01-3.04 4.068-.315z"
        fill="#007FC8"
      />
      <path
        d="M182.959 102.77c-.945.029-1.862-1.032-1.891-1.979-.057-1.176 1.089-2.008 2.206-2.008 1.031 0 1.977.89 1.919 1.951-.085 1.262-1.088 2.007-2.234 2.036z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M50.01 150.036c-2.55-.057-2.063-4.818.344-4.101.974.286 2.09 1.348 1.862 2.437-.2.918-1.29 1.721-2.206 1.664-.716-.029.888.057 0 0zM114.694 100.762c-.029-1.118.63-2.15 1.776-2.265.774-.058 2.12.774 2.32 1.577.23.947-.544 2.438-1.518 2.438-1.375 0-2.521-.459-2.578-1.75z"
        fill="#fff"
      />
      <path
        d="M150.445 103.143c.029-1.061 1.089-2.208 2.063-2.036.859.143 2.062.975 1.862 1.893-.631 3.355-3.954 1.806-3.925.143z"
        fill="#3BB2F4"
      />
      <path
        d="M88.053 132.512c-.201-1.033.229-1.778.83-2.122.946-.545 2.607-.402 2.98.917.171.603-.201 2.295-.946 2.524-.974.344-2.607.086-2.864-1.319zM91.118 198.879c-.2-1.032.229-1.778.83-2.122.946-.545 2.607-.402 2.98.918.172.602-.2 2.294-.946 2.524-1.002.372-2.606.114-2.864-1.32z"
        fill="#007FC8"
      />
      <path
        d="M89.342 86.164c-2.32.057-2.865-4.102-.23-3.987 2.493.086 2.865 3.93.23 3.987-.63 0 .916-.029 0 0zM45.913 197.99c-.945.058-1.718-1.577-1.546-2.352.229-1.089 1.604-1.864 2.635-1.462.917.373 1.805 1.663 1.26 2.61-.63 1.09-1.203 1.118-2.349 1.204-.687.058 1.26-.086 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M73.873 171.03c.945-.029 2.12.574 2.205 1.635.086 1.262-.601 2.266-1.862 2.151-1.031-.086-1.89-.66-2.034-1.75-.143-1.118.487-2.093 1.69-2.036 1.175-.057-1.088-.057 0 0z"
        fill="#fff"
      />
      <path
        d="M87.165 184.94c-2.665.058-2.378-4.216-.058-4.13.945.029 1.89.947 1.948 1.893.029 1.32-.802 1.893-1.89 2.237-.66.029.229-.057 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M154.943 106.585c.2-.258 1.346-.431 1.69-.459 1.146-.086 1.747.918 1.805 1.95.057.889-.688 1.979-1.633 2.036-.516.029-1.146-.43-1.547-.688-.287-.172-1.289-1.606-.315-2.839zM132.942 66.92c.114.86-.831 2.466-1.862 2.35-.63-.056-2.034.173-2.063-2.093-.028-2.266 3.667-2.438 3.925-.258z"
        fill="#007FC8"
      />
      <path
        d="M9.303 134.462c-.057-2.323 3.323-3.097 3.838-.631.23 1.119-.229 1.836-1.289 2.295-1.26.573-2.062-.631-2.55-1.664-.028-1.032.03.058 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M73.73 181.986c-.832 2.123-4.212 1.377-3.754-1.032.459-2.438 4.87-1.864 3.753 1.032z"
        fill="#fff"
      />
      <path
        d="M119.936 91.871c1.06.029 2.235.689 2.206 1.779 0 .516-.573 2.18-1.289 2.18-.888 0-2.12-.402-2.435-1.291-.43-1.32.258-2.323 1.518-2.668.745.029-.2.058 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M90.774 191.91c-.028-.975 1.347-2.008 2.263-1.807 2.521.574 1.347 4.044-.2 3.958-1.547-.115-2.034-1.061-2.063-2.151z"
        fill="#fff"
      />
      <path
        d="M186.454 71.307c-1.146.201-1.805.115-2.578-.803-.688-.831-.631-2.552.458-2.782 3.896-.917 2.951 3.442 2.12 3.585z"
        fill="#007FC8"
      />
      <path
        d="M50.583 163.286c.86.975 1.146 1.836.057 2.754-.745.631-1.833.918-2.52.028-.316-.372-.46-.917-.602-1.376-.172-.689.057-1.291.83-1.692.745-.373 1.49-.545 2.235.286z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M154.312 37.063c.201 1.147-.487 1.75-1.489 2.093-1.003.344-1.92-.114-2.292-1.118-.487-1.377.544-2.668 1.575-2.84 1.06-.114 2.006.717 2.206 1.865zM122.171 103.43c-.029-.918.745-1.893 1.604-2.151 1.06-.316 2.063.803 2.206 1.778.114.803-.716 2.352-1.604 2.266-1.146-.086-1.92-.775-2.206-1.893-.029-1.033.143.602 0 0z"
        fill="#fff"
      />
      <path
        d="M85.847 97.75c-.287-.917.487-1.806 1.318-2.064 1.06-.344 1.632.143 2.263.975.888 1.147-.23 2.868-1.29 2.897-1.03 0-1.948-.717-2.291-1.807z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M100.772 131.422c.028 2.352-3.524 2.237-3.868.086-.4-2.466 3.81-2.724 3.868-.086z"
        fill="#fff"
      />
      <path
        d="M122.572 62.072c-1.117-.315-1.576-.488-1.977-1.692-.258-.803.545-1.95 1.375-2.151 1.089-.258 2.12.688 2.321 1.692a2 2 0 01-.258 1.463c-.315.66-.86.63-1.461.688-.373-.086.344-.029 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M150.388 56.422c.143 1.09-.659 2.008-1.748 2.065-1.031.057-2.034-1.032-2.034-2.036 0-.918 1.06-2.065 2.063-1.836 1.003.2 1.69.832 1.719 1.807.172 1.233 0-.832 0 0zM134.26 85.218c-.745-.631-.717-2.037-.115-2.725.516-.602 1.547-.832 2.292-.602 1.232.344 1.174 2.294.773 3.097-.401.775-2.034 1.033-2.95.23zM172.847 83.812c.487-.918 1.575-1.348 2.549-.946.86.344 1.003 2.093.688 2.868-.544 1.29-2.091 1.147-2.664.774-.602-.401-1.06-1.778-.573-2.696zM106.702 137.187c.057.803-1.003 1.893-1.805 1.807-.63-.058-1.919-.201-1.891-1.864 0-2.18 3.524-2.237 3.696.057z"
        fill="#007FC8"
      />
      <path
        d="M182.902 47.99c-.974.029-1.977-.574-2.149-1.578-.172-.917.43-2.18 1.433-2.294 1.06-.115 1.776.688 2.119 1.606.459 1.147-.372 1.979-1.403 2.266-1.031.029.143-.029 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M86.105 37.091c-.401-.287-.516-.745-.373-1.204.373-1.062 1.26-1.635 2.35-1.32.544.144 1.26.717 1.26 1.578 0 .688-1.175 2.495-3.237.946z"
        fill="#fff"
      />
      <path
        d="M71.638 98.44a2.154 2.154 0 011.862 2.093c.086 2.667-3.982 1.95-3.61-.086 0-1.033.63-2.151 1.748-2.008z"
        fill="#007FC8"
      />
      <path
        d="M91.891 185.198c.172-.946 1.547-1.29 2.407-.975.916.345 1.661 1.52 1.117 2.467-.258.459-.745.889-1.232 1.118-.487.23-2.693-.458-2.292-2.61z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M60.18 106.269c.973-.114 1.919.861 2.12 1.75.2.918-.774 2.38-1.806 2.036-.859-.287-1.804-.918-1.947-1.893a1.638 1.638 0 011.632-1.893c.974-.114-1.174 0 0 0z"
        fill="#fff"
      />
      <path
        d="M48.463 131.049c0-2.724 4.068-1.835 3.61.517-.459 2.409-3.61 1.749-3.61-.517z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M167.948 102.34c1.06-.23 2.034.373 2.292 1.463.2.831-.63 2.409-1.633 2.265-2.32-.401-2.893-3.241-.659-3.728zM122.572 86.537c-.229-2.495 3.724-2.294 3.781.086 0 .516-.372 1.233-.83 1.463-1.805.889-2.865-.574-2.951-1.549-.115-1.262.086 1.176 0 0z"
        fill="#fff"
      />
      <path
        d="M92.78 157.608c.859-1.033 1.919-1.004 2.835 0 .573.631.717 1.491.23 2.208-.745 1.147-2.12 1.061-2.836.23-.401-.488-1.089-1.435-.23-2.438z"
        fill="#3BB2F4"
      />
      <path
        d="M162.276 84.357c-.773-.373-1.547-1.549-1.203-2.41.458-1.118 1.576-1.376 2.578-.946 1.175.488 1.461 1.578 1.003 2.639-.43 1.032-1.318 1.233-2.378.717z"
        fill="#91E7F2"
      />
      <path
        d="M74.703 127.263c-1.146.144-1.976-.946-2.206-1.979-.229-1.09.946-1.778 1.891-1.692 2.034.23 2.435 3.442.315 3.671-.916.115.172 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M180.238 33.42c0 2.553-3.925 2.036-3.61-.43.115-.89.974-1.692 1.862-1.721.774 0 1.862 1.405 1.748 2.151 0 1.233.085-.574 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M60.638 119.52c1.06.028 2.062.889 2.034 2.007-.029.775-.974 1.951-1.834 1.721-.659-.172-1.948-.975-1.92-1.778.058-.975.631-1.979 1.72-1.95z"
        fill="#007FC8"
      />
      <path
        d="M58.976 131.91c.23-1.033.688-2.352 2.034-2.037 2.091.488 2.35 3.299-.172 3.586-.888.086-2.034-.803-1.862-1.549z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M194.189 92.043c-.487-.688-.631-1.778.257-2.265 1.089-.603 1.891-.373 2.636.544.802 1.004.286 2.324-.459 2.668-.716.344-1.69.143-2.434-.947z"
        fill="#fff"
      />
      <path
        d="M166.115 87.11c0-1.003.286-1.462 1.26-1.692.917-.23 1.518-.057 2.091.689.717.946.373 1.864-.544 2.552-1.003.775-2.75-.315-2.807-1.549 0-1.09.028.517 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M59.922 140.342c.057-1.032.802-1.692 1.833-1.749.974-.058 1.719 1.348 1.747 2.237 0 1.778-3.724 2.036-3.58-.488z"
        fill="#007FC8"
      />
      <path
        d="M148.612 79.654c1.031.315 2.205.688 1.804 2.036-.286.946-1.031 1.52-1.976 1.692-.946.172-1.461-1.061-1.518-1.778-.115-1.119.601-1.893 1.69-1.95.343.114-.573.028 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M188.001 50.657c-.086-2.524 3.924-2.581 3.638-.057-.057.459-.315 1.72-1.518 1.893-1.805.23-2.12-.86-2.12-1.836 0-1.32.172.287 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M175.625 66.145c2.292-.115 2.636 3.069.545 3.7-1.003.286-1.948-.89-2.206-1.75-.401-1.176.802-1.606 1.661-1.95.917-.029-.343.143 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M130.965 113.755c2.263-.488 3.037 3.671.545 3.671-1.605.029-2.894-3.155-.545-3.671z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M178.605 97.091c.945.89 1.002 1.893.143 2.868-.773.889-1.891.316-2.55-.43-.744-.832-.429-2.208.201-2.696.602-.487 1.661-.23 2.206.258z"
        fill="#fff"
      />
      <path
        d="M102.261 105.552c0-.86.573-1.979 1.576-1.864 1.232.143 1.977.631 2.034 1.864.086 2.266-3.61 2.209-3.61 0zM197.741 80.944c2.492-.2 2.635 3.786.028 3.528-1.06-.115-1.776-1.29-1.518-2.294.201-.918.516-1.234 1.49-1.234.802-.057-1.089 0 0 0z"
        fill="#007FC8"
      />
      <path
        d="M64.849 196.04c.114-2.094 2.55-2.438 3.265-.459.43 1.147.115 2.122-1.145 2.266-1.146.086-2.178-.545-2.12-1.807z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M41.931 188.554c.03-.803.86-1.749 1.69-1.778 1.003-.057 1.72.889 1.92 1.807.2.803-1.29 2.151-2.034 2.007-.83-.172-1.604-1.175-1.576-2.036zM85.073 191.537c-2.291.029-2.263-3.499-.143-3.643.745-.057 1.92.689 2.005 1.492.115 1.233-.86 1.835-1.862 2.151-.945 0 .144-.057 0 0z"
        fill="#fff"
      />
      <path
        d="M54.45 200.227c-2.292.029-2.263-3.499-.143-3.642.745-.058 1.92.688 2.005 1.491.115 1.262-.888 1.836-1.862 2.151-.974.029.115-.029 0 0z"
        fill="#007FC8"
      />
      <path
        d="M100.886 156.862c-.716-.172-1.718-.803-1.604-1.635.086-.717.774-2.065 1.662-1.979 2.578.23 2.521 3.585-.058 3.614-.487-.115 1.175 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M90.889 119.778c-.688.172-1.347-.086-1.977-.344-.573-.23-.83-1.693-.573-2.151.487-.832 1.604-1.119 2.435-.804 2.32.976.573 3.184.115 3.299z"
        fill="#007FC8"
      />
      <path
        d="M55.138 138.994c.086 2.294-3.266 1.749-3.524-.23-.344-2.495 3.696-2.151 3.524.23.028.602.028-.545 0 0zM149.757 105.81c.631.574.946 1.893.316 2.581-.459.488-2.206.89-2.607.172-.172-.286-1.175-1.72-.229-2.638.916-.918 1.718-.832 2.52-.115z"
        fill="#3BB2F4"
      />
      <path
        d="M77.339 97.063c0-1.062 1.031-1.98 2.005-1.75 1.003.23 1.69.975 1.49 2.065-.459 2.151-3.466 1.492-3.495-.315z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M157.005 93.162c-.172-2.266 4.125-1.807 3.409.66-.315 1.06-.888 1.577-2.034 1.29-.974-.2-1.317-1.06-1.375-1.95z"
        fill="#fff"
      />
      <path
        d="M110.913 141.575c-1.175-.373-1.891-.803-1.777-2.094.086-.889 1.118-1.634 1.977-1.405 1.003.287 1.432.947 1.432 1.979.258.717-.859 1.807-1.632 1.52z"
        fill="#007FC8"
      />
      <path
        d="M63.617 160.505c-1.92-.574-1.92-3.786.401-3.471.917.115 1.776.861 1.776 1.836 0 1.29-1.146 1.434-2.177 1.635-.086-.029.401-.086 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M32.85 141.374c-.802.517-1.747-1.176-1.718-1.807.028-.975.83-1.749 1.776-1.864.745-.086 1.633 1.262 1.661 1.922 0 1.061-.63 1.835-1.719 1.749-.43.258.717.058 0 0z"
        fill="#fff"
      />
      <path
        d="M167.06 94.338c.802-.115 1.49.66 1.776 1.348.287.774-.172 1.836-.945 2.151-1.089.43-2.292.086-2.636-1.635-.229-.975.888-1.75 1.805-1.864zM143.341 28.802c2.205.087 2.864 2.725.687 3.528-2.034.746-3.094-3.642-.687-3.527z"
        fill="#007FC8"
      />
      <path
        d="M98.509 73.888c-.63-.831-1.375-1.52-.917-2.61.401-.946 1.346-1.09 2.206-.717 1.031.46 1.203 1.406.802 2.381-.458.975-1.117.947-2.091.947-.316-.402.114 0 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M86.305 74.921c0-.889.573-1.463 1.432-1.635.831-.143 1.69.746 1.949 1.463.343.947-.545 1.864-1.433 2.094-1.031.287-1.948-.947-1.948-1.922z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M69.633 120.265c-.917.144-1.604-.86-1.776-1.663-.2-.918.716-1.864 1.575-2.037 1.662-.286 2.607 3.872.2 3.7-.486.058 1.032.058 0 0zM121.799 130.275c.859-.545 1.804.315 2.033 1.348.23 1.147-.429 2.352-1.546 2.294-1.118-.057-3.094-2.036-.487-3.642zM37.176 173.468c.029-2.065 2.95-1.663 3.352.029.229.946-.602 1.864-1.519 2.007-1.088.23-1.862-1.032-1.833-2.036 0-.832-.029.717 0 0z"
        fill="#fff"
      />
      <path
        d="M100.313 87.856c0 .287 0 .602-.229.803-.258.2-.4.172-.63.43-.172.172-.401.287-.63.258-.802-.057-1.977-.86-1.948-1.72.057-2.553 3.437-2.18 3.437.229 0 .373 0-1.606 0 0z"
        fill="#007FC8"
      />
      <path
        d="M153.969 71.88c-.888 0-1.748-.544-1.977-1.405-.201-.774.315-2.065 1.203-2.15 1.003-.115 2.177.716 2.177 1.778 0 1.147-1.002 1.663-1.403 1.778-1.06 0 .086 0 0 0zM68.029 89.606c.916-.115 2.09.831 2.09 1.75 0 .946-.286 1.462-1.718 1.663-1.604.2-2.578-3.127-.372-3.413z"
        fill="#91E7F2"
      />
      <path
        d="M38.207 150.466c-.143-.803.716-1.807 1.519-1.749.974.086 1.604.946 1.833 1.864.516 1.95-3.294 2.323-3.352-.115-.114-.774.029.689 0 0zM101.603 117.77c-.774 0-1.948-.659-1.948-1.549 0-.889.802-1.921 1.747-1.95 1.833-.029 2.464 3.471.201 3.499z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M138.471 98.325c.687-.66 1.747-.345 2.32.258.774.831.688 1.749-.172 2.495-.687.602-1.833.43-2.291.115-.43-.345-.573-2.18.143-2.868zM149.013 63.076c.028-2.208 3.18-2.323 3.437-.23.258 2.266-3.466 2.352-3.437.23z"
        fill="#fff"
      />
      <path
        d="M164.998 31.728c-.029.86-.315 1.606-1.232 1.72-.774.115-1.719-.257-2.12-.974-.401-.689.716-2.238 1.461-2.295 1.003-.057 1.891.373 1.891 1.549z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M104.553 84.185c-.687 1.147-1.604.63-2.349-.057-.659-.603-.802-1.32-.315-2.094.573-.918 1.776-1.061 2.492-.516.745.545.745 1.72.172 2.667z"
        fill="#fff"
      />
      <path
        d="M75.534 162.34c.057-1.004.945-1.979 1.92-1.692.859.258 1.776 1.463 1.375 2.323-.86 1.95-3.38.946-3.295-.631z"
        fill="#91E7F2"
      />
      <path
        d="M166.086 46.814c-.114-.832.573-1.75 1.432-1.778.774-.058 1.805.746 1.92 1.491.229 1.434-1.203 1.922-1.748 1.922-.487-.029-1.489-.774-1.604-1.635z"
        fill="#3BB2F4"
      />
      <path
        d="M118.074 127.378c-.429-.172-.802-.344-.974-.746-.401-.917-.315-2.007.688-2.409.63-.258 1.661-.057 1.976.574 1.29 2.323-.916 2.868-1.69 2.581z"
        fill="#91E7F2"
      />
      <path
        d="M139.903 78.305c-.029-.917.315-1.835 1.432-1.778.917.058 1.805.717 1.777 1.692-.029 2.151-3.238 2.094-3.209.086-.029-.66-.029.803 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M112.574 90.523c2.034-.372 2.177 3.729-.057 3.327-.63-.114-1.719-.774-1.719-1.52 0-.946.831-1.806 1.776-1.806.745-.144-.945 0 0 0zM106.501 58.63c.114-2.294 2.807-1.95 3.409-.057.258.86-.802 1.75-1.576 1.807-1.088.086-1.604-.832-1.833-1.75.029-.86 0 .03 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M127.47 108.764c.172-.803.974-1.778 1.92-1.405.888.373 1.547 1.147 1.289 2.151-.602 2.151-3.237 1.119-3.209-.746.115-.545 0 1.262 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M56.14 170.858c.029.861-.802 1.291-1.575 1.32-.974.028-1.662-1.148-1.72-2.008-.056-.975 1.118-1.549 1.949-1.319.917.258 1.346 1.176 1.346 2.007z"
        fill="#007FC8"
      />
      <path
        d="M136.752 15.925c.029 2.094-3.151 1.72-3.38-.144-.287-2.15 3.351-2.122 3.38.144zM125.809 129.615c-.029-.631.515-1.577 1.146-1.692.888-.143 2.12.545 2.32 1.463.258 1.061-.831 1.835-1.747 1.807-.888 0-1.662-.574-1.719-1.578z"
        fill="#91E7F2"
      />
      <path
        d="M58.317 192.799c-.286-.832.43-1.893 1.29-1.979.973-.086 1.718.201 1.947 1.205.258 1.147-.257 1.835-1.117 2.065-.86.229-1.862-.517-2.12-1.291zM104.725 166.413c-.745-.517-.974-1.893-.229-2.524.802-.689 2.234-1.061 2.693-.172 1.432 2.61-1.404 3.441-2.464 2.696z"
        fill="#007FC8"
      />
      <path
        d="M111.027 101.623c.115.717-.229 1.663-.888 1.979-.773.373-1.919-.373-2.177-1.119-.372-1.032.401-2.151 1.06-2.323.659-.143 1.833.43 2.005 1.463z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M61.669 178.975c-2.063.086-1.776-3.471.43-3.27.916.086 1.575.574 1.46 1.922-.114 1.405-1.346 1.348-1.89 1.348-.745.029.888-.029 0 0z"
        fill="#fff"
      />
      <path
        d="M120.509 80.743c-.888-.172-1.69-1.147-1.461-2.065.229-.889 1.433-1.491 2.263-1.233.659.2 1.089 1.119 1.06 1.778 0 .66-.716 1.75-1.862 1.52z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M138.328 41.737c-.029.803-.373 1.55-1.232 1.721-.831.172-1.49-.602-1.862-1.29-.459-.918.487-2.008 1.403-2.008 1.204 0 1.433.574 1.691 1.577-.058 1.234-.029-.057 0 0z"
        fill="#fff"
      />
      <path
        d="M99.168 62.588c.716-.516 1.833.029 2.205.832.545 1.147-.315 2.151-1.146 2.295-.802.172-3.208-1.607-1.06-3.127z"
        fill="#007FC8"
      />
      <path
        d="M185.537 37.034c.143-.775 1.146-1.348 1.891-1.205.831.144 1.833 1.033 1.547 1.836-.974 2.868-3.782 1.32-3.438-.631z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M94.842 166.9c.888.144 1.69 1.147 1.432 2.037-.2.774-1.06 1.405-1.862 1.261-.916-.143-1.518-1.204-1.404-2.036.172-.832.917-1.405 1.834-1.262z"
        fill="#fff"
      />
      <path
        d="M151.82 21.948c-.029-2.037 3.123-2.38 3.151-.23 0 .89-.659 1.98-1.604 1.836-1.06-.144-1.232-.66-1.547-1.606-.029-.975.057.143 0 0zM39.267 125.514c0-.975.716-1.492 1.662-1.377.4.057.773.23 1.002.574.201.287.66.831.688 1.176.057.86-1.089 1.663-1.89 1.577-.545-.086-1.634-1.377-1.462-1.95 0-.775-.172.516 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M98.222 56.852c-.659-.143-1.747-.831-1.718-1.606.028-.889.887-1.635 1.747-1.635 1.031 0 1.719.89 1.432 1.836-.286.975-.372 1.434-1.46 1.405-.03 0 .887.029 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M139.13 119.806c-.717-.602-.774-1.52-.373-2.007.487-.603 1.375-.918 2.12-.545.774.401.945 1.52.774 2.122-.172.603-1.547 1.234-2.521.43zM27.637 130.132c.888 0 1.518.516 1.633 1.405.085.717-1.032 1.606-1.69 1.663-.946.086-1.49-.602-1.72-1.434-.286-1.061.975-1.606 1.777-1.634.974 0-.172 0 0 0zM11.623 171.374c1.805.086 2.407 2.41.516 3.041-2.235.717-2.75-3.127-.516-3.041 1.175.058-.745-.028 0 0z"
        fill="#fff"
      />
      <path
        d="M31.332 84.414c-.802 0-1.518-1.176-1.317-1.921.229-.89 1.203-1.377 2.062-1.234 2.005.316 1.031 3.184-.745 3.155zM79.2 167.875c.03-2.237 3.639-1.721 3.18.172-.171.66-.945 1.549-1.661 1.549-.716.029-1.518-1.032-1.518-1.721z"
        fill="#007FC8"
      />
      <path
        d="M32.192 155.141c-1.69.057-2.607-2.61-.716-3.04 2.033-.488 3.093 2.983.716 3.04z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M174.164 61.01c-.028-.802.831-1.52 1.576-1.433.945.115 1.49 1.004 1.518 1.893 0 .688-.916 1.348-1.547 1.405-.888.057-1.518-1.118-1.547-1.864 0-.66.029.918 0 0z"
        fill="#fff"
      />
      <path
        d="M73.357 75.409c-.029 2.093-3.294 1.405-3.266-.402.029-2.122 3.294-1.491 3.266.402z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M60.15 74.778c-.515 2.409-3.294 1.405-3.265-.402.057-2.094 3.552-2.036 3.266.402zM157.377 29.892c.029 2.008-2.52 2.037-2.95.23-.516-2.094 2.893-2.639 2.95-.23z"
        fill="#fff"
      />
      <path
        d="M174.709 38.812c-.602.918-1.662.688-2.435.23-.774-.46-.974-1.463-.201-2.295.545-.574 1.518-.631 2.149-.2.888.63.773 1.376.487 2.265-.545.23.286-.402 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M109.452 77.043c-.258.89-.459 1.52-1.519 1.635-.658.086-1.518-.717-1.69-1.29-.601-1.922 3.266-2.496 3.209-.345-.086.287.028-.831 0 0z"
        fill="#91E7F2"
      />
      <path
        opacity={0.5}
        d="M109.022 46.757c1.862.114 2.349 2.983.229 3.069-1.948.086-2.435-3.213-.229-3.07zM179.35 18.85c-.201-1.806 3.437-2.294 3.007.086-.315 1.807-3.065 1.922-3.007-.086-.058-.63 0 .46 0 0zM65.135 127.177c.115-.831.344-1.52 1.29-1.606.83-.086 1.919.631 1.89 1.549-.029.688-.974 1.721-1.69 1.549-.516-.115-1.49-.889-1.49-1.492.143-1.118 0 .488 0 0zM56.627 89.204c-.086.66-.2 1.549-1.031 1.692-.802.115-1.89-.86-1.977-1.606-.086-.86.802-1.635 1.576-1.72.802-.144 1.375.974 1.432 1.634-.086.631-.057-.602 0 0z"
        fill="#fff"
      />
      <path
        d="M187.542 24.787c.373-.516 1.461-.946 2.034-.487.487.372.802 1.262.774 1.864-.057 1.176-1.633 1.233-2.292 1.004-.659-.23-1.117-1.52-.516-2.38z"
        fill="#3BB2F4"
      />
      <path
        d="M109.022 110.801c.172.659-.688 1.807-1.432 1.692-.516-.086-1.375-.631-1.605-1.119-.429-.889.373-2.208 1.118-2.122 1.432.143 1.604.316 1.919 1.549z"
        fill="#007FC8"
      />
      <path
        d="M69.432 176.508c.058 1.836-3.208 1.52-3.266-.057-.085-2.151 3.066-1.807 3.266.057.029.689-.057-.717 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M45.57 203.956c.057 1.835-3.209 1.52-3.266-.058-.086-2.151 3.065-1.807 3.266.058.028.688-.086-.717 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M190.035 75.466c-.602-.631-.917-1.434-.487-2.237.372-.689 1.231-1.061 1.919-.545.487.344 1.089 1.262.859 1.893-.315.918-1.461 1.176-2.291.889-.946-.947.458.143 0 0z"
        fill="#fff"
      />
      <path
        d="M82.638 152.417c-.315-.861.144-1.75 1.06-1.836.63-.086 1.461.143 1.748.746.4.86-.2 2.122-.974 2.294-.745.143-1.518-.344-1.834-1.204z"
        fill="#91E7F2"
      />
      <path
        d="M62.643 49.453c-.802-.259-1.661-.89-1.232-1.865.373-.803 1.089-1.204 1.948-1.032.716.143 1.06.602 1.117 1.32.086 1.09-.888 1.49-1.833 1.577-.258-.086 1.117-.086 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M147.179 102.426c-.773.115-1.375-.688-1.632-1.319-.43-1.09.429-1.98 1.432-1.807 2.635.401 1.06 3.011.2 3.126zM122.285 70.017c.831-.201 1.347.66 1.49 1.348.115.66-.63 1.864-1.346 1.921-.659.029-1.576-1.147-1.633-1.72-.086-.861.687-1.52 1.489-1.55.545-.143-.573.03 0 0zM127.356 97.292c-1.69.115-2.263-2.868-.258-3.097 1.977-.23 2.349 3.212.258 3.097-.487.029.602.029 0 0z"
        fill="#fff"
      />
      <path
        d="M66.653 163.086c0-.775.803-1.578 1.605-1.463.802.115 1.317.975 1.375 1.721.229 2.151-3.037 1.549-2.98-.258 0-.66 0 .516 0 0z"
        fill="#007FC8"
      />
      <path
        d="M176.17 52.034c.114-1.98 2.807-1.893 3.008-.057.257 2.093-3.094 2.065-3.008.057z"
        fill="#91E7F2"
      />
      <path
        d="M160.815 39.529c.43-.488 1.433-.631 1.977-.23.601.43.945 1.664.487 2.266-.544.746-1.748.488-2.321.172-.515-.344-.716-1.491-.143-2.208zM176.37 43.544c1.748.03 1.776 3.098.115 3.04-.716-.028-1.604-.688-1.776-1.376-.229-.86.916-1.664 1.661-1.664z"
        fill="#007FC8"
      />
      <path
        d="M74.617 133.372c-.086-.631.344-1.634 1.003-1.807.917-.229 1.69.259 1.977 1.148.343 1.09-.545 1.864-1.203 1.921-.66.086-1.72-.659-1.777-1.262z"
        fill="#3BB2F4"
      />
      <path
        d="M161.359 69.587c-.744-.46-1.06-1.263-.659-2.066.43-.889 1.232-.86 2.034-.487 1.003.459.831 2.007.43 2.466-.372.402-1.146.46-1.805.087z"
        fill="#007FC8"
      />
      <path
        d="M75.62 153.707c.143-.717.659-1.491 1.375-1.549.745-.057 1.49.889 1.404 1.635-.115.832-.745 1.176-1.49 1.233-.057 0-1.461-.573-1.29-1.319zM70.55 200.772c.2-.918 1.002-1.979 2.062-1.348.516.316.802.918.716 1.492-.114.803-.745 1.118-1.49 1.204-.057-.028-1.46-.602-1.288-1.348z"
        fill="#3BB2F4"
      />
      <path
        d="M171.472 71.078c.2.746-.917 1.635-1.604 1.72-.86.116-1.347-.4-1.461-1.232-.115-.918.315-1.75 1.146-1.893.83-.172 1.69.602 1.919 1.405z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M107.876 119.577c-.229.746-.945 1.663-1.919 1.291-.831-.345-1.175-1.291-.888-2.094.601-1.692 2.836-.717 2.807.803-.086.258.029-1.233 0 0z"
        fill="#fff"
      />
      <path
        d="M90.087 62.531c1.948.029 1.804 3.413.257 3.012-.773-.201-1.49-.689-1.604-1.492-.114-.918.66-1.233 1.347-1.52.716 0-.144.057 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M168.407 21.03c.028.746-.43 1.635-1.175 1.864-.802.23-1.375-1.262-1.49-1.807-.114-.803.602-1.376 1.347-1.405.974-.057 1.06.545 1.318 1.348.028.86-.086-.23 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M94.87 180.495c.087 2.036-3.18 1.663-2.921-.058.114-.717.43-1.577 1.317-1.405.745.143 1.576.66 1.605 1.463.028.573-.03-.43 0 0z"
        fill="#fff"
      />
      <path
        d="M95.1 211.929c.086 2.036-3.18 1.663-2.922-.057.114-.717.43-1.578 1.318-1.406.744.144 1.575.66 1.604 1.463.028.545 0-.43 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M101.459 196.642c.086 2.036-3.18 1.664-2.922-.057.115-.717.43-1.578 1.318-1.406.745.144 1.576.66 1.604 1.463.029.574-.028-.43 0 0z"
        fill="#fff"
      />
      <path
        d="M66.74 186.891c.057-.803.83-1.176 1.575-1.148.773.029 1.232.689 1.432 1.377.258.918-.83 1.664-1.632 1.578-.803-.115-1.433-.918-1.375-1.807zM50.468 119.548c0-2.122 3.008-1.606 3.008.23 0 .631-1.002 1.491-1.604 1.147-.573-.258-1.518-.602-1.404-1.377 0-.487-.086.459 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M157.549.953c.717-.086 1.404.43 1.547 1.119.144.631.029 1.663-1.146 1.72-1.031.058-1.604-.315-1.661-1.462-.057-1.118.43-1.262 1.26-1.377z"
        fill="#fff"
      />
      <path
        d="M101.259 181.757c-.774-.029-1.805-.574-1.776-1.492.057-1.892 2.922-1.577 2.893-.057 0 .861-.315 1.578-1.117 1.549z"
        fill="#3BB2F4"
      />
      <path
        d="M150.531 113.095c-1.833 0-1.146-3.585.458-2.868.745.315 1.289.688 1.175 1.577-.115.861-.802 1.291-1.633 1.291-.63 0 1.26-.029 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M31.79 106.241c-1.919.229-1.947-2.18-.314-2.61.687-.173 1.49.487 1.718 1.089.43 1.004-.601 1.463-1.403 1.521-.688.086.143 0 0 0z"
        fill="#fff"
      />
      <path
        d="M85.102 113.038c-.029-1.893 2.664-2.123 2.635-.058 0 .574-.286 1.463-1.002 1.463-.745-.029-1.604-.602-1.633-1.405z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M27.379 166.7c-.774-.259-1.404-.89-1.232-1.75.143-.717.659-1.176 1.404-1.147.917.057 1.26 1.032 1.289 1.835-.115.23-.544 1.377-1.461 1.062z"
        fill="#fff"
      />
      <path
        d="M122.658 36.862c-1.719-.172-2.349-2.553-.373-2.897.717-.115 1.375.574 1.519 1.205.229 1.003-.344 1.405-1.146 1.692-1.06-.115.286-.115 0 0z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M143.541 87.024c-.172 1.664-2.893.975-2.693-.373.23-1.347 2.894-1.434 2.693.373z"
        fill="#fff"
      />
      <path
        d="M75.276 64.653c-.573 2.639-3.208.258-2.55-.717.975-1.405 2.55-1.032 2.55.717 0 .058 0-1.204 0 0zM53.677 178.602c-.774-.143-1.834-.172-1.748-1.262.086-.889.688-1.405 1.576-1.262.888.115 1.174.344 1.174 1.262 0 .861-.172 1.09-1.002 1.262-.459-.086.2-.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M183.36 3.879c-.029-1.577 2.406-1.922 2.836-.43.63 2.036-2.492 2.61-2.836.43-.115-.803.057.115 0 0z"
        fill="#fff"
      />
      <path
        d="M71.58 167.244c0-1.749 2.808-1.52 2.665-.057-.029.43 0 1.52-1.031 1.635-.774.086-1.776-.689-1.633-1.578 0-.889-.086.603 0 0z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M46.486 97.866c-.659-.517-1.317-1.004-1.203-1.95.115-.947.945-.918 1.69-.804.716.115 1.06.918.86 1.606-.23.775-.63.976-1.347 1.148-.258-.172.917-.201 0 0z"
        fill="#fff"
      />
      <path
        d="M87.938 163.946c.487-.573 1.375-.545 1.862.029.43.516.917 1.032.401 1.692-.601.774-1.719.774-2.177.258-.458-.459-.573-1.377-.086-1.979z"
        fill="#91E7F2"
      />
      <path
        d="M89.026 137.732c.487-.574 1.376-.545 1.862.028.43.517.917 1.033.402 1.693-.602.774-1.72.774-2.178.258-.458-.459-.601-1.406-.085-1.979z"
        fill="#007FC8"
      />
      <path
        d="M77.568 184.969c-.029-1.635 2.664-2.122 2.664-.459.029 1.75-2.635 2.381-2.664.459z"
        fill="#3BB2F4"
      />
      <path
        opacity={0.5}
        d="M183.904 77.818c-.487.143-.974.487-1.432.143-.372-.258-.63-.401-.659-.889-.028-.545.287-1.09.745-1.29 1.747-.689 2.234 1.778 1.346 2.036zM19.73 191.279c-.143.659-.4 1.147-1.174.975-.602-.144-1.518-.488-1.404-1.233.315-1.979 2.578-1.607 2.578.258-.028.114 0-.975 0 0z"
        fill="#fff"
      />
      <path
        d="M64.992 170.342c2.148.774.802 2.839-.143 2.581-.63-.172-1.49-.631-1.26-1.405.2-.689.744-1.119 1.403-1.176.286.086-.688.086 0 0z"
        fill="#007FC8"
      />
      <path
        d="M104.381 40.017c-1.69.086-1.919-2.61-.315-2.754 1.604-.114 1.891 2.668.315 2.754z"
        fill="#91E7F2"
      />
      <path
        d="M84.157 52.206c1.833-.058 1.317 2.868-.144 2.466-1.747-.487-1.69-2.294.144-2.466.974-.029-.2 0 0 0zM40.27 115.963c-.716-.229-1.576-.373-1.747-1.204-.144-.689.257-1.234.916-1.377.66-.172 1.318.057 1.576.717.315.774-.23 1.319-.745 1.864-.372-.143.2-.229 0 0z"
        fill="#007FC8"
      />
      <path
        d="M61.125 98.468c.716 0 1.146.2 1.146.975 0 .602-.287 1.52-1.032 1.463-.63-.058-1.661-.344-1.604-1.147.086-.803.659-1.32 1.49-1.291.86.029-.029 0 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M23.655 178.917c0-1.807 2.406-1.462 2.435.23 0 .659-.544 1.032-1.146 1.09-.888.086-1.29-.488-1.29-1.32zM80.748 121.556c.171-.631 1.117-.746 1.604-.545.659.287.974.947.601 1.807-.372.889-2.635.516-2.205-1.262zM91.204 125.112c-.086-1.692 2.635-1.893 2.263-.086-.115.488-.315 1.233-.917 1.262-.86 0-1.29-.258-1.346-1.176zM120.71 118.057c.916-.057 1.26.344 1.375 1.032.258 1.693-2.263 2.18-2.177.086 0-.401.257-1.089.802-1.118zM168.607 64.883c.917-.057 1.26.344 1.375 1.032.258 1.692-2.263 2.18-2.177.086 0-.401.258-1.06.802-1.118zM173.878 101.68c.917-.057 1.26.344 1.375 1.033.258 1.692-2.263 2.179-2.177.086-.029-.402.229-1.09.802-1.119z"
        fill="#007FC8"
      />
      <path
        d="M180.438 63.62c.917-.056 1.261.345 1.375 1.033.258 1.693-2.263 2.18-2.177.086 0-.372.258-1.06.802-1.118z"
        fill="#3BB2F4"
      />
      <path
        d="M46.257 170.944c.917-.057 1.26.344 1.375 1.033.258 1.692-2.263 2.179-2.177.086 0-.402.258-1.09.802-1.119z"
        fill="#007FC8"
      />
      <path
        d="M51.299 189.328c.917-.057 1.26.345 1.375 1.033.258 1.692-2.263 2.18-2.177.086-.029-.402.229-1.09.802-1.119zM2.227 183.707c.917-.057 1.26.344 1.375 1.033.258 1.692-2.263 2.179-2.177.086 0-.402.258-1.09.802-1.119z"
        fill="#3BB2F4"
      />
      <path
        d="M136.723 93.793c.917-.057 1.261.344 1.375 1.032.258 1.693-2.263 2.18-2.177.087 0-.373.258-1.062.802-1.119zM134.575 108.162c.916-.057 1.26.344 1.375 1.033.258 1.692-2.349 2.237-2.235.172-.028-.402.315-1.176.86-1.205z"
        fill="#007FC8"
      />
      <path
        d="M163.909 90.036c.917-.057 1.26.344 1.375 1.032.258 1.693-2.263 2.18-2.177.087 0-.402.258-1.062.802-1.12z"
        fill="#91E7F2"
      />
      <path
        d="M156.174 82.522c.917-.058 1.261.344 1.375 1.032.258 1.692-2.263 2.18-2.177.086 0-.401.258-1.09.802-1.118zM30.673 120.552c1.347-.086 1.49 2.897-.114 2.524-1.576-.373-1.576-2.438.114-2.524.487-.029-.601.029 0 0z"
        fill="#3BB2F4"
      />
      <path
        d="M63.56 84.042c.83.086 1.145.459 1.231 1.233.086.602-.315 1.348-1.002 1.29-.745-.057-1.461-.889-1.49-1.433.029-.545.573-1.176 1.26-1.09z"
        fill="#007FC8"
      />
      <path
        d="M21.879 154.367c1.317-.058 2.034 1.491.773 2.265-1.203.803-2.664-2.093-.773-2.265.86-.029-.602.057 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M115.41 18.965c.43 1.807-2.664 2.008-2.578.143.086-1.806 2.206-1.606 2.578-.143z"
        fill="#007FC8"
      />
      <path
        d="M152.45 97.464c-1.318 0-1.146-2.007 0-2.18 1.604-.286 1.776 2.18 0 2.18z"
        fill="#91E7F2"
      />
      <path
        d="M136.551 115.676c-1.317 0-1.145-2.007 0-2.18 1.633-.258 1.805 2.209 0 2.18zM183.274 94.539c-1.318 0-1.146-2.008 0-2.18 1.604-.258 1.776 2.208 0 2.18z"
        fill="#007FC8"
      />
      <path
        opacity={0.5}
        d="M161.818 75.61c-1.318 0-1.146-2.008 0-2.18 1.604-.259 1.804 2.208 0 2.18zM177.258 27.655c-1.031-.115-1.547-2.352-.028-2.294 1.461.086 1.69 2.323.028 2.294-.028 0 .602 0 0 0z"
        fill="#fff"
      />
      <path
        d="M66.74 107.474c.343-.746.458-1.062 1.317-.832.573.143.774.545.745 1.118-.029.517-.23 1.205-.86 1.119a1.35 1.35 0 01-.859-.459c-.258-.229-.143-.86-.343-.946.143-.287.028 0 0 0z"
        fill="#91E7F2"
      />
      <path
        d="M48.09 106.871c.086 1.377-1.861 1.09-1.718-.028.086-.631.458-.803 1.06-.775.573 0 .63.287.659.803z"
        fill="#007FC8"
      />
    </svg>
  );
}

export default SvgAdultMouthpieceBg;
